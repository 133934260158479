<div mojDir moj-dynamic-fontsize class="ws-wrapper">
    <header>
        <moj-accessible-menu></moj-accessible-menu>
        <moj-website-header mainSiteName="הרשם לענייני הירושה" engSiteName="משרד המשפטים"></moj-website-header>
        <!-- <moj-website-topmenu>
            <app-menu></app-menu>
        </moj-website-topmenu> -->
    </header>
    <main>
        <div class="container">
            <router-outlet></router-outlet>
        </div>
    </main>
    <footer role="contentinfo">
        <moj-website-footer [versionNumber]="version"></moj-website-footer>
    </footer>
</div>
