<div>
  <moj-line>
    <moj-line>
      <h1 class="font-24 moj-bold moj-primary-color">
        {{ checkRequestTexts.oldRequestCheck }}
      </h1>
    </moj-line>
  </moj-line>
  <div>
    <form #f="ngForm">
      <div role="search">
        <moj-line class="paddingTop5">
          <moj-textbox ngModel name="tzManoach" [labelTextKey]="checkRequestTexts.deceasedId" [identification]="true"
            [maxlength]="9" [controlWidthColumns]="2">
          </moj-textbox>
          <moj-textbox [maxlength]="30" ngModel name="shemMishpachaManoach"
            [labelTextKey]="checkRequestTexts.deceasedLastName" minlength="2" [controlWidthColumns]="3">
          </moj-textbox>
          <moj-textbox [maxlength]="20" ngModel name="shemPratiManoach"
            [labelTextKey]="checkRequestTexts.deceasedFirstName" minlength="2" [controlWidthColumns]="2">
          </moj-textbox>
        </moj-line>
        <moj-line>
          <div class="col-md-11">
            <moj-buttons-line style="float: left">
              <moj-clear-button [form]="f"> </moj-clear-button>
              <moj-submit-button id="searchBakashotBtn" [buttonStyle]="buttonStyle.Primary" [form]="f"
                (onSubmit)="retrieveData()" [textKey]="checkRequestTexts.search"
                [isEnable]="(f.valid && !showLoading)!">
              </moj-submit-button>
            </moj-buttons-line>
          </div>
        </moj-line>
      </div>
    </form>
  </div>
  <div *ngIf="showGrid">
    <bdikat-bakasha-output [data]="bdikatBakashotResponse" [showLoading]="showLoading"></bdikat-bakasha-output>
  </div>
</div>