import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
  })
  export class LoginComponent {

    @Output()
    checkedChange = new EventEmitter();

    onCheckedChange(event: any) {
      this.checkedChange.emit(event);
    }
  }