import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { ButtonStyle, ButtonToggleItem, ENUMS, Enums, MessageType, MojLoadingService, MojMessagesService, MojRecaptchaService } from "@moj/moj-ng";
import { SaveNotificationRequest } from '../models/save-notification-form-request';
import { SaveNotificationResponse } from "../models/save-notification-form-response";
import { DataStoreService } from '../services/data-store.service';
import { InheritanceDataService } from "../services/inheritance-data.service";

@Component({
  selector: "RequestNotification",
  templateUrl: "./RequestNotification.component.html",
  styleUrls: ["./RequestNotification.component.css"],
})
export class RequestNotification implements OnInit {
  @ViewChild('f', { static: false }) formModel!: NgForm;
  @ViewChild("pnl", { read: ViewContainerRef, static: true })
  pnl!: ViewContainerRef;
  Enums: Enums = ENUMS;

  firstName: any;
  lastName: any;
  phone: any;
  email: any;
  identityType = 1;
  idNumber: any;
  countries: any;
  countryId: any;
  recapchaModel: any;

  entityLastName: any;
  entityFirstName: any;
  isPhoneRequired: boolean = true;
  isEmailRequired: boolean = true;
  buttonStyle = ButtonStyle;
  buttonToggleItems: ButtonToggleItem[] = [
    { id: 1, text: 'תעודת זהות' },
    { id: 2, text: 'דרכון' },
  ];
  constructor(private loadingService: MojLoadingService, private recaptchaService: MojRecaptchaService, public dataStoreService: DataStoreService, public dataService: InheritanceDataService, private messagesService: MojMessagesService, private router: Router) { }

  ngOnInit() { }

  ngAfterViewInit() { }

  initForm() { }

  sendRequestNotification() {
    this.loadingService.show(this.pnl);
    let f = this.formModel.value;
    this.recaptchaService.executeRecaptcha().subscribe((res) => {
      this.recapchaModel = res;
      let req: SaveNotificationRequest =
      {
        CountryID: f.countryId,
        EntityFirstName: f.entityFirstName,
        EntityIdNumber: f.idNumber,
        EntityIdentityTypeId: f.identityType,
        EntityLastName: f.entityLastName,
        SubscriberEmail: f.email,
        SubscriberFirstName: f.firstName,
        SubscriberLastName: f.lastName,
        SubscriberPhone: f.phone,
        RecaptchaString: this.recapchaModel
      };

      this.dataService.saveRequestNotificationForm(req)
        .subscribe({
          next: (res: SaveNotificationResponse) => {
            if (res.errorId == 0) {
              this.loadingService.hide();
              this.messagesService
                .showMessage({ messageTitle: "בקשתך לקבלת התראה התקבלה", messageHtml: `<p>מס' אסמכתא ` + res.referenceID.toString() + `</p> <p>במידה ותוגש בקשה תשלח התראה לפרטי ההתקשרות שציינת </p> <p>מועד פקיעת הבדיקה ` + res.durationDateStr + `</p>`, messageType: MessageType.Success })
                .subscribe((res) => {
                  const currentRoute = this.router.url;
                  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigate([currentRoute]); // navigate to same route
                  });
                });
            }
            else {
              this.loadingService.hide();
              switch (res.errorId) {
                case 1: this.messagesService.showMessage({ messageText: "פרטי המנוח אינם נכונים. אנא וודא שהקלדת את הפרטים כפי שמופיעים בתעודת הזהות", messageType: MessageType.Message }); break;
                case 2: this.messagesService.showMessage({ messageText: `קיים תיק אחר המנוח/ה אצל הרשם לענייני ירושה לכן לא ניתן להרשם לשירות זה. ניתן לאתר את התיק על פי שם  המנוח/ה או על פי מספר זיהוי בלשונית איתור בקשות`, messageType: MessageType.Attention }); break;
                case 3: if (res.durationDatePhoneStr === res.durationDateEmailStr) {
                  this.messagesService.showMessage({
                    messageText: "קיימת כבר עבורך בקשה לקבלת התראה על הגשת בקשה עבור המנוח/ה. הבקשה לקבלת התראה פעילה עד לתאריך " + res.durationDateEmailStr + "." + " ניתן יהיה להגיש בקשה נוספת רק לאחר תאריך זה ", messageType: MessageType.Attention
                  }); break;
                }
                  this.messagesService.showMessage({
                    messageHtml: (res.durationDatePhoneStr != "" && res.durationDatePhoneStr != null ?
                      "<p>קיימת כבר עבור מספר טלפון זה בקשה לקבלת התראה על הגשת בקשה עבור המנוח/ה. הבקשה לקבלת התראה פעילה עד לתאריך " + res.durationDatePhoneStr + "." + " יהיה ניתן להגיש בקשה נוספת למספר טלפון זה רק לאחר תאריך זה. </p>" : "") +
                      (res.durationDateEmailStr != "" && res.durationDateEmailStr != null ?
                        "<p>קיימת כבר עבור כתובת דוא\"ל זה בקשה לקבלת התראה על הגשת בקשה עבור המנוח/ה. הבקשה לקבלת התראה פעילה עד לתאריך " + res.durationDateEmailStr + "." + " יהיה ניתן להגיש בקשה נוספת לכתובת דוא\"ל זאת רק לאחר תאריך זה. </p>" : ""),
                    messageType: MessageType.Attention
                  }); break;

              }
            }
          },
          error: () => {
            this.loadingService.hide();
            this.errServer();
          }
        });
    })



  }

  errServer() {
    this.messagesService.showMessage({ messageText: "משהו השתבש אנא נסה שנית מאוחר יותר", messageType: MessageType.Error });
  }

  onEmailorPhoneChanged(event: any) {
    let f = this.formModel.value;
    if (f.email === '' && f.phone === '') {
      this.isPhoneRequired = true
      this.isEmailRequired = true
    }
    else {
      this.isPhoneRequired = false;
      this.isEmailRequired = false;
    }
  }

}
