<div id="requestCopy" #pnl>
    <form #form="ngForm">
        <div id="layout-content" class="container-fluid">
            <h4 class="font-20 moj-orange-color moj-bold">פרטי המנוח</h4>
            <moj-line>
                <div class="font-12 moj-bold">{{deceasedText.alertMessage}}</div>
            </moj-line>
            <moj-line>
                <moj-textbox [required]="true" [identification]='true' ngModel name="idNumber"
                    [labelTextKey]="deceasedText.idNumber" labelWidthColumns="2" [controlWidthColumns]="4"
                    isLabelAboveControl="true" [maxlength]="9">
                </moj-textbox>
                <moj-textbox [required]="true" ngModel name="firstName" [labelTextKey]="deceasedText.firstName"
                    [maxlength]="50" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
                </moj-textbox>
                <moj-textbox [required]="true" ngModel name="lastName" [labelTextKey]="deceasedText.lastName"
                    [maxlength]="50" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
                </moj-textbox>
            </moj-line>
            <moj-line class="paddingTop10">
                <h4 class="font-20 moj-orange-color moj-bold">{{deceasedText.debtsDetails}}</h4>
            </moj-line>
            <moj-line>
                <moj-textbox [readOnly]="true" [(ngModel)]="autorityName" name="autorityName"
                    [labelTextKey]="deceasedText.localAuthorityName" [maxlength]="50" labelWidthColumns="2"
                    [controlWidthColumns]="4" isLabelAboveControl="true">
                </moj-textbox>
                <moj-datepicker name="debtsDate" ngModel isLabelAboveControl="true" labelWidthColumns="2"
                    [controlWidthColumns]="4" [required]="true" [labelTextKey]="deceasedText.debtsDate"
                    [maxEnableDate]="maxDate">
                </moj-datepicker>
                <moj-textbox [required]="true" ngModel [dir]="'ltr'" name="amount" [labelTextKey]="deceasedText.amount"
                    [min]="1" [max]="20000" labelWidthColumns="3" [controlWidthColumns]="4" isLabelAboveControl="true">
                </moj-textbox>
            </moj-line>
            <moj-line>
                <moj-textbox [required]="true" ngModel [dir]="'ltr'" name="phone" [labelTextKey]="deceasedText.phone"
                    labelWidthColumns="3" [controlWidthColumns]="4" isLabelAboveControl="true">
                </moj-textbox>
                <moj-textbox [required]="true" ngModel [dir]="'ltr'" email name="email"
                    [labelTextKey]="deceasedText.email" labelWidthColumns="3" [controlWidthColumns]="4"
                    isLabelAboveControl="true">
                </moj-textbox>
            </moj-line>
            <moj-line>
                <moj-textarea [required]="true" ngModel name="debtDesc" [labelTextKey]="'מהות החוב'"
                    [maxTextLength]="500" [rows]=4 [controlWidthColumns]="12">
                </moj-textarea>
            </moj-line>
            <moj-line>
                <radio-buttons-wrapper required="true" [describedbyId]="'authorityCollection'">
                    <div class="col-sm-3 paddingX0 font-16 moj-primary-color moj-bold">{{deceasedText.claimAuthority}}
                    </div>
                    <div class="col-sm-8">
                        <div class="col-sm-2">
                            <moj-radiobutton ngModel [required]="true" name="authorityCollection" [radiovalue]="true"
                                [labelTextKey]="'כן'">
                            </moj-radiobutton>
                        </div>
                        <div class="col-sm-2">
                            <moj-radiobutton ngModel #authorityCollection1 name="authorityCollection"
                                [radiovalue]="false" [labelTextKey]="'לא'">
                            </moj-radiobutton>
                        </div>
                    </div>
                </radio-buttons-wrapper>
                <!-- <radio-buttons-wrapper required="true" [describedbyId]="'isActive'">
                  <div class="col-sm-4 paddingX0 font-16 moj-primary-color moj-bold">חוב פעיל?</div>
                  <div class="col-sm-8">
                      <div class="col-sm-2">
                          <moj-radiobutton
                               [required]="true"
                              ngModel
                              name="isActive"
                              [radiovalue]="true"
                              [name]="'isActive'"
                              [labelTextKey]="'כן'">
                          </moj-radiobutton>
                      </div>
                      <div class="col-sm-2">
                          <moj-radiobutton
                              
                              ngModel 
                              name="isActive"
                              [radiovalue]="false"
                              [name]="'isActive'"
                              [labelTextKey]="'לא'">
                          </moj-radiobutton>
                      </div>
                 </div>
                </radio-buttons-wrapper> -->
            </moj-line>
        </div>
        <moj-buttons-line style="float: left">
            <moj-clear-button [form]="form" (click)="resetData()"></moj-clear-button>
            <moj-submit-button id="requestCopyBtn" [buttonStyle]="buttonStyle.Primary" [form]="form"
                (onSubmit)="onSubmit()" [textKey]="'הגשת חוב'" [isEnable]="form.valid!">
            </moj-submit-button>
        </moj-buttons-line>
    </form>
</div>