import { Component, OnInit } from '@angular/core';
import { MojFileUploadDesignType } from '@moj/moj-ng';
import { DictItem } from '../../../models/dict-item.model';
import { DataStoreService } from '../../../services/data-store.service';

@Component({
  selector: 'app-yerusha-tab4',
  templateUrl: './yerusha-tab4.component.html'
})
export class YerushaTab4Component implements OnInit {

  isOutHeirs!: boolean;
  IsDiedHeirs!: boolean;
  isOffsprings!:boolean;

  isOutForPerson!:boolean;
  isOutAll!:boolean;
  IsHasTZAV!:boolean;
  dateDeceased:any;
  hebrewDaySelected:any;
  hebrewMonthSelected:any;
  hebrewYearSelected:any;
  remarks:any;

  idTypeHeirsSelected: number = 1;
  outForPersonTypesSelected: number = 1;


  idTypeChildSelected: number = 1;
  relatedSelected: number = 1;


  idTypes: Array<DictItem> = [];
  outForPersonTypes: Array<DictItem> = [];
  hebrewDays: Array<DictItem> = [];
  hebrewMonths: Array<DictItem> = [];
  hebrewYears: Array<DictItem> = [];
  related: Array<DictItem> = [];


  filesOutHeirs = [];
  filesDiedHeirs = [];
  filesTZAV = [];

   
  filesDesignType = MojFileUploadDesignType;    


  constructor(public dataStoreService: DataStoreService) { }

  ngOnInit() {
    this.idTypes.push({id:1,value:"ת. זהות"})
    this.idTypes.push({id:2,value:"דרכון"})
    this.idTypes.push({id:3,value:"מס' ביטוח לאומי ארה\"ב"})
    this.idTypes.push({id:4,value:"מס' זהות זר"})

    this.outForPersonTypes.push({id:1,value:"בן/בת זוג של המוריש/ה"})
    this.outForPersonTypes.push({id:2,value:"בן/בת של המוריש/ה"})
    this.outForPersonTypes.push({id:3,value:"אחיו/אחותו של המוריש/ה"})                

    this.hebrewDays.push({id:1,value:"א'"})
    this.hebrewDays.push({id:2,value:"ב'"})
    this.hebrewDays.push({id:3,value:"ג'"})
    this.hebrewDays.push({id:4,value:"ד'"})

    this.hebrewMonths.push({id:1,value:"תשרי"})
    this.hebrewMonths.push({id:2,value:"חשון"})
    this.hebrewMonths.push({id:3,value:"כסלו"})
    this.hebrewMonths.push({id:4,value:"טבת"})

    this.hebrewYears.push({id:1,value:"תרס''א"})
    this.hebrewYears.push({id:2,value:"תרס''ב"})
    this.hebrewYears.push({id:3,value:"תרס''ג"})
    this.hebrewYears.push({id:4,value:"תרס''ד"})

    this.related.push({id:1,value:"בן"})
    this.related.push({id:2,value:"בת"})
    this.related.push({id:3,value:"אח"})
    this.related.push({id:4,value:"אחות"})
  }

}
