import { Component, OnInit } from '@angular/core';
import { DictItem } from '../../../models/dict-item.model';
import { DataStoreService } from '../../../services/data-store.service';

@Component({
  selector: 'app-yerusha-tab1',
  templateUrl: './yerusha-tab1.component.html'
})
export class YerushaTab1Component implements OnInit {
  isBakashaNilvet!: boolean;
  isRelatives!: boolean;
  idType:any;
  machoz:any;
  idTypesList: Array<DictItem> = [];

  constructor(public dataStoreService: DataStoreService) { }

  ngOnInit() {
    this.idTypesList.push({id:1,value:"ת. זהות"})
    this.idTypesList.push({id:2,value:"דרכון"})
    this.idTypesList.push({id:3,value:"מס' ביטוח לאומי ארה\"ב"})
    this.idTypesList.push({id:4,value:"מס' זהות זר"})
  }

}
