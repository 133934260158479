import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MojConfigService } from '@moj/moj-ng';
import { Observable, of } from 'rxjs';

import { Bakasha, BakashaForCourtOrderResponse, BakashaResponse } from '../models/bakasha.model';
import { Client } from '../models/client-data.model';
import { DictItem } from '../models/dict-item.model';

import { Apostille } from '../models/apostille.model';
import { BakashaButtonsLogicResponse } from '../models/bakasha-buttons-logic.model';
import { BdikatBakashaRequest, BdikatBakashaResponse } from '../models/bdikat-bakasha.model';
import { DebtsRequest, DebtsResponse } from '../models/debts.model';
import { FaultyDocumentsRemarksResponse } from '../models/faulty-documents-remarks-response';
import { LawyerDetailsResponse } from '../models/lawyer-details-response.model';
import { RecaptchaModel } from '../models/recaptcha-model';
import { SaveAgraFormRequest } from '../models/save-agra-form-request';
import { SaveAgraFormResponse } from '../models/save-agra-form-response';
import { SaveNotificationRequest } from '../models/save-notification-form-request';
import { SaveNotificationResponse } from '../models/save-notification-form-response';
import { SugNewBakashaEnum } from '../models/sug-new-bakasha-enum';
import { TaarifAgrotItem } from '../models/taarif-agrot-item.model';
import { Tguva, TguvaRequest } from '../models/tguva.model';

@Injectable()
export class InheritanceDataService {

  sugNewBakashaEnum: typeof SugNewBakashaEnum
  private url;  // URL to web api

  constructor(private http: HttpClient,
    configService: MojConfigService) {
    //this.url = configService.configuration.webApiAddress;
    this.url = configService.configuration.webApiAddress + "inheritance/";
    this.sugNewBakashaEnum = SugNewBakashaEnum;
  }

  getLastDateUpdated(): Observable<Date> {
    return this.http.get<Date>(this.url + "GetLastDateUpdated", {
      withCredentials: true
    });
  }

  getAllTaarifeyAgrot(recaptchaModel?: RecaptchaModel): Observable<TaarifAgrotItem[]> {
    return this.http.post<TaarifAgrotItem[]>(this.url + "GetTaarifeyAgrot", recaptchaModel);
  }

  getAgraFee(sugBakasha: number, misparBakashaPnimi: number): Observable<number> {
    return this.http.get<number>(this.url + "GetAgraFee", {
      params: new HttpParams()
        .set("sugBakasha", sugBakasha.toString())
        .set("misparBakashaPnimi", misparBakashaPnimi.toString())
    });
  }



  getAllBateyMishpat(): Observable<DictItem[]> {
    return this.http.get<DictItem[]>(this.url + "GetBateyMishpat");
  }

  getAllSugeyTik(): Observable<DictItem[]> {
    return this.http.get<DictItem[]>(this.url + "GetSugeyTik");
  }

  getAllMachozot(): Observable<DictItem[]> {
    return this.http.get<DictItem[]>(this.url + "GetMachozot");
  }

  getAllCountries(): Observable<DictItem[]> {
    return this.http.get<DictItem[]>(this.url + "GetCountries");
  }

  getApostilleCountries(): Observable<Apostille> {
    var json = { "organizationID": 1 };
    return this.http.post<Apostille>(this.url + "GetApostilleCountries", json);
  }

  getAllGetAuthorities(term: string): Observable<DictItem[]> {
    return this.http.get<DictItem[]>(this.url + 'GetAuthorities', { params: new HttpParams().set('search', term) });
  }

  getTguvotYoetz(tguvaRequest: TguvaRequest): Observable<Tguva[]> {
    return this.http.post<Tguva[]>(this.url + "GetTguvotYoetz", tguvaRequest);
  }

  getBakashot(request: any): Observable<Bakasha[]> {
    return this.http.post<Bakasha[]>(this.url + "GetBakashot", request);
  }

  getBakasha(kod: number): Observable<BakashaResponse> {
    return this.http.get<BakashaResponse>(this.url + "GetBakasha", {
      params: new HttpParams()
        .set("kod", kod.toString())
    });
  }

  getBakashaForCourtOrder(tZManoach: string, isTz: boolean, model?: RecaptchaModel): Observable<BakashaForCourtOrderResponse> {
    return this.http.post<BakashaForCourtOrderResponse>(this.url + "GetBakashaForCourtOrder",
      { tZManoach: tZManoach, isTz: isTz, recaptchaModel: model });
  }

  getFileDownload(request: any) {
    return this.http.post(this.url + "GetCourtOrderCopy", request, { responseType: 'blob', observe: 'response' });
  }

  getBdikatBakasha(request: BdikatBakashaRequest): Observable<BdikatBakashaResponse[]> {
    return this.http.post<BdikatBakashaResponse[]>(this.url + "GetBdikatBakasha", request);
  }

  getCouncelRequests(request: BdikatBakashaRequest): Observable<BdikatBakashaResponse[]> {
    return this.http.post<BdikatBakashaResponse[]>(this.url + "GetCouncelRequests", request);
  }

  getDeceasedDebts(request: DebtsRequest): Observable<DebtsResponse[]> {
    return this.http.post<any[]>(this.url + "GetDeceasedDebts", request);
  }

  saveAgraForm(request: SaveAgraFormRequest): Observable<SaveAgraFormResponse> {
    return this.http.post<SaveAgraFormResponse>(this.url + "SaveAgraForm", request);
  }

  saveDebtForm(request: any): Observable<any> {
    return this.http.post<any>(this.url + "SaveDebtForm", request);
  }

  saveRequestNotificationForm(request: SaveNotificationRequest): Observable<SaveNotificationResponse> {
    return this.http.post<SaveNotificationResponse>(this.url + "SaveNotificationRequest", request);
  }

  deleteDebt(debtId: number): Observable<boolean> {
    return this.http.post<boolean>(this.url + "DeleteDeceasedDebts", debtId);
  }

  getBakashaButtonsLogic(kod: number): Observable<BakashaButtonsLogicResponse> {
    return this.http.get<BakashaButtonsLogicResponse>(this.url + "GetBakashaButtonsLogic", {
      params: new HttpParams()
        .set("kod", kod.toString())
    });
  }

  isPersonalInDead(clientData: Client, recaptchaString: string): Observable<boolean> {
    return this.http.post<boolean>(this.url + "GetVerifyPersonalInDead",
      {
        clientData,
        recaptchaString: recaptchaString || ""
      })
  }

  isPersonalDataExists({ clientData, recaptchaString }: { clientData: Client; recaptchaString: string; }): Observable<boolean> {
    return this.http.post<boolean>(this.url + "GetVerifyPersonalDataOpen",
      {
        clientData,
        recaptchaString: recaptchaString || ""
      })
  }

  isPersonalDataExistsCorp(clientData: Client): Observable<boolean> {
    return this.http.post<boolean>(this.url + "GetVerifyPersonalDataCorp",
      {
        clientData
      })
  }

  getLawyerDetails(licenseNumber: number, recaptchaString: string): Observable<LawyerDetailsResponse> {
    return this.http.post<LawyerDetailsResponse>(this.url + "GetLawyerDetails",
      {
        licenseNumber: licenseNumber.toString(),
        recaptchaString: recaptchaString || ""
      });
  }

  getFaultyDocumentsRemarks(bakashaPnimit: string): Observable<FaultyDocumentsRemarksResponse> {
    return this.http.get<FaultyDocumentsRemarksResponse>(this.url + "GetFaultyDocumentsRemarks", {
      params: new HttpParams()
        .set("bakashaPnimit", bakashaPnimit.toString())
    });
  }

  //TODO: bring from database
  getAllSugNewBakasha(): Observable<DictItem[]> {
    if (!this.sugNewBakashaEnum) return of();

    let diArr: DictItem[] = [
      {
        id: this.sugNewBakashaEnum.MinuyMenahelIzavon,
        value: "מינוי מנהל עזבון קבוע"
      },
      {
        id: SugNewBakashaEnum.TikunTsavYerusha,
        value: "תיקון צו ירושה"
      },
      {
        id: SugNewBakashaEnum.TikunTsavKiumTsavaa,
        value: "תיקון צו קיום צוואה"
      },
      {
        id: SugNewBakashaEnum.BitulTsavYerusha,
        value: "ביטול צו ירושה"
      },
      {
        id: SugNewBakashaEnum.BitulTsavkiumTsavaa,
        value: "ביטול צו קיום צוואה"
      },
      {
        id: SugNewBakashaEnum.OchachatMavet,
        value: "הוכחת מוות"
      },
      {
        id: SugNewBakashaEnum.TikunTautSofer,
        value: "תיקון טעות סופר"
      },
      {
        id: SugNewBakashaEnum.IkuvBitsua,
        value: "עיכוב ביצוע"
      },
      {
        id: SugNewBakashaEnum.OsafatPratim,
        value: "הוספת פרטים"
      },
      {
        id: SugNewBakashaEnum.HaarachatMoed,
        value: "הארכת מועד"
      },
      {
        id: SugNewBakashaEnum.YiunBatik,
        value: "עיון בתיק "
      },
      {
        id: SugNewBakashaEnum.OchachatTsavaaInEtek,
        value: "הוכחת צוואה בהעתק"
      },
      {
        id: SugNewBakashaEnum.BakashatTsavYerushaMetukenet,
        value: "בקשת צו ירושה מתוקנת"
      },
      {
        id: SugNewBakashaEnum.BakashatTsavKiumTsavaaMetukenet,
        value: "בקשת צו קיום צוואה מתוקנת"
      },
      {
        id: SugNewBakashaEnum.ChangeRepresentation,
        value: "בקשה להחלפת ייצוג"
      }
    ]
    return of(diArr);
  }
  getUrl() {
    return this.url;
  }
  isIpAuthorized(): Observable<boolean> {
    return this.http.get<boolean>(this.url + "IsIpAuthorized");
  }
  getAuthorityName(): Observable<any> {
    return this.http.get<string>(this.url + "GetAuthorityName");
  }
  getAuthorities() {
    return 'inheritance/GetAuthorities?';
  }

}
