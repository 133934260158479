import { Component } from "@angular/core";
import { FontSize, FontWeight, GridService, MojColor } from "@moj/moj-ng";
import { ColDef, GridApi, GridOptions } from "ag-grid-community";

import { SugBakasha } from "../models/sug-bakasha";
import { TaarifAgrotItem } from "../models/taarif-agrot-item.model";

import { TranslateService } from "@ngx-translate/core";
import { DataStoreService } from "../services/data-store.service";

@Component({
  selector: "agrot",
  templateUrl: "./agrot.component.html"
})
export class AgrotComponent {
  agrotData!: TaarifAgrotItem[];
  sugeyBakashot!: SugBakasha[];
  rowData: any;
  columns!: ColDef[];
  sugBakachaSelected!: string;

  fontSize = FontSize;
  fontWeight = FontWeight;
  mojColor = MojColor;

  gridApi!: GridApi;
  gridOptions!: GridOptions;
  feesTexts;

  constructor(
    private gridService: GridService,
    private translateService: TranslateService,
    private dataStoreService: DataStoreService
  ) {
    this.feesTexts = this.translateService.instant("Texts.feesTexts");
    this.handleRecaptcha();
  }

  handleRecaptcha() {
    this.dataStoreService.agrot$.subscribe({
      next: (output) => {
        this.agrotData = output;
        this.rowData = this.agrotData;
        this.sugeyBakashot = this.agrotData.map(x => ({
          id: x.sugBakasha.trim(),
          name: x.sugBakasha.trim()
        }));
      },
      error: () => this.gridApi.hideOverlay()
    });
  }

  ngOnInit() {
    this.gridOptions = this.gridService.getMojGridOptions();
    this.gridOptions.domLayout = 'normal';

    this.columns = [
      this.gridService.getMojColumn("sugBakasha", { colDef: { "headerName": "feesTexts.requestType", width: 250 } }),
      this.gridService.getMojDateColumn("taarichTchilatTaarif", { colDef: { headerName: "feesTexts.effectiveDate" } }),
      this.gridService.getMojCurrencyColumn("sumAgratReshem", { colDef: { headerName: "feesTexts.registrarFeeSum" } }),
      this.gridService.getMojCurrencyColumn("sumAgratPirsum", { colDef: { headerName: "feesTexts.publishFeeSum" } }),
      this.gridService.getMojCurrencyColumn("sumAgratPikuach", { colDef: { headerName: "feesTexts.inspectionFeeEstateManagersSum", width: 250 } })
    ];
  }

  retrieveData() {
    this.rowData = this.agrotData.filter(
      x =>
        !this.sugBakachaSelected ||
        x.sugBakasha.trim() === this.sugBakachaSelected
    );
  }

  onSelectSugBakasha() {
    this.retrieveData();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  rowDataChanged() {
    if (this.gridApi) this.gridApi.sizeColumnsToFit();
  }

  onBlur() {
    this.retrieveData();
  } // fix forceSelected bug
}
