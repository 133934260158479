import { Component } from '@angular/core';
import { ENUMS, Enums, FontSize, FontWeight, MojColor } from '@moj/moj-ng';

import { TranslateService } from '../../../node_modules/@ngx-translate/core';
import { InheritanceDataService } from '../services/inheritance-data.service';
// import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  title = 'דף הבית';
  dateUpdated?: Date;
  fontSize = FontSize;
  fontWeight = FontWeight;
  mojColor = MojColor;
  // loggedIn = false;
  mojEnum:Enums=ENUMS;
  homeTexts;

  
  
  constructor(private dataService: InheritanceDataService, private translateService: TranslateService){
    // try {
    //  console.log.apply(console, new Array(1000000000)); //intentional invalid array length error
    // }
    // catch (e){
    //   console.log('in home' , e)
    // }

    this.dataService.getLastDateUpdated()
      .subscribe(output => 
        {this.dateUpdated = output}
      ,err => {console.log(err)});

      this.homeTexts = this.translateService.instant('Texts.homeTexts');    
  }

  // onClick(data){
  //   this.loggedIn = true;
  // }


  documentScrollTop = 0;

ngOnInit() {

    window.addEventListener("message", (data) => {
      this.receiveMessage(data.data);
    }, false);
  }

    receiveMessage(data: any) {
        if (data.isOpen != undefined) {
            document.getElementById("feedback-iframe")!.style.width = data.width;
            document.getElementById("feedback-iframe")!.style.height =  data.height;
          
            if (data.isOpen) {
                document.getElementById("feedback-iframe")!.classList.add("open");
                this.documentScrollTop = document.documentElement.scrollTop;
                window.scrollTo(0, document.documentElement.offsetHeight/2 + data.scrollHeight) // scroll to start of feedback dialog
            }
            else {
                document.getElementById("feedback-iframe")!.classList.remove("open");
                window.scrollTo( 0, this.documentScrollTop );
            }
        }
    }
}
