import { Component, OnInit } from '@angular/core';
import { WizardItem } from '@moj/moj-ng';
import { ZavaaTab1Component } from './wizard-tabs/zavaa-tab1/zavaa-tab1.component';
import { ZavaaTab2Component } from './wizard-tabs/zavaa-tab2/zavaa-tab2.component';
import { ZavaaTab3Component } from './wizard-tabs/zavaa-tab3/zavaa-tab3.component';
import { ZavaaTab4Component } from './wizard-tabs/zavaa-tab4/zavaa-tab4.component';
import { ZavaaTab5Component } from './wizard-tabs/zavaa-tab5/zavaa-tab5.component';
import { ZavaaTab6Component } from './wizard-tabs/zavaa-tab6/zavaa-tab6.component';

@Component({
  selector: 'app-bakasha-letzav-kyum-zavaa',
  templateUrl: './bakasha-letzav-kyum-zavaa.component.html',
  styleUrls: ['./bakasha-letzav-kyum-zavaa.component.css']
})
export class BakashaLetzavKyumZavaaComponent implements OnInit {

  items!: WizardItem[];

  constructor() { }

  ngOnInit() {
    this.items = [
      
      {
        label: 'חלק ד\' זוכים', component: ZavaaTab5Component ,
      },
      
      {
        label: 'הנחיות ופרטי הבקשה', component: ZavaaTab1Component ,
      },
      {
        label: 'חלק א\' מבקש מוריש', component:  ZavaaTab2Component,
      },
      {
        label: 'חלק ב\' שינוי לפני הפטירה', component: ZavaaTab3Component ,
        componentData: { name: 'my form', id: 1 },
      }
      ,
      {
        label: 'חלק ג\' שינוי אחר הפטירה', component: ZavaaTab4Component ,
      },
      
      {
        label: 'חלק ה\' תצהיר', component: ZavaaTab6Component //, isForMetro: true,
      }
    ]
  }

}
