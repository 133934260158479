<moj-line>
    <h2 class="moj-primary-color">{{ checkRequestTexts.searchResults }}</h2>
    <div class="font-12 moj-bold"> {{ checkRequestTexts.formMessage }} </div>
    <div *ngIf="isToManyRecords">
        <div class="font-12 moj-bold">{{ checkRequestTexts.SearchResultsMessage }}</div>
    </div>
    <div *ngIf="data?.length! < 100">
        <div class="font-12 moj-bold">{{'נמצאו ' + (data?.length || 0) + ' תוצאות התואמות לחיפוש בבקשות לערכאות אחרות'}}
        </div>

    </div>
    <div class="paddingTop15" id="bakashotGridDiv">
        <moj-grid-panel>
            <ag-grid-angular style="height: 400px" class="ag-theme-balham" [gridOptions]="gridOptions"
                [rowData]="rowData" [columnDefs]="columns" [pagination]="false" [infiniteInitialRowCount]="10"
                [suppressHorizontalScroll]="true" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </moj-grid-panel>
    </div>
</moj-line>