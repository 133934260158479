import { Component, OnInit } from '@angular/core';
import { GridService, MojFileUploadDesignType } from '@moj/moj-ng';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { DictItem } from '../../../models/dict-item.model';
import { DataStoreService } from '../../../services/data-store.service';

@Component({
  selector: 'app-yerusha-tab5',
  templateUrl: './yerusha-tab5.component.html'
})
export class YerushaTab5Component implements OnInit {

  constructor(public dataStoreService: DataStoreService, private gridService: GridService
  ) { }

  idTypeHeirsSelected: number = 1;
  countryHeirsSelected: number = 1;
  cityHeirsSelected: number = 1;
  streetHeirsSelected: number = 1;
  relatedSelected: number = 1;
  legalCourtSelected: number = 1;

  isApotropos!: boolean;
  isSingelHeirs!: boolean;
  isHeirsKnow!: boolean;
  isAllHeirsKnow!: boolean;

  idTypes: Array<DictItem> = [];
  cities: Array<DictItem> = [];
  streets: Array<DictItem> = [];
  related: Array<DictItem> = [];
  legalCourt: Array<DictItem> = [];

  filesApotroposTZAV = [];
  filesHeirsMessage = [];

  filesDesignType = MojFileUploadDesignType;

  gridOptions!: GridOptions;
  herisColumns!: ColDef[];
  herisData:any;
  gridHerisApi!: GridApi;

  ngOnInit() {

    this.gridOptions = this.gridService.getMojGridOptions();
    this.gridOptions.domLayout = 'autoHeight';

    this.initColumns();

    this.initDropDownData();

  }
  initDropDownData() {

    this.idTypes.push({id:1,value:"ת. זהות"})
    this.idTypes.push({id:2,value:"דרכון"})
    this.idTypes.push({id:3,value:"מס' ביטוח לאומי ארה\"ב"})
    this.idTypes.push({id:4,value:"מס' זהות זר"})

    this.streets.push({id:1,value:"השלום"})
    this.streets.push({id:2,value:"הרואה"})
    this.streets.push({id:3,value:"האלה"})
    this.streets.push({id:4,value:"ויצמן"})

    this.cities.push({id:1,value:"ירושלים"})
    this.cities.push({id:2,value:"תל אביב"})
    this.cities.push({id:3,value:"חיפה"})
    this.cities.push({id:4,value:"באר שבע"})

    this.related.push({id:1,value:"בן"})
    this.related.push({id:2,value:"בת"})
    this.related.push({id:3,value:"אח"})
    this.related.push({id:4,value:"אחות"})


    this.legalCourt.push({ id: 1, value: "בחר" })
    this.legalCourt.push({ id: 2, value: "כשיר" })
    this.legalCourt.push({ id: 3, value: "קטין" })
    this.legalCourt.push({ id: 4, value: "אדם שמונה לו אפוטרופוס" })
  }
  onHerisGridReady(params:any) {
    this.gridHerisApi = params.api;
  }

  initColumns() {
    this.herisColumns = [
      this.gridService.getMojColumn("num", { colDef: { headerName: "requestTexts.submitter" } }),
      this.gridService.getMojColumn("name", { colDef: { headerName: "requestTexts.representor" } }),
      this.gridService.getMojColumn("related", { colDef: { headerName: "requestTexts.representorAddress" } }),
      this.gridService.getMojColumn("legalCourt", { colDef: { headerName: "requestTexts.representorTel" } }),
      this.gridService.getMojColumn("part", { colDef: { headerName: "requestTexts.representorTel" } })
    ];
  }
}
