import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
 
import { AgrotComponent } from './agrot/agrot.component';
import { BakashaLetzavKyumZavaaComponent } from './bakasha-letzav-kyum-zavaa/bakasha-letzav-kyum-zavaa.component';
import { BakashaLetzavYerushaComponent } from './bakasha-letzav-yerusha/bakasha-letzav-yerusha.component';
import { BakashaComponent } from './bakasha/bakasha.component';
import { BakashotComponent } from './bakashot/bakashot.component';
import { FindCourtOrderComponent } from './court-order/find-court-order.component';
import { RequestCopyComponent } from './court-order/request-copy/request-copy.component';
import { DebtsComponent } from './debts/debts.component';
import { DirectGuard } from './guards/direct-guard.service';
import { IpGuard } from './guards/ip-guard.service';
import { HomeComponent } from './home/home.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { CitizenLayoutComponent } from './layouts/citizen-layout.component';
import { CorpLayoutComponent } from './layouts/corp-layout.component';
import { AuthGuard } from './login/auth-guard.service';
import { LoginCorpComponent } from './login/login-corp/login-corp.component';
import { RequestNotification } from './RequestNotification/RequestNotification.component';
import { TguvotComponent } from './tguvot/tguvot.component';
 
export const appRoutes: Routes = [
   
    // {
    //     path: "donations",
    //     loadChildren: () => import('./donations/donations.module').then(m => m.DonationsModule)
    // },
    { path: '', redirectTo: 'home', pathMatch: 'full' }, //default route
    {
        path: '',
        component: CorpLayoutComponent,
        children: [
            {
                path: 'find-court-order',
                component: FindCourtOrderComponent,
                canActivate: [AuthGuard, IpGuard]
            },
            {
                path: 'login-corp',
                component: LoginCorpComponent,
                canActivate: [IpGuard]
            },
        ]
    },
    {
        path: '',
        component: CitizenLayoutComponent,
        children: [
            { path: 'home', component: HomeComponent },
            { path: 'bakashot', component: BakashotComponent },
            { path: 'bakasha', component: BakashaComponent, canActivate: [DirectGuard] },
            { path: 'agrot', component: AgrotComponent },
            // { path: 'check-request', component: BdikatBakashaComponent },
            { path: 'tguvot', component: TguvotComponent },
            { path: 'debts', component: DebtsComponent },
            { path: 'request-copy/:misparTik/:kodBakasha/:kodMachoz/:sugBakasha', component: RequestCopyComponent },
            { path: 'payment/landing', component: LandingPageComponent },
            { path: "bakasha-letzav-yerusha", component: BakashaLetzavYerushaComponent },
            { path: "bakasha-letzav-kyum-zavaa", component: BakashaLetzavKyumZavaaComponent },
            { path: "RequestNotification", component: RequestNotification},
            //{ path: '**', redirectTo: '/home', pathMatch: 'full' }
            // { path: '**', redirectTo: 'home' }
        ]
    }, 


];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { onSameUrlNavigation: "reload" })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
