<div>


  <div class="paddingTop10 font-20 blue3 bold">
    הזוכים על פי הצוואה (שדות המסומנים בכוכבית הנם שדות חובה)
  </div>
  <div>
    <h2 class="font-16 orange2 bold">לאור האמור בצוואה ולעיל, הזוכים על פי הצוואה הם כדלקמן:
    </h2>
  </div>





  <section name="heirsInfo">
    <moj-line>
      <h3 class="font-14" style="float:right;">שורה 1</h3>
      <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span style="color:#CC0000;"
          class="fa fa-times"></span>מחק שורה</h3>
    </moj-line>
    <div>
      <moj-line>
        <moj-dropdownlist required [ngModel]="idTypeSelected" labelWidthColumns="1" [controlWidthColumns]="4"
          name="idType" fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="true" [items]="idTypes">
        </moj-dropdownlist>
      </moj-line>
    </div>

    <div>
      <moj-line>
        <moj-textbox [required]="true" [identification]='idTypeSelected==1' ngModel name="idNumber"
          labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
          [maxlength]="9">
        </moj-textbox>
        <moj-textbox [required]="true" ngModel name="firstName" [labelTextKey]="'שם פרטי'" [maxlength]="50"
          labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
        </moj-textbox>
        <moj-textbox [required]="true" ngModel name="lastName" [labelTextKey]="'שם משפחה'" [maxlength]="50"
          labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
        </moj-textbox>
      </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />


    <div>
      <h2 class="font-16 orange2 bold">מקום מושבו ומענו

      </h2>
    </div>

    <div>
      <moj-line>
        <moj-autocomplete isLabelAboveControl="true" required name="countryHeirs" [labelTextKey]="'ארץ'"
          labelWidthColumns="1" [controlWidthColumns]="4" *ngIf="dataStoreService.countries$ | async as countriesItems"
          [items]="countriesItems" fieldName="value" fieldID="id" [dropdown]="true" [(ngModel)]="countryHeirsSelected">
        </moj-autocomplete>
      </moj-line>
    </div>
    <div>
      <moj-line>
        <moj-autocomplete isLabelAboveControl="true" required name="city" [labelTextKey]="'ישוב'" labelWidthColumns="1"
          [controlWidthColumns]="4" [items]="cities" fieldName="value" fieldID="id" [dropdown]="true"
          [(ngModel)]="citySelected">
        </moj-autocomplete>
        <moj-autocomplete isLabelAboveControl="true" required name="street" [labelTextKey]="'רחוב'"
          labelWidthColumns="1" [controlWidthColumns]="4" [items]="streets" fieldName="value" fieldID="id"
          [dropdown]="true" [(ngModel)]="streetSelected">
        </moj-autocomplete>
        <moj-textbox required ngModel name="houseNumber" [labelTextKey]="'מספר בית'" [maxlength]="50"
          labelWidthColumns="2" [controlWidthColumns]="2" isLabelAboveControl="true">
        </moj-textbox>
        <moj-textbox ngModel name="mikud" [labelTextKey]="'מיקוד'" [maxlength]="50" labelWidthColumns="2"
          [controlWidthColumns]="1" isLabelAboveControl="true">
        </moj-textbox>
      </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />


    <div>
      <moj-line>
        <moj-dropdownlist required [(ngModel)]="relatedSelected" labelWidthColumns="1" [controlWidthColumns]="4"
          name="relatedType" fieldID="id" labelTextKey="קרבתו למנוח" isLabelAboveControl="false" [items]="related">
        </moj-dropdownlist>
      </moj-line>
    </div>
    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
      <moj-line>
        <moj-dropdownlist required [(ngModel)]="legalCourtSelected" labelWidthColumns="1" [controlWidthColumns]="4"
          name="legalCourtType" fieldID="id" labelTextKey="כשרותו המשפטית" isLabelAboveControl="false"
          [items]="legalCourt">
        </moj-dropdownlist>
        <div *ngIf="legalCourtSelected==4">
          <moj-file-upload labelTextKey="צו מינוי אפוטרופוס" labelWidthColumns="1" [controlWidthColumns]="4"
            isLabelAboveControl="true" [required]="true" [id]="'filesApotroposTZAV'" [(ngModel)]="filesApotroposTZAV"
            name="filesApotroposTZAV" show [maxFileSize]="23068672" [enabledFileTypes]="'pdf'"
            designType="filesDesignType.Single">
          </moj-file-upload>
        </div>
      </moj-line>
    </div>

    <div>
      <moj-line>
        <div style="padding-left:30px;">
          <button style="background-color:#B7D3E4;padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
        </div>
      </moj-line>
    </div>
    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

  </section>

  <section name="heirsList">
    <div>
      <h2 class="font-16 orange2 bold">להלן רשימת הזוכים
      </h2>
    </div>

    <moj-grid-panel>
      <ag-grid-angular class="ag-theme-balham paddingTop5" [gridOptions]="gridOptions" [rowData]="herisData"
        [columnDefs]="herisColumns" [suppressHorizontalScroll]="true" [infiniteInitialRowCount]="9" [pagination]="false"
        (gridReady)="onHerisGridReady($event)">
      </ag-grid-angular>
    </moj-grid-panel>
  </section>
  <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

  <section name="message">
    <div>
      <h2 class="font-16 orange2 bold">הודעות לזוכים
      </h2>
    </div>

    <moj-line>
      <div class="col-xs-6 paddingRight0">
        <span style="color:red;">*</span>המבקשת היא היורשת היחידה
      </div>
      <div class="col-xs-5 paddingRight0">
        <div class="col-xs-4 paddingX0">
          <moj-radiobutton [(ngModel)]="isSingelHeirs" [radiovalue]="true" name="singelHeirs" labelTextKey="כן">
          </moj-radiobutton>
        </div>
        <div class="col-xs-4 paddingX0">
          <moj-radiobutton [(ngModel)]="isSingelHeirs" [radiovalue]="false" name="singelHeirs" labelTextKey="לא">
          </moj-radiobutton>
        </div>
      </div>
    </moj-line>
    <div *ngIf="!isSingelHeirs">
      <moj-line>
        <div class="col-xs-6 paddingRight0">
          <span style="color:red;">*</span>האם היורשים יודעים על הגשת בקשה זו?
        </div>
        <div class="col-xs-5 paddingRight0">
          <div class="col-xs-4 paddingX0">
            <moj-radiobutton [(ngModel)]="isHeirsKnow" [radiovalue]="true" name="heirsKnow" labelTextKey="כן">
            </moj-radiobutton>
          </div>
          <div class="col-xs-4 paddingX0">
            <moj-radiobutton [(ngModel)]="isHeirsKnow" [radiovalue]="false" name="heirsKnow" labelTextKey="לא">
            </moj-radiobutton>
          </div>
        </div>
      </moj-line>
    </div>
    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div *ngIf="isHeirsKnow">
      <div>
        <moj-line>
          <moj-radiobutton [(ngModel)]="isAllHeirsKnow" [radiovalue]="true" name="allHeirsKnow"
            labelTextKey="המבקשת הודיעה אישית לכל היורשים ומצהירה כי הם מודעים לבקשה">
          </moj-radiobutton>
        </moj-line>
        <moj-line>
          <moj-radiobutton [(ngModel)]="isAllHeirsKnow" [radiovalue]="false" name="allHeirsKnow"
            labelTextKey="אסמכתא על משלוח דואר רשום או אישור היורשים כי קיבלו הודעה על פי בקשה זו.">
          </moj-radiobutton>
        </moj-line>
      </div>

      <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />


      <div *ngIf="!isAllHeirsKnow">
        <div>
          <h2 class="font-16 orange2 bold"> <span style="color:red;">*</span>
            אסמכתאות על משלוח דואר רשום או אישור היורשים כי קיבלו הודעה על פי בקשה זו
          </h2>
        </div>
        <moj-line>
          <h3 class="font-14" style="float:right;">שורה 1</h3>
          <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
              style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
        </moj-line>

        <div>
          <moj-line>
            <moj-file-upload labelWidthColumns="0" [controlWidthColumns]="5" isLabelAboveControl="false"
              [required]="true" [id]="'filesHeirsMessage'" [(ngModel)]="filesHeirsMessage" name="filesHeirsMessage" show
              [maxFileSize]="23068672" [enabledFileTypes]="'pdf'" designType="filesDesignType.Single">
            </moj-file-upload>
          </moj-line>
        </div>
      </div>

      <div>
        <moj-line>
          <div style="padding-left:30px;">
            <button style="background-color:#B7D3E4;padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
          </div>
        </moj-line>
      </div>
    </div>
    <div *ngIf="!isHeirsKnow">
      <moj-line>
        <moj-textarea [required]="true" ngModel name="messageToHeirs" isLabelAboveControl="true"
          [labelTextKey]="' נא לשלוח הודעה ליורשים עפי תקנה 14. במידה ולא ניתן לשלוח הודעה נמק.'" labelWidthColumns="1"
          [rows]="2" [controlWidthColumns]="11">
        </moj-textarea>
      </moj-line>
    </div>

  </section>
</div>