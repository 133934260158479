import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SiteConfigService } from '../services/SiteConfigService';

@Component({
  selector: 'app-citizen-layout',
  templateUrl: './citizen-layout.component.html',
  styleUrls: ['./citizen-layout.component.scss'],
})
export class CitizenLayoutComponent {
  private _version!: string;
  applicationId: any;
  texts;


  constructor(private configService: SiteConfigService, 
    private translateService: TranslateService) {
      this.texts = this.translateService.instant('Texts.homeTexts')
  }

  get version() {
    return this._version;
  }



  ngOnInit() {
    const config = (<any>this.configService.configuration);
    this._version = config.version.replace("-", ".");
    this.applicationId = config.applicationId;
  }
}