<div>

  <moj-line>
    <h1 class="font-28 moj-bold moj-primary-color">
      {{ deceasedText.debetsOfdeceased }}
    </h1>
  </moj-line>
  <moj-line>
  </moj-line>
  <div class="moj-orange-color moj-bold">{{deceasedText.formMessage}}</div>
  <moj-line>
    <div class="font-12 moj-bold">{{deceasedText.searchAdviseMessage}}</div>
  </moj-line>
  <form #f="ngForm">

    <moj-line>
      <div class="font-20 moj-bold moj-orange-color">{{ deceasedText.deceasedDetails }}</div>
    </moj-line>

    <moj-line>
      <moj-textbox [ngModel]="idNumber" [maxlength]="9" name="idNumber" [labelTextKey]="deceasedText.idNumber"
        [controlWidthColumns]="3" pattern="^[A-Za-z0-9]*$">
      </moj-textbox>

      <moj-textbox [maxlength]="30" [ngModel]="firstName" minlength="2" name="firstName"
        [labelTextKey]="deceasedText.firstName" [controlWidthColumns]="3" pattern="^[A-Za-zא-ת\.\-\s\,\']*$">
      </moj-textbox>

      <moj-textbox [maxlength]="30" [ngModel]="lastName" minlength="2" name="lastName"
        [labelTextKey]="deceasedText.lastName" [controlWidthColumns]="3" pattern="^[A-Za-zא-ת\.\-\s\,\']*$">
      </moj-textbox>

    </moj-line>

    <moj-line></moj-line>
    <moj-line>
      <div class="font-20 moj-bold moj-orange-color">{{ deceasedText.debtsDetails }}</div>
    </moj-line>
    <moj-line>
      <moj-autocomplete [itemsUrl]="itemsUrl" name="localAuthority" #authorityInput [placeholder]="'הקלד רשות מקומית'"
        [labelTextKey]="deceasedText.authority" labelWidthColumns="1" [controlWidthColumns]="3" [fieldName]="'value'"
        [fieldID]="'id'" [(ngModel)]="localAuthority">
      </moj-autocomplete>

      <moj-datepicker [ngModel]="debtsDate" name="debtsDate" labelWidthColumns="1" [controlWidthColumns]="3"
        [labelTextKey]="deceasedText.debtsDate" [maxEnableDate]="maxDate">
      </moj-datepicker>

      <!-- <moj-label [textKey]="deceasedText.claimAuthority" [widthColumns]="2"></moj-label>
      <div class="col-sm-2">
        <div class="col-sm-6">
          <moj-radiobutton  [ngModel]="authorityCollection" name="claimToAuthorityCollection"
            [radiovalue]="true" [labelTextKey]="'כן'">
          </moj-radiobutton>
        </div>
        <div class="col-sm-6">
          <moj-radiobutton  [ngModel]="authorityCollection" name="claimToAuthorityCollection"
            [radiovalue]="false" [labelTextKey]="'לא'">
          </moj-radiobutton>

        </div>
      </div> -->
    </moj-line>
    <moj-line></moj-line>
    <moj-line>
      <moj-buttons-line style="float: left">
        <moj-submit-button *ngIf="isGuard" id="addDebtsBtn" [buttonStyle]="buttonStyle.Primary" [form]="f"
          (onSubmit)="addDebts()" [textKey]="deceasedText.addDebts" [isEnable]="(f.valid && !showLoading)!">
        </moj-submit-button>
        <moj-clear-button [form]="f"></moj-clear-button>
        <moj-submit-button id="searchDebtsBtn" [buttonStyle]="buttonStyle.Primary" [form]="f" (onSubmit)="onSubmit()"
          [textKey]="deceasedText.search" [isEnable]="(f.valid && !showLoading)!">
        </moj-submit-button>
      </moj-buttons-line>
    </moj-line>
  </form>
</div>
<moj-line *ngIf="showGrid">
  <debts-output [data]="debtsResponse" [showLoading]="showLoading">
  </debts-output>
</moj-line>