import { Component, Input, OnInit } from '@angular/core';
import { FontSize, FontWeight, GridService, MojColor } from '@moj/moj-ng';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { BdikatBakashaResponse } from '../../models/bdikat-bakasha.model';

@Component({
  selector: 'bdikat-bakasha-output',
  templateUrl: './bdikat-bakasha-output.component.html',
  styleUrls: ['./bdikat-bakasha-output.component.css']
})
export class BdikatBakashaOutputComponent implements OnInit {
      private _data = new BehaviorSubject<BdikatBakashaResponse[]>([]);
      columnApi: any;

    @Input() 
    set showLoading(value:any){
        if (this.gridApi) {
            (value)? this.gridApi.showLoadingOverlay() :this.gridApi.hideOverlay();
        }
    }

    @Input()
    set data(value: BdikatBakashaResponse[]) {
        this._data.next(value);
    };
    get data() {
        return this._data.getValue();
    }

    private gridApi!: GridApi;
    rowData!: BdikatBakashaResponse[];
    columns: ColDef[];
    fontSize = FontSize;
    fontWeight = FontWeight;
    mojColor = MojColor;
    isToManyRecords: boolean = false;
    gridOptions: GridOptions;

    checkRequestTexts;

    constructor(  private translateService: TranslateService,
                  private gridService: GridService) { 
      // this.gridOptions = dataStoreService.getGeneralGridOptions();
      this.gridOptions = this.gridService.getMojGridOptions();
      this.gridOptions.domLayout = undefined;
      this.checkRequestTexts = this.translateService.instant('Texts.checkRequestTexts');

      this.columns = [
        this.gridService.getMojColumn("shemBeitMishpat", { colDef: { headerName: "checkRequestTexts.court"} }),
        this.gridService.getMojColumn("shnatTik", { colDef: { headerName: "checkRequestTexts.caseYear"} }),
        this.gridService.getMojColumn("hodeshTik", { colDef: { headerName: "checkRequestTexts.caseMonth"} }),
        this.gridService.getMojColumn("misparTik",{ colDef: { headerName: "checkRequestTexts.caseNumber"} }),
        this.gridService.getMojColumn("shnatBakasha",{ colDef: { headerName: "checkRequestTexts.requestYear"} }),
        this.gridService.getMojColumn("misparBakasha",{ colDef: { headerName: "checkRequestTexts.requestNumber"} }),
        this.gridService.getMojColumn("teurSugBakasha",{ colDef: { headerName: "checkRequestTexts.requestType"} }),
        this.gridService.getMojColumn("tzManoach",{ colDef: { headerName: "checkRequestTexts.deceasedIdNumber"} }),
        this.gridService.getMojColumn("shemManoach",{ colDef: { headerName: "checkRequestTexts.deceasedName"} })
      ];  
    }

  ngOnInit() {
        this._data
          .subscribe(x => {
            this.rowData = x; 
            this.isToManyRecords = this.data && (this.data.length >= 100);       
            document.getElementById("bakashotGridDiv")?.addEventListener('keypress',function(e){
                if(e.keyCode == 13 )
                {
                    var cell = document.activeElement;
                    if( cell?.getAttribute("col-id") == "kod")
                    {
                        let elem: HTMLElement = cell.firstChild?.firstChild?.nextSibling as HTMLElement;
                        elem.click();
                    }
                    if(cell?.getAttribute("col-id") == "misparTik")
                    {
                        let elem: HTMLElement = cell.firstChild?.firstChild as HTMLElement;
                        elem.click();
                    }
                }
            });

            var myInput = document.getElementById("searchBakashotBtn");

            var GO = this.gridOptions;
            myInput?.addEventListener("keydown", function (event) {
                    // code for tab key
                    var tabKeyCode = 9;

                    // ignore non tab key strokes
                    if(event.keyCode === tabKeyCode && !event.shiftKey) 
                    {
                    // prevents tabbing into the url section
                    event.preventDefault();                 

                    // scrolls to the first row
                    GO.api?.ensureIndexVisible(0);

                    // scrolls to the first column
                    var firstCol = GO.columnApi?.getAllDisplayedColumns()[0];
                    GO.api?.ensureColumnVisible(firstCol!);

                    // sets focus into the first grid cell
                    GO.api?.setFocusedCell(0, firstCol!);
                    }    
            }, true);

        });
  }

    onGridReady(params:any) { 
      this.gridApi = params.api; 
    } 

}
