import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ButtonStyle, FontSize, FontWeight, MojColor, WizardItemComponentBase } from '@moj/moj-ng';
import { Observable } from 'rxjs';

import { FilterType } from '@moj/moj-ng';
import { TranslateService } from '../../../node_modules/@ngx-translate/core';
import { DictItem } from '../models/dict-item.model';
import { Tguva, TguvaRequest } from '../models/tguva.model';
import { DataStoreService } from '../services/data-store.service';
import { InheritanceDataService } from '../services/inheritance-data.service';

@Component({
  selector: 'tguvot',
  templateUrl: './tguvot.component.html',
  styleUrls: ['./tguvot.component.css']
})
export class TguvotComponent extends WizardItemComponentBase {
  @ViewChild('f', { static: false }) formModel!: NgForm;
  rowData: any;
  buttonStyle = ButtonStyle;
  fontSize = FontSize;
  fontWeight = FontWeight;
  mojColor = MojColor;
  filterType = FilterType;

  BateyMishpat!: DictItem[];
  SugeyTik!: DictItem[];
  courtsListItems: any;
  request!: TguvaRequest;
  tguvot!: Tguva[];
  public yearListItems!: string[];
  public monthListItems!: string[];
  isYearRequired: boolean = false;
  isMonthRequired: boolean = false;
  showLoading!: boolean;
  showGrid: boolean = false;

  sugeyTik$!: Observable<DictItem[]>;
  bateyMishpat$!: Observable<DictItem[]>;

  tguvotTexts;

  constructor(private dataService: InheritanceDataService,
    public dataStoreService: DataStoreService,
    private translateService: TranslateService) {
    super();

    this.tguvotTexts = this.translateService.instant('Texts.tguvotTexts');

    // dataService.getAllBateyMishpat()
    //   .subscribe(output => {
    //     this.BateyMishpat = output.map(x => ({ id: x.id, value: x.value.trim() }))
    //   });

    // dataService.getAllSugeyTik()
    //   .subscribe(output => {
    //     this.SugeyTik = output
    //   });
  }

  ngOnInit() {
    let firstYear = 1947;
    let range = new Date().getFullYear();
    this.yearListItems = Array.from(new Array(range - firstYear), (val, index) => (range - index).toString());
    this.monthListItems = Array.from(new Array(12), (val, index) => (index + 1).toString());
  }

  // שדה שנה הוא חובה:
  //כאשר קוד בית משפט מעל קוד 996
  // כאשר קוד בית משפט מתחת לקוד 10
  //כאשר קוד בית משפט מעל קוד 61 ומתחת לקוד 992
  courtChanged(court: any) {
    const courtId = court.id

    this.isYearRequired =
      courtId > 996 ||
      (courtId < 992 && courtId > 61) ||
      courtId < 10

    // this.isMonthRequired =
    //   (court < 10 ||
    //     (court > 61 && court < 992) ||
    //     court > 996)
  }

  retrieveData() {
    this.showGrid = true;
    this.showLoading = true;
    let x = this.formModel.value;

    let fileTypeId!: string;
    let fileMonth = "";
    let shnatBakasha = "";
    let requestNumber = "";
    if (x.type != null && x.type) {
      fileTypeId = x.type.id.toString();
    }
    if (x.month != null && x.month) {
      fileMonth = x.month.toString();
    }
    if (x.shnatBakasha != null && x.shnatBakasha) {
      shnatBakasha = x.shnatBakasha.toString();
    }
    if (x.requestNumber != null && x.requestNumber) {
      requestNumber = x.requestNumber.toString();
    }

    // this.recaptchaService.executeRecaptcha()
    //   .subscribe((res) => {
    //     this.model.recaptchaString = res;
    var request: any = {
      hodeshTik: fileMonth,
      shnatTik: x.year != null ? x.year.toString() : null,
      misparTik: x.number.toString(),
      kodSugTik: fileTypeId,

      shnatBakasha: shnatBakasha,
      misparBakasha: requestNumber,

      kodBeitMishpat: x.court.id.toString(),
      kodBakasha: null,
      recaptchaModel: null
      // recaptchaModel: this.model
    };
    this.dataService.getTguvotYoetz(request)
      .subscribe(output => {
        this.tguvot = output;
        this.showLoading = false
      }, error => this.showLoading = false);
    // }, error => this.showLoading = false);
  }

  onSubmit() { this.retrieveData(); }
}
