import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ButtonStyle, FontSize, FontWeight, MessageType, MojColor, MojDialogService, MojMessagesService } from '@moj/moj-ng';
import { TranslateService } from '@ngx-translate/core';
import { DataStoreService } from '../services/data-store.service';
import { InheritanceDataService } from '../services/inheritance-data.service';
import { AddNewDebtComponent } from './add-new-debt/add-new-debt.component';

@Component({
  selector: 'app-debts',
  templateUrl: './debts.component.html',
  styleUrls: ['./debts.component.css']
})
export class DebtsComponent implements OnInit {


  @ViewChild('f', { static: false }) formModel!: NgForm;
  @ViewChild('authorityInput', { static: false }) authorityInput: any;
  @ViewChild('authorityInput', { read: ElementRef, static: false }) authoritySearchInput!: ElementRef;

  deceasedText;
  debtsDate: any;
  firstName: any;
  lastName: any;
  idNumber: any;
  localAuthorityId: any;
  localAuthority: any;

  authorityCollection: any;
  isActiv: any;
  maxDate: Date = new Date();

  isGuard!: boolean;
  autorityName!: string;
  buttonStyle = ButtonStyle;
  fontSize = FontSize;
  fontWeight = FontWeight;
  mojColor = MojColor;

  showLoading!: boolean;
  showGrid: boolean = false;
  debtsResponse: any;
  itemsUrl:any;

  constructor(private dataService: InheritanceDataService,
    public dataStoreService: DataStoreService,
    private translateService: TranslateService,
    private messagesService: MojMessagesService,
    private dialogService: MojDialogService) {

    this.deceasedText = this.translateService.instant('Texts.deceasedText');

  }
  ngOnInit() {
    this.itemsUrl = this.dataService.getAuthorities();
    this.dataService.getAuthorityName().subscribe(res => {
      if (res)
        this.isGuard = res != ''
    });
  }



  isAllFieldsEmpty() {
    let data = this.formModel.value;
   
    if (!data.localAuthority &&
      !data.idNumber &&
      !data.firstName &&
      !data.lastName &&
      !data.debtsDate
    )
      return true;
    return false;
  }



  onSubmit() {
    this.retrieveData();
  }

  retrieveData() {
    if (this.isAllFieldsEmpty()) {
      this.messagesService.showMessage(
        { messageText: "Texts.atLeastOneFieldRequired", messageTitle: "MojMessages.Message", messageType: MessageType.Message });
      return;
    }

    this.showGrid = true;
    this.showLoading = true;
    let request = this.formModel.value;
    request.localAuthorityId = request.localAuthority;

    this.dataService.getDeceasedDebts(request)
      .subscribe(
        output => {
          this.debtsResponse = output;
          this.showLoading = false;
        },
        error => {
          this.showLoading = false;
        });
  }

  addDebts() {
    this.openContentDialog();
  }

  public openContentDialog() {
    const dialogtitleText = "הוספת חוב"

    this.dialogService.openDialog({
      title: 'dialogtitleText',
      isAddFooter: false,
      preventScroll: true, closable: true
    }, AddNewDebtComponent);


  }

}
