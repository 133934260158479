import { Component, OnInit } from '@angular/core';
import { MojFileUploadDesignType } from '@moj/moj-ng';
 
@Component({
  selector: 'app-yerusha-tab6',
  templateUrl: './yerusha-tab6.component.html'
})
export class YerushaTab6Component implements OnInit {

  filesPo = [];
  fileDeathCertificateDeceased = [];
  fileConfirmationCloseTik = [];
  fileDeathCertificateHeirs = [];
  fileTZAVforDeceasedHeirsAfterDeceased = [];
  fileAttachments = [];
  filesDesignType = MojFileUploadDesignType;

  isApproval!:boolean;
  isPtor!:boolean;



  constructor() { }

  ngOnInit() {
  }



}
