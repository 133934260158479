import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericPaymentService } from '@moj/generic-payment';
import { ButtonData, ButtonStyle } from '@moj/moj-ng';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  paymentParamsToDisplay: string[] = ["OrderNumber", "PayDate", "CustomerId", "CustomerName", "TotalCheck"];
  paramsToDisplay: string[] = ["OrderNumber", "RequestId"];
  serviceParamsToDisplay: string[] = ["RequestId", "Description"];
  mainTitle!: string;
  mainText!: string;
  requestTexts;

  buttons: [ButtonData] = [
    { id: 1, text: "חזרה לאיתור בקשה", buttonStyle: ButtonStyle.Primary },
  ];

  constructor(private router: Router,
    private genericPayment: GenericPaymentService,
    private translateService: TranslateService) {
    this.requestTexts = this.translateService.instant('Texts.requestTexts');
  }

  ngOnInit() {
    this.genericPayment.landingParams.subscribe((res: any) => {
      if (res?.shohamData?.length > 0) {
        res.returnedParameters = res['shohamData'];
        this.mainTitle = `${res.anotherData.shohamResponseHeader}`;
        this.mainText = `${res.anotherData.shohamResponseTextList[0]}. ${this.requestTexts.requestSent}`
      }
    });
  }

  buttonClick(button: ButtonData) {
    if (button.id == 1) {

      this.router.navigate(['/bakashot']);

    }
  }



}
