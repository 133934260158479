import { Component, OnInit } from '@angular/core';
import { MojFileUploadDesignType } from '@moj/moj-ng';
import { DictItem } from '../../../models/dict-item.model';
import { DataStoreService } from '../../../services/data-store.service';

@Component({
  selector: 'app-yerusha-tab3',
  templateUrl: './yerusha-tab3.component.html'
})
export class YerushaTab3Component implements OnInit {
  isSpouse!: boolean;
  isOffsprings!: boolean;
  isFatherAlive!: boolean;
  isMotherAlive!: boolean;
  isCar!: boolean;
  isApartment!: boolean;
  isApartmentHeirs!: boolean;
  isApartmentOtherHeirs!: boolean;
  isMarriedMoreThanThreeYears!: boolean;
  isSpouseLivedTogether!: boolean;
  isChildAlive!: boolean;
  isChildLeftEstate!: boolean;
  isDeceasedChildHadChildren!: boolean;
  idTypeSpouseSelected = 1;
  idTypeChildSelected = 1;
  idTypeChildChildSelected = 1;
  idTypeFatherSelected = 1;
  idTypeMotherSelected = 1;
  countrySpouseSelected!: number;
  citySpouseSelected!: number;
  streetSpouseSelected!: number;
  cityApartmentSelected!: number;
  streetApartmentSelected!: number;
  countryFatherSelected!: number;
  countryMotherSelected!: number;
  cityFatherSelected!: number;
  cityMotherSelected!: number;
  streetFatherSelected!: number;
  streetMotherSelected!: number;
  hebrewYearFatherDeathSelected!: number;
  hebrewYearMotherDeathSelected!: number;
  hebrewMonthFatherDeathSelected!: number;
  hebrewMonthMotherDeathSelected!: number;
  hebrewDayFatherDeathSelected!: number;
  hebrewDayMotherDeathSelected!: number;
  dateOfDeathFather:any;
  dateOfDeathMother:any;
  idTypes: Array<DictItem> = [];
  cities: Array<DictItem> = [];
  streets: Array<DictItem> = [];
  hebrewDays: Array<DictItem> = [];
  hebrewMonths: Array<DictItem> = [];
  hebrewYears: Array<DictItem> = [];
  fuDesignType = MojFileUploadDesignType;   
  filesChild = []; 
  filesFatherProofOfDeath = [];
  filesMotherProofOfDeath = [];
  





  constructor(public dataStoreService: DataStoreService) { }

  ngOnInit() {
    this.idTypes.push({id:1,value:"ת. זהות"})
    this.idTypes.push({id:2,value:"דרכון"})
    this.idTypes.push({id:3,value:"מס' ביטוח לאומי ארה\"ב"})
    this.idTypes.push({id:4,value:"מס' זהות זר"})

    this.streets.push({id:1,value:"השלום"})
    this.streets.push({id:2,value:"הרואה"})
    this.streets.push({id:3,value:"האלה"})
    this.streets.push({id:4,value:"ויצמן"})

    this.cities.push({id:1,value:"ירושלים"})
    this.cities.push({id:2,value:"תל אביב"})
    this.cities.push({id:3,value:"חיפה"})
    this.cities.push({id:4,value:"באר שבע"})

    this.hebrewDays.push({id:1,value:"א'"})
    this.hebrewDays.push({id:2,value:"ב'"})
    this.hebrewDays.push({id:3,value:"ג'"})
    this.hebrewDays.push({id:4,value:"ד'"})

    this.hebrewMonths.push({id:1,value:"תשרי"})
    this.hebrewMonths.push({id:2,value:"חשון"})
    this.hebrewMonths.push({id:3,value:"כסלו"})
    this.hebrewMonths.push({id:4,value:"טבת"})

    this.hebrewYears.push({id:1,value:"תרס''א"})
    this.hebrewYears.push({id:2,value:"תרס''ב"})
    this.hebrewYears.push({id:3,value:"תרס''ג"})
    this.hebrewYears.push({id:4,value:"תרס''ד"})
  }

}
