<moj-line>
    <moj-line>
        <h2 class="moj-primary-color">{{ deceasedText.searchResults }}</h2>
    </moj-line>
    <div *ngIf="isToManyRecords">
        <div class="font-12 moj-bold">{{ deceasedText.SearchResultsMessage }}</div>
    </div>
    <div *ngIf="data?.length! < 100">
        <div class="font-12 moj-bold">{{'נמצאו ' + (data?.length || 0) + ' תוצאות התואמות לחיפוש'}}</div>
    </div>
    <div class="moj-orange-color moj-bold">{{deceasedText.clarification}}</div>
    <div class="paddingTop15" id="deGridDiv">

        <moj-grid-panel>
            <ag-grid-angular #agGrid id="myGrid" style="height: 500px" [suppressHorizontalScroll]="true"
                class="ag-theme-balham" [gridOptions]="gridOptions" [rowData]="rowData" [columnDefs]="columnDefs"
                [masterDetail]="true" [defaultColDef]="defaultColDef" [animateRows]="true"
                [detailCellRenderer]="detailCellRenderer" [frameworkComponents]="frameworkComponents"
                [pagination]="false" [infiniteInitialRowCount]="9" [suppressHorizontalScroll]="true"
                (rowClicked)='onRowClicked($event)' (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </moj-grid-panel>
        <!-- 
          <p-dataView #listView [value]="rowData" [paginator]="true" [rows]="10">
            <ng-template let-item pTemplate="listItem">
                <moj-list-item [listItemType]="Enums.ListItemType.Details">
                    <moj-list-item-body>
                        <h3>{{item.ProductName}}</h3>
                        <br>
                        <span>עוד טקסט דינאמי, ניתן לשים כאן תמונות, קישורים וכל דבר</span>
                    </moj-list-item-body>
                    <moj-list-item-ext>
                        <moj-grid-edit-button [item]="item"></moj-grid-edit-button>
                        <moj-grid-delete-button [item]="item"></moj-grid-delete-button>
                         <button><i class="far fa-exclamation"></i></button>
                    </moj-list-item-ext>
                </moj-list-item>
            </ng-template>
        </p-dataView> -->

    </div>
</moj-line>