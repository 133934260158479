import { Component, OnInit } from '@angular/core';
import { MojFileUploadDesignType } from '@moj/moj-ng';

@Component({
  selector: 'app-zavaa-tab6',
  templateUrl: './zavaa-tab6.component.html',
  styleUrls: ['./zavaa-tab6.component.css']
})
export class ZavaaTab6Component implements OnInit {

  filesPo = [];
  fileDeathCertificateDeceased = [];
  fileConfirmationCloseTik = [];
  fileDeathCertificateHeirs = [];
  fileTZAVforDeceasedHeirsAfterDeceased = [];
  fileAttachments = [];
  filesDesignType = MojFileUploadDesignType;

  isApproval!:boolean;
  isPtor!:boolean;

  constructor() { }

  ngOnInit() {
  }

}
