import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ButtonStyle, Enums, ENUMS, FontSize, FontWeight, MessageType, MojColor, MojLoadingService, MojMessagesService, MojRecaptchaService } from '@moj/moj-ng';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../login/auth-service.service';
import { Bakasha, BakashaForCourtOrderResponse } from '../models/bakasha.model';
import { CopyDownloadAction } from '../models/copy-download-action.model';
import { RecaptchaModel } from '../models/recaptcha-model';
import { InheritanceDataService } from '../services/inheritance-data.service';

@Component({
    selector: 'find-court-order',
    templateUrl: './find-court-order.component.html',
    styleUrls: ['./find-court-order.component.css']
})
export class FindCourtOrderComponent {

    @ViewChild('f', { static: false }) formModel!: NgForm;
    @ViewChild("pnl", { read: ViewContainerRef, static: false }) pnl!: ViewContainerRef;
    bakashot: Bakasha[] = [];
    buttonStyle = ButtonStyle;
    fontSize = FontSize;
    fontWeight = FontWeight;
    mojColor = MojColor;
    mojEnum: Enums = ENUMS;
    textToShow!: string;
    isCourtOrderExists!: boolean;
    columns!: ColDef[];
    isAnyDataAvail!: boolean;
    isTzChecked = true;
    machoz!: number;
    misTik!: number;
    idOptions = true;
    copyDownloadActionObj!: CopyDownloadAction;
    gridApi!: GridApi;
    isGridHidden: boolean = false;
    isRabani!: boolean;
    recapchaModel!: RecaptchaModel;
    courtOrderTexts;

    constructor(private dataService: InheritanceDataService,
        private loadingService: MojLoadingService,
        private authService: AuthService,
        private translateService: TranslateService,
        private messageService: MojMessagesService,
        private recaptchaService: MojRecaptchaService) {
        this.courtOrderTexts = this.translateService.instant('Texts.courtOrderTexts');
    }

    downloadFile(bakasha: Bakasha) {
        this.loadingService.show(this.pnl);
        let client = this.authService.clientData;
        if (client == null) {
            this.authService.logout();
        }

        client.misTik = bakasha.misparTik;
        client.region = bakasha.kodMachoz;
        var request = {
            clientData: client,
            recaptchaModel: null,
            isRabani: this.isRabani,
            idNumber: bakasha.tzManoach,
            isTz: this.idOptions
        }

        this.dataService.getFileDownload(request)
            .pipe(
                finalize(() => { this.loadingService.hide(); })
            )
            .subscribe((data: any) => {
                let fileStatus = data.headers.get('fileStatus');
                let fileName = bakasha.tzManoach + " " + this.courtOrderTexts.inheritanceCopy + ".pdf";

                console.log("fileStatus=", fileStatus);

                switch (fileStatus) {
                    case "0":
                        this.download(data.body, fileName);
                        break;
                    case "1":
                        this.messageService.showMessage({ messageTitle: "MojTexts.errorMessage", messageText: "קובץ לא קיים", messageType: MessageType.Error });
                        break;
                    case "2":
                        this.messageService.showMessage({ messageTitle: "MojTexts.errorMessage", messageText: this.courtOrderTexts.fileTooBig, messageType: MessageType.Error });
                        break;
                }
                this.loadingService.hide();

            }, () => {
                this.loadingService.hide();
            })
    }
    download(data: any, fileName: string) {
        var nav = navigator.userAgent.toLowerCase();
        var is_IE = (nav.indexOf('msie') != -1) || (nav.indexOf('trident') != -1) ? true : false;

        var a = window.document.createElement('a');

        // if (is_IE)//IE
        // window.navigator.msSaveOrOpenBlob(new Blob([data], { type: 'application/pdf' }), fileName);
        // else {
        a.href = window.URL.createObjectURL(data);
        a.download = fileName;
        // Append anchor to body.
        document.body.appendChild(a)
        a.click();
        // Remove anchor from body
        document.body.removeChild(a)
        // }
    }

    retrieveData() {
        let id = this.formModel.value.idNumber;
        // remove leading zeroes if id and not darkon
        if (this.idOptions)
            id = id.replace(/^0+/, '');
        this.recapchaModel = new RecaptchaModel();
        this.recaptchaService.executeRecaptcha().subscribe((res) => {
            this.recapchaModel.recaptchaString = res;
            this.dataService.getBakashaForCourtOrder(id, !!this.idOptions, this.recapchaModel)
                .pipe(
                    finalize(() => { this.loadingService.hide(); })
                )
                .subscribe(output => {
                    this.handleData(output);
                }
                )
        })
    }

    handleData(data: BakashaForCourtOrderResponse) {
        this.textToShow = "";
        this.isCourtOrderExists = false;
        if (!data) {
            this.textToShow = this.courtOrderTexts.noFileFoundForDeceased;
            this.isAnyDataAvail = false;
        }
        else {
            this.isRabani = data.isRabani;
            this.isAnyDataAvail = true;
            if (data.status == 30 && data.causeOfClosing == 1) {
                this.isCourtOrderExists = true;
            }
            else {
                this.textToShow = "2416@justice.gov.il " + this.courtOrderTexts.fileClosed;
            }
            let bakasha = data.bakashaBasic;

            this.bakashot = [];
            this.bakashot.push(bakasha);
        }
    }

    onSubmit() {
        this.loadingService.show(this.pnl);
        this.retrieveData();
    }

    // getIdDarkonLabel() {
    //     console.log("in find-courtorder getIdDarkonLabel this.formModel.value=", this.formModel.value)
    // }
}
