export enum SugBakashaEnum {
    Itnagdut = 20,
    etekTzav = 33,
    etekTzavApostille = 109,
    BakashaChadasha = 1000,
    TguvaLehachlatatRasham = 105,
    HashlamatMismachimMazkirut = 107,
    HashlamatMismachimYoetz = 108,
    HashlamatMismachimOzerRasham = 106
}
