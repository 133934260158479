import { Component } from '@angular/core';
import { SiteConfigService } from '../services/SiteConfigService';

@Component({
  selector: 'app-corp-layout',
  templateUrl: './corp-layout.component.html',
  // template: `
  //   <div class="container">
  //       <router-outlet></router-outlet>
  //   </div>
  // `,
  styles: []
})
export class CorpLayoutComponent {
  private _version!: string;

  constructor(private configService: SiteConfigService
  ) { }
  get version() {
    return this._version;
  }

  ngOnInit() {
    this._version = (<any>this.configService.configuration).version.replace("-", ".");
  }
}