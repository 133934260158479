import { Component, OnInit } from '@angular/core';
// import { Observable } from 'rxjs';

// import { AuthService } from '../login/auth-service.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html'
  })
  export class MenuComponent implements OnInit
  { 
    // isLoggedIn$: Observable<boolean>;   

  constructor() { }

  ngOnInit() {
    // this.isLoggedIn$ = this.authService.isLoggedIn;
  }

  // onLogout(){
  //   this.authService.logout();
  // }
  }