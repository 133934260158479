import { Component, Input } from '@angular/core';
import { GridService } from '@moj/moj-ng';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { Tguva } from '../../models/tguva.model';

@Component({
  selector: 'tguvot-output',
  templateUrl: './tguvot-output.component.html',
  styleUrls: ['./tguvot-output.component.css']
})
export class TguvotOutputComponent {

  gridOptions: GridOptions;
  private _data = new BehaviorSubject<Tguva[]>([]);
  isGridHidden: boolean = true;

  @Input()
  set showLoading(value: any) {
    if (this.gridApi)
      (value) ? this.gridApi.showLoadingOverlay() : this.gridApi.hideOverlay();
  }

  @Input()
  set data(value) {
    // set the latest value for _data BehaviorSubject
    this._data.next(value);
  };
  get data() {
    // get the latest value from _data BehaviorSubject
    return this._data.getValue();
  }

  private gridApi!: GridApi;
  rowData!: Tguva[];
  columns: ColDef[];
  tguvotTexts;


  constructor(private gridService: GridService,
    private translateService: TranslateService) {

    this.tguvotTexts = this.translateService.instant('Texts.tguvotTexts');
    // this.gridOptions = this.dataStoreService.getGeneralGridOptions();
    this.gridOptions = this.gridService.getMojGridOptions();
    this.gridOptions.domLayout = 'normal';
    console.log("in tguvot-output.component this.gridOptions=", this.gridOptions);


    this.columns = [
      this.gridService.getMojColumn("shemsugtik", { colDef: { headerName: "tguvotTexts.caseType" } }),
      this.gridService.getMojColumn("shnatBakasha", { colDef: { headerName: "tguvotTexts.requestYear" } }),
      this.gridService.getMojColumn("misparBakasha", { colDef: { headerName: "tguvotTexts.requestNumber" } }),
      this.gridService.getMojColumn("shemSugTguva", { colDef: { headerName: "tguvotTexts.responseType" } }),
      this.gridService.getMojDateColumn("taarichMatanTguva", { colDef: { headerName: "tguvotTexts.responseDate" } })
    ];
  }

  ngOnInit() {
    this._data
      .subscribe(x => {
        this.rowData = this.data;
        console.log("this.rowData=", this.rowData);
      });
  }

  onGridReady(params:any) {
    console.log("********* in onGridReady");
    console.log("********* in this.columns=", this.columns);

    this.gridApi = params.api;
    console.log("********* inthis.gridApi=", this.gridApi);

    this.isGridHidden = false;
  }
}
