<div>
  <moj-line>
    <moj-line>
      <h1 class="font-28 moj-bold moj-primary-color">
        {{ feesTexts.feesRates }}
      </h1>
    </moj-line>
  </moj-line>
  <moj-line>
    <div class="paddingRight0" role="search">
      <moj-autocomplete (onSelect)="onSelectSugBakasha()" (onKeyUp)="onSelectSugBakasha()" name="sugBakasha"
        [placeholder]="feesTexts.all" [labelTextKey]="feesTexts.requestType" labelWidthColumns="1"
        [controlWidthColumns]="3" [items]="sugeyBakashot" fieldName="name" fieldID="id" [dropdown]="true"
        [(ngModel)]="sugBakachaSelected" (blur)="onBlur()">
      </moj-autocomplete>
    </div>
  </moj-line>
  <moj-line class="paddingTop20">
    <moj-grid-panel>
      <ag-grid-angular style="height: 500px" class="ag-theme-balham" [gridOptions]="gridOptions" [rowData]="rowData"
        [columnDefs]="columns" [pagination]="false" [infiniteInitialRowCount]="9" [suppressHorizontalScroll]="true"
        (gridReady)="onGridReady($event)" (rowDataChanged)="rowDataChanged()">
      </ag-grid-angular>
    </moj-grid-panel>
  </moj-line>
</div>