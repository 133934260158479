import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ButtonStyle, ButtonToggleItem, ElementSize, FontSize, FontWeight, LabelAlign, MessageType, MojColor, MojMessagesService } from '@moj/moj-ng';
import { TranslateService } from '@ngx-translate/core';
import { Bakasha } from '../models/bakasha.model';
import { BdikatBakashaRequest, BdikatBakashaResponse } from '../models/bdikat-bakasha.model';
import { BahashotDataService } from '../services/bakashot-data.service';
import { DataStoreService } from '../services/data-store.service';
import { InheritanceDataService } from '../services/inheritance-data.service';

@Component({
    selector: 'bakashot',
    templateUrl: './bakashot.component.html',
    styleUrls: ['./bakashot.component.css'],
    // changeDetection: 
})
export class BakashotComponent implements OnInit {
    MAX_SQL_INT: number = 2147483647;

    @ViewChild('f', { static: false }) formModel!: NgForm;
    @ViewChild("pnl", { read: ViewContainerRef, static: false }) pnl!: ViewContainerRef;

    bakashot!: Bakasha[];

    buttonStyle = ButtonStyle;
    fontSize = FontSize;
    fontWeight = FontWeight;
    mojColor = MojColor;
    labelAlign = LabelAlign;
    elementSize = ElementSize

    taarichFrom: any;
    taarichTo: any;
    shemPratiManoach: any;
    shemMishpachaManoach: any;
    tzManoah: any;
    misparTik: any;
    machoz: any;

    showLoading!: boolean;
    showLoadingCouncel: boolean = false;
    showGrid: boolean = false;
    showGridCouncel: boolean = false;
    requestsTexts;

    bdikatBakashotResponse!: BdikatBakashaResponse[];
    buttonToggleItems: ButtonToggleItem[] = [
        { id: 1, text: 'בקשות רשם הירושה' },
        { id: 2, text: 'בקשות לערכאות אחרות' },
    ];
    //   buttonToggleItems$ : BehaviorSubject<ButtonToggleItem[]> =new BehaviorSubject<ButtonToggleItem[]> (this.buttonToggleItems) ;

    gridType = 1;

    constructor(private inheritanceDataService: InheritanceDataService,
        private messagesService: MojMessagesService,
        private bakashotDataService: BahashotDataService,
        public dataStoreService: DataStoreService,
        private translateService: TranslateService) {

        this.requestsTexts = this.translateService.instant('Texts.requestsTexts');
    }

    initSearchParamInView(request: any) {
        this.shemPratiManoach = request.shemPratiManoach;
        this.shemMishpachaManoach = request.shemMishpachaManoach;
        this.tzManoah = request.tZManoach;
        this.misparTik = request.misparTik;
        this.machoz = request.kodMachoz;
        this.taarichFrom = request.date_From ? new Date(request.date_From) : "";
        this.taarichTo = request.date_To ? new Date(request.date_To) : "";
    }

    initRequestForSearch() {
        let data = this.formModel.value;

        const first = data.shemPratiManoach;
        const last = data.shemMishpachaManoach;
        const id = data.tzManoah;

        let request = {
            kod: null,
            tZManoach: id ? id : "",
            shemMishpachaManoach: last ? last : "",
            shemPratiManoach: first ? first : "",
            misparTik: data.misparTik ? data.misparTik : "",
            date_From: data.taarichFrom ? data.taarichFrom.toDateString() : null,
            date_To: data.taarichTo ? data.taarichTo.toDateString() : null,
            kodMachoz: data.machoz ? data.machoz : ""
        }
        return request;
    }



    ngOnInit() {
        let req = this.bakashotDataService.getPreviousRequestForSearch();
        this.initSearchParamInView(req);
    }

    isAllFieldsEmpty() {
        let data = this.formModel.value;
        if (!data.shemPratiManoach &&
            !data.shemMishpachaManoach &&
            !data.tzManoah &&
            !data.misparTik &&
            !data.taarichFrom &&
            !data.taarichTo &&
            !data.machoz) {
            return true;
        }

        return false;
    }

    retrieveData() {

        if (this.isAllFieldsEmpty()) {
            this.messagesService.showMessage(
                {
                    messageText: "Texts.atLeastOneFieldRequired",
                    messageTitle: "MojMessages.Message",
                    messageType: MessageType.Message
                });
            return;
        }
        this.showGrid = true;
        this.showLoading = true;
        var request = this.initRequestForSearch();

        this.bakashotDataService.setCurrentRequestForSearch(request)

        this.inheritanceDataService.getBakashot(request)
            .subscribe(output => {
                this.bakashot = output;

                this.showLoading = false;
                this.changeToggleResults();
            },
                err => {
                    this.showLoading = false;
                });

        this.councelRetrieveData();

    }

    changeToggleResults() {
        this.buttonToggleItems = [
            { id: 1, text: 'בקשות רשם הירושה' + ' (' + (this.bakashot ? this.bakashot.length.toString() : '0') + ')' },
            { id: 2, text: 'בקשות לערכאות אחרות' + ' (' + (this.bdikatBakashotResponse ? this.bdikatBakashotResponse.length.toString() : '0') + ')' }
        ];
    }

    councelRetrieveData() {
        this.bdikatBakashotResponse = [];
        let f = this.formModel.value;
        this.showGridCouncel = true;
        this.showLoadingCouncel = true;
        let req: BdikatBakashaRequest = { kod: undefined, misparTik: undefined, tzManoach: f.tzManoah, shemPratiManoach: f.shemPratiManoach, shemMishpachaManoach: f.shemMishpachaManoach };


        this.inheritanceDataService.getCouncelRequests(req)
            .subscribe(output => {

                this.bdikatBakashotResponse = output;
                this.showLoadingCouncel = false;
                this.changeToggleResults();
            },
                error => {
                    this.showLoadingCouncel = false;
                    this.bdikatBakashotResponse = [];
                });
    }

    onSubmit() {
        console.log("on submit");

        this.retrieveData();
    }
}
