import { Injectable } from '@angular/core';
import { GridService } from '@moj/moj-ng';
import { GridOptions } from 'ag-grid-community';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApostilleCountry } from '../models/apostille.model';
import { IBakashaParams } from '../models/bakasha-params.model';
import { DictItem } from '../models/dict-item.model';
import { TaarifAgrotItem } from '../models/taarif-agrot-item.model';
import { InheritanceDataService } from './inheritance-data.service';

@Injectable()
export class DataStoreService {

  private _machozot$ = new BehaviorSubject(new Array<DictItem>());
  private _sugeyTik$ = new BehaviorSubject(new Array<DictItem>());
  private _bateyMishpat$ = new BehaviorSubject(new Array<DictItem>());
  private _agrot$ = new BehaviorSubject(new Array<TaarifAgrotItem>());
  private _countries$ = new BehaviorSubject(new Array<DictItem>());
  private _sugNewBakasha$ = new BehaviorSubject(new Array<DictItem>());
  private _bakashaParams$ = new BehaviorSubject({} as IBakashaParams);
  private _apostilleCountries$ = new BehaviorSubject(new Array<ApostilleCountry>());

  constructor(private inheritanceData: InheritanceDataService, private gridService: GridService) {
    this.inheritanceData.getAllMachozot().subscribe(data => { this._machozot$.next(data) });
    this.inheritanceData.getAllSugeyTik().subscribe(data => { this._sugeyTik$.next(data) });
    this.inheritanceData.getAllBateyMishpat().subscribe(data => { this._bateyMishpat$.next(data) });
    this.inheritanceData.getAllTaarifeyAgrot().subscribe(data => { this._agrot$.next(data) });
    this.inheritanceData.getAllCountries().subscribe(data => { this._countries$.next(data) });
    this.inheritanceData.getAllSugNewBakasha().subscribe(data => { this._sugNewBakasha$.next(data) });
    this.inheritanceData.getApostilleCountries().subscribe(data => { this._apostilleCountries$.next(data.countriesList) });
  }

  get machozot$(): Observable<DictItem[]> { return this._machozot$.asObservable() }
  get sugeyTik$(): Observable<DictItem[]> { return this._sugeyTik$.asObservable() }
  get bateyMishpat$(): Observable<DictItem[]> { return this._bateyMishpat$.asObservable() }
  get agrot$(): Observable<TaarifAgrotItem[]> { return this._agrot$.asObservable() }
  get countries$(): Observable<DictItem[]> { return this._countries$.asObservable() }
  get sugNewBakasha$(): Observable<DictItem[]> { return this._sugNewBakasha$.asObservable() }
  get bakashaParams$(): Observable<IBakashaParams> { return this._bakashaParams$.asObservable() }
  get apostilleCountries$(): Observable<ApostilleCountry[]> { return this._apostilleCountries$.asObservable() }

  setBakashaParams$(bakashaParams: IBakashaParams) {
    this._bakashaParams$.next(bakashaParams)
  };

  public getGeneralGridOptions() {
    let gridOptions: GridOptions = this.gridService.getMojGridOptions();
    gridOptions.sideBar = false;
    return gridOptions;
  }

}
