<div #pnl>
    <moj-line>
        <moj-line>
            <h1 class="font-24 moj-bold moj-primary-color">{{ loginTexts.header }}</h1>
        </moj-line>
    </moj-line>
    <moj-line>
        <div class="moj-bold"> {{ loginTexts.infoMessage }} </div>
    </moj-line>
    <form #f="ngForm">
        <div role="search">
            <moj-line>
                <moj-textbox [identification]="true" [required]="true" ngModel name="idNumber"
                    [labelTextKey]="loginTexts.idNumber" labelWidthColumns="2" [controlWidthColumns]="4"
                    isLabelAboveControl="true" [maxlength]="9">
                </moj-textbox>
            </moj-line>
            <moj-line>
                <moj-textbox [required]="true" ngModel name="firstName" [labelTextKey]="loginTexts.firstName"
                    labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
                </moj-textbox>
            </moj-line>
            <moj-line>
                <moj-textbox [required]="true" ngModel name="lastName" [labelTextKey]="loginTexts.lastName"
                    labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
                </moj-textbox>
            </moj-line>
            <moj-line>
                <moj-textbox email [required]="true" ngModel name="email" [labelTextKey]="loginTexts.email"
                    labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
                </moj-textbox>
            </moj-line>
            <moj-line>
                <div class="paddingTop15">
                    <div class="moj-danger-color moj-bold"> {{ loginTexts.warningMessage }} </div>
                </div>
            </moj-line>
            <moj-line>
                <div class="col-sm-4 col-xs-12 text-left paddingTop20">
                    <moj-submit-button class="paddingLeft15" id="loginBtn" [buttonStyle]=buttonStyle.Primary [form]="f"
                        (onSubmit)="onSubmit()" [textKey]="loginTexts.access" [isEnable]="f.valid!">
                    </moj-submit-button>
                </div>
            </moj-line>
        </div>
    </form>
</div>