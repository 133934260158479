<div #pnl>
    <moj-line>
        <h1 class="font-24 moj-bold moj-primary-color" style="float: right;">{{ courtOrderTexts.viewOrderRequest }}</h1>
        <a class="font-20 moj-bold" style="float: left;" [routerLink]="['/home']">{{ courtOrderTexts.backToHomePage }}
            ➦</a>
    </moj-line>
    <div id="layout-content" class="container">
        <moj-line>
            <h2 class="font-20 moj-bold moj-orange-color">{{ courtOrderTexts.deceasedDetails }}</h2>
        </moj-line>
        <form (ngSubmit)="onSubmit()" #f="ngForm">
            <div role="search">
                <moj-line>
                    <div class="col-xs-3">
                        <moj-radiobutton [(ngModel)]="idOptions" [radiovalue]="true" name="id"
                            [labelTextKey]="courtOrderTexts.id">
                        </moj-radiobutton>
                    </div>
                    <div class="col-xs-3">
                        <moj-radiobutton [(ngModel)]="idOptions" [radiovalue]="false" name="id"
                            [labelTextKey]="courtOrderTexts.passport">
                        </moj-radiobutton>
                    </div>
                    <div class="col-xs-6" style="padding-left: 0px !important;">
                        <moj-textbox style="padding-left: 0px !important;" [required]="true"
                            [identification]="idOptions" pattern="^\d{1,15}$" [maxlength]="idOptions ? 9 : 15" ngModel
                            name="idNumber"
                            [labelTextKey]="(idOptions && courtOrderTexts.idNumber) || courtOrderTexts.passportNumber"
                            labelWidthColumns="6" [controlWidthColumns]="6">
                        </moj-textbox>
                    </div>
                </moj-line>
                <moj-buttons-line>
                    <moj-submit-button id="searchBakashotBtn" [buttonStyle]="buttonStyle.Primary" [form]="f"
                        [textKey]="courtOrderTexts.search" [isEnable]="f.valid!">
                    </moj-submit-button>
                </moj-buttons-line>
            </div>
        </form>
        <moj-line>
            <div id="myGrid" style="height: 200px">
                <div *ngIf="isAnyDataAvail">
                    <h2 class="moj-primary-color">{{ courtOrderTexts.requestDetails}}</h2>
                    <!-- <moj-dynamic-label
                        textKey="פרטי הבקשה"
                        [fontSize]="fontSize.font16"
                        [color]="mojColor.blue"
                        [fontWeight]="fontWeight.bold">
                    </moj-dynamic-label> -->
                    <!-- <ag-grid-angular
                            class="ag-theme-balham"
                            mojGridOptions
                            [rowData]="bakashot"
                            [columnDefs]="columns"
                            
                            [pagination]="false"
                            [suppressHorizontalScroll]="true"
                            (gridReady)="onGridReady($event)"
                            gridAutoHeight="true">
                        </ag-grid-angular> -->
                    <div class="table table-responsive" class="paddingTop15">
                        <div class="row pirteyBakashaGridHeader pirteyBakashaGrid">
                            <div class="col-xs-2">{{ courtOrderTexts.CaseNumber }}</div>
                            <div class="col-xs-2">{{ courtOrderTexts.district }}</div>
                            <div class="col-xs-2">{{ courtOrderTexts.requestType }}</div>
                            <div class="col-xs-2">{{ courtOrderTexts.deceasedId }}</div>
                            <div class="col-xs-2">{{ courtOrderTexts.deceasedName }}</div>
                            <div class="col-xs-2 DLFalighn" *ngIf="isCourtOrderExists">{{ courtOrderTexts.downloadOrder
                                }}</div>
                        </div>
                        <div class="row pirteyBakashaGrid" *ngFor="let b of bakashot">
                            <div class="col-xs-2">{{b.misparTik}}</div>
                            <div class="col-xs-2">{{b.shemMachoz}}</div>
                            <div class="col-xs-2">{{b.shemSugBakasha}}</div>
                            <div class="col-xs-2">{{b.tzManoach}}</div>
                            <div class="col-xs-2">{{b.shemManoach}}</div>
                            <div class="col-xs-2 fas fa-download DLF DLFalighn" tabindex="0"
                                aria-label="courtOrderTexts.downloadOrder" [title]="courtOrderTexts.downloadOrder"
                                *ngIf="isCourtOrderExists" (keydown.enter)="downloadFile(b)" (click)="downloadFile(b)">
                            </div>
                        </div>
                    </div>
                </div>

                <moj-line *ngIf="textToShow">
                    <moj-dynamic-label [textKey]="textToShow" [color]="mojColor.Default" dir="ltr">
                    </moj-dynamic-label>
                </moj-line>
                <moj-line class="bottom">

                </moj-line>
            </div>
        </moj-line>
    </div>
</div>