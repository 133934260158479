<div>
	<div>
		<div class="paddingTop10 paddingBottom10 font-20 blue3 bold">בן/בת הזוג והקרובים הסמוכים של המוריש/ה (שדות
			המסומנים בכוכבית הנם שדות חובה)</div>
	</div>

	<div>
		<div class="paddingTop10 font-20 blue3 bold">1. בן/בת זוג של המוריש/ה </div>
	</div>

	<div>
		<div class="col-sm-6 font-16 blue3 bold">
			למוריש/ה היה/היתה במותה בן/בת זוג
			<span style="color:#c90101;">*</span>
		</div>
		<div class="col-sm-6 paddingRight0">
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isSpouse" [radiovalue]="true" name="spouse" labelTextKey="כן">
				</moj-radiobutton>
			</div>
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isSpouse" [radiovalue]="false" name="spouse" labelTextKey="לא">
				</moj-radiobutton>
			</div>
		</div>
	</div>

	<div *ngIf="isSpouse">
		<h2 class="font-16 orange2 bold">בעת מותה של המורישה בן/בת הזוג חי/ה ופרטיו/ה:</h2>
	</div>

	<div *ngIf="isSpouse">
		<moj-line>
			<moj-dropdownlist required [ngModel]="idTypeSpouseSelected" labelWidthColumns="1" [controlWidthColumns]="4"
				name="idTypeSpouse" fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="true"
				[items]="idTypes">
			</moj-dropdownlist>
		</moj-line>
	</div>

	<div *ngIf="isSpouse">
		<moj-line>
			<moj-textbox [required]="true" [identification]='idTypeSpouseSelected==1' ngModel name="idNumberSpouse"
				labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
				[maxlength]="9">
			</moj-textbox>
			<moj-textbox [required]="true" ngModel name="firstNameSpouse" [labelTextKey]="'שם פרטי'" [maxlength]="50"
				labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
			</moj-textbox>
			<moj-textbox [required]="true" ngModel name="lastNameSpouse" [labelTextKey]="'שם משפחה'" [maxlength]="50"
				labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
			</moj-textbox>
		</moj-line>
	</div>

	<hr *ngIf="isSpouse" class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

	<div *ngIf="isSpouse">
		<h2 class="font-16 orange2 bold">מענו/ה</h2>
	</div>

	<div *ngIf="isSpouse">
		<moj-line>
			<moj-autocomplete *ngIf="dataStoreService.countries$ | async as countriesItems" isLabelAboveControl="true"
				required name="countrySpouse" [labelTextKey]="'ארץ'" labelWidthColumns="1" [controlWidthColumns]="4"
				[items]="countriesItems" fieldName="value" fieldID="id" [dropdown]="true"
				[(ngModel)]="countrySpouseSelected">
			</moj-autocomplete>
		</moj-line>
	</div>

	<div *ngIf="isSpouse">
		<moj-line>
			<moj-autocomplete isLabelAboveControl="true" required name="citySpouse" [labelTextKey]="'ישוב'"
				labelWidthColumns="1" [controlWidthColumns]="4" [items]="cities" fieldName="value" fieldID="id"
				[dropdown]="true" [(ngModel)]="citySpouseSelected">
			</moj-autocomplete>
			<moj-autocomplete isLabelAboveControl="true" required name="streetSpouse" [labelTextKey]="'רחוב'"
				labelWidthColumns="1" [controlWidthColumns]="4" [items]="streets" fieldName="value" fieldID="id"
				[dropdown]="true" [(ngModel)]="streetSpouseSelected">
			</moj-autocomplete>
			<moj-textbox required ngModel name="houseNumberSpouse" [labelTextKey]="'מספר בית'" [maxlength]="50"
				labelWidthColumns="2" [controlWidthColumns]="2" isLabelAboveControl="true">
			</moj-textbox>
			<moj-textbox ngModel name="mikudSpouse" [labelTextKey]="'מיקוד'" [maxlength]="50" labelWidthColumns="2"
				[controlWidthColumns]="1" isLabelAboveControl="true">
			</moj-textbox>
		</moj-line>
	</div>

	<hr *ngIf="isSpouse" class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

	<div *ngIf="isSpouse">
		<h2 class="font-16 orange2 bold">זכויותיו המיוחדות של בן/בת הזוג בעזבון:</h2>
	</div>

	<div *ngIf="isSpouse">
		<moj-line>
			<div class="col-md-12 blue3 font-16 bold">
				א) מכונית
			</div>
		</moj-line>
	</div>

	<div *ngIf="isSpouse" class="row">
		<div class="col-xs-6 paddingRight0">
			<moj-radiobutton [controlWidthColumns]="9" [(ngModel)]="isCar" [radiovalue]="false" name="car"
				labelTextKey="למורישה לא הייתה מכונית">
			</moj-radiobutton>
		</div>
	</div>

	<div *ngIf="isSpouse" class="row">
		<div class="col-xs-12 paddingRight0">
			<moj-radiobutton isLabelAutowidth="true" [controlWidthColumns]="12" [(ngModel)]="isCar" [radiovalue]="true"
				name="car"
				labelTextKey="למורישה היתה במותה מכונית/יות נוסעים שהייתה שייכת למשק הבית המשותף לה ולבן/בת זוגה, ובן/בת זוגה זכאי/ת לקבלה על פי סעיף 11 (א) לחוק הירושה, התשכ''ה - 1965">
			</moj-radiobutton>
		</div>
	</div>

	<div *ngIf="isCar">
		<moj-line>
			<moj-textbox [required]="true" ngModel name="carModel" labelTextKey="יצרן ודגם" labelWidthColumns="2"
				[controlWidthColumns]="4" isLabelAboveControl="true" [maxlength]="50">
			</moj-textbox>
			<moj-textbox [required]="true" ngModel name="carYear" [labelTextKey]="'שנת יצור'" [maxlength]="4"
				labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
			</moj-textbox>
			<moj-textbox [required]="true" ngModel name="carId" [labelTextKey]="'מספר רישוי'" [maxlength]="10"
				labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
			</moj-textbox>
		</moj-line>
	</div>

	<div *ngIf="isSpouse">
		<moj-line>
			<div class="col-md-12 blue3 font-16 bold">
				ב) הדירה
			</div>
		</moj-line>
	</div>

	<div *ngIf="isSpouse">
		<div class="col-xs-6 blue3 bold">
			האם עזבון המורישה כולל דירה?
			<span style="color:#c90101;">*</span>
		</div>
		<div class="col-xs-6 paddingRight0">
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isApartment" [radiovalue]="true" name="apartment" labelTextKey="כן">
				</moj-radiobutton>
			</div>
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isApartment" [radiovalue]="false" name="apartment" labelTextKey="לא">
				</moj-radiobutton>
			</div>
		</div>
	</div>

	<div *ngIf="isApartment" class="row">
		<div class="col-xs-6 blue3 bold">
			האם המורישה הניחה ילדים או צאצאיהם או הורים?
			<span style="color:#c90101;">*</span>
		</div>
		<div class="col-xs-6 paddingRight0">
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isApartmentHeirs" [radiovalue]="true" name="apartmentHeirs"
					labelTextKey="כן">
				</moj-radiobutton>
			</div>
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isApartmentHeirs" [radiovalue]="false" name="apartmentHeirs"
					labelTextKey="לא">
				</moj-radiobutton>
			</div>
		</div>
	</div>

	<div *ngIf="isApartmentHeirs==false" class="row">
		<div class="col-sm-6 blue3 bold">
			האם למורישה אחים/אחיות או הורי הורים?
			<span style="color:#c90101;">*</span>
		</div>
		<div class="col-xs-6 paddingRight0">
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isApartmentOtherHeirs" [radiovalue]="true" name="apartmentOtherHeirs"
					labelTextKey="כן">
				</moj-radiobutton>
			</div>
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isApartmentOtherHeirs" [radiovalue]="false" name="apartmentOtherHeirs"
					labelTextKey="לא">
				</moj-radiobutton>
			</div>
		</div>
	</div>

	<div *ngIf="isApartmentOtherHeirs" class="row">
		<div class="col-sm-6 blue3 bold">
			האם בן/בת הזוג היה/היתה נשוי/אה למוריש/ה למעלה משלוש שנים?
			<span style="color:#c90101;">*</span>
		</div>
		<div class="col-xs-6 paddingRight0">
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isMarriedMoreThanThreeYears" [radiovalue]="true"
					name="marriedMoreThanThreeYears" labelTextKey="כן">
				</moj-radiobutton>
			</div>
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isMarriedMoreThanThreeYears" [radiovalue]="false"
					name="marriedMoreThanThreeYears" labelTextKey="לא">
				</moj-radiobutton>
			</div>
		</div>
	</div>

	<div *ngIf="isMarriedMoreThanThreeYears" class="row">
		<div class="col-sm-6 blue3 bold">
			האם בת/בן הזוג גר/ה עם המוריש/ה ערב מותו/ה של המוריש/ה בדירה?
			<span style="color:#c90101;">*</span>
		</div>
		<div class="col-xs-6 paddingRight0">
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isSpouseLivedTogether" [radiovalue]="true" name="spouseLivedTogether"
					labelTextKey="כן">
				</moj-radiobutton>
			</div>
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isSpouseLivedTogether" [radiovalue]="false" name="spouseLivedTogether"
					labelTextKey="לא">
				</moj-radiobutton>
			</div>
		</div>
	</div>

	<div *ngIf="isApartmentOtherHeirs">
		<h2 class="font-16 orange2 bold">פרטי הדירה</h2>
	</div>

	<div *ngIf="isApartmentOtherHeirs">
		<moj-line>
			<moj-textbox [required]="true" ngModel name="gush" labelTextKey="גוש" labelWidthColumns="2"
				[controlWidthColumns]="3" isLabelAboveControl="true" [maxlength]="50">
			</moj-textbox>
			<moj-textbox [required]="true" ngModel name="helka" [labelTextKey]="'חלקה'" [maxlength]="4"
				labelWidthColumns="2" [controlWidthColumns]="3" isLabelAboveControl="true">
			</moj-textbox>
			<moj-textbox [required]="true" ngModel name="tatHelka" [labelTextKey]="'תת חלקה'" [maxlength]="10"
				labelWidthColumns="2" [controlWidthColumns]="3" isLabelAboveControl="true">
			</moj-textbox>
		</moj-line>
	</div>

	<div *ngIf="isApartmentOtherHeirs">
		<moj-line>
			<moj-autocomplete isLabelAboveControl="true" required name="cityApartment" [labelTextKey]="'ישוב'"
				labelWidthColumns="1" [controlWidthColumns]="4" [items]="cities" fieldName="value" fieldID="id"
				[dropdown]="true" [(ngModel)]="cityApartmentSelected">
			</moj-autocomplete>
			<moj-autocomplete isLabelAboveControl="true" required name="streetApartment" [labelTextKey]="'רחוב'"
				labelWidthColumns="1" [controlWidthColumns]="4" [items]="streets" fieldName="value" fieldID="id"
				[dropdown]="true" [(ngModel)]="streetApartmentSelected">
			</moj-autocomplete>
			<moj-textbox required ngModel name="houseNumberApartment" [labelTextKey]="'מספר בית'" [maxlength]="50"
				labelWidthColumns="2" [controlWidthColumns]="2" isLabelAboveControl="true">
			</moj-textbox>
			<moj-textbox ngModel name="mikudApartment" [labelTextKey]="'מיקוד'" [maxlength]="50" labelWidthColumns="2"
				[controlWidthColumns]="1" isLabelAboveControl="true">
			</moj-textbox>
		</moj-line>
	</div>

	<hr *ngIf="isSpouse" class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

	<div class="row">
		<div class="paddingTop10 font-20 blue3 bold">2. יורשיו/ה לפי דין של המוריש/ה</div>
	</div>

	<div class="row">
		<div class="col-sm-6 font-16 blue3 bold">
			האם היורשים (למעט בן/בת זוג) הינם ילדי המוריש/ה ו/או צאצאיהם?
			<span style="color:#c90101;">*</span>
		</div>
		<div class="col-sm-6 paddingRight0">
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isOffsprings" [radiovalue]="true" name="offsprings" labelTextKey="כן">
				</moj-radiobutton>
			</div>
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isOffsprings" [radiovalue]="false" name="offsprings" labelTextKey="לא">
				</moj-radiobutton>
			</div>
		</div>
	</div>

	<div *ngIf="isOffsprings">
		<h2 class="font-16 orange2 bold">אלה כל הילדים שנולדו למוריש/ה אי פעם</h2>
	</div>

	<div *ngIf="isOffsprings" class="col-sm-12">
		<moj-line>
			<h3 class="font-14 bold" style="float:right;">שורה 1</h3>
			<h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
					style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
		</moj-line>
	</div>

	<div *ngIf="isOffsprings">
		<moj-line>
			<moj-dropdownlist required [ngModel]="idTypeSpouseSelected" labelWidthColumns="1" [controlWidthColumns]="4"
				name="idTypeSpouse" fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="true"
				[items]="idTypes">
			</moj-dropdownlist>
		</moj-line>
	</div>

	<div *ngIf="isOffsprings">
		<moj-line>
			<moj-textbox [required]="true" [identification]='idTypeChildSelected==1' ngModel name="idNumberChild"
				labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
				[maxlength]="9">
			</moj-textbox>
			<moj-textbox [required]="true" ngModel name="firstNameChild" [labelTextKey]="'שם פרטי'" [maxlength]="50"
				labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
			</moj-textbox>
			<moj-textbox [required]="true" ngModel name="lastNameChild" [labelTextKey]="'שם משפחה'" [maxlength]="50"
				labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
			</moj-textbox>
		</moj-line>
	</div>

	<div *ngIf="isOffsprings">
		<moj-line>
			<moj-textbox [required]="true" ngModel name="otherParentChild" [labelTextKey]="'שם ההורה השני'"
				[maxlength]="50" labelWidthColumns="2" [controlWidthColumns]="6" isLabelAboveControl="true">
			</moj-textbox>
		</moj-line>
	</div>

	<div *ngIf="isOffsprings" class="row paddingTop10">
		<div class="col-sm-6 font-16 blue3 bold">
			האם ילדו/ה של המנוח היה בחיים ביום פטירתו של המנוח?
			<span style="color:#c90101;">*</span>
		</div>
		<div class="col-xs-5 paddingRight0">
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isChildAlive" [radiovalue]="true" name="ChildAlive" labelTextKey="כן">
				</moj-radiobutton>
			</div>
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isChildAlive" [radiovalue]="false" name="ChildAlive" labelTextKey="לא">
				</moj-radiobutton>
			</div>
		</div>
	</div>

	<div *ngIf="isChildAlive" class="row">
		<div class="col-xs-6 font-16 blue3 bold">
			האם ילדו של המנוח הסתלק מחלקו בעיזבון?
			<span style="color:#c90101;">*</span>
		</div>
		<div class="col-xs-5 paddingRight0">
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isChildLeftEstate" [radiovalue]="true" name="childLeftEstate"
					labelTextKey="כן">
				</moj-radiobutton>
			</div>
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isChildLeftEstate" [radiovalue]="false" name="childLeftEstate"
					labelTextKey="לא">
				</moj-radiobutton>
			</div>
		</div>
	</div>

	<div *ngIf="isChildAlive==false">
		<moj-line>
			<div class="col-xs-8">
				<moj-file-upload labelTextKey="יש לצרף אישור פטירה" isLabelAboveControl="true" labelWidthColumns="1"
					[controlWidthColumns]="4" [required]="true" [id]="'fuChild'" [(ngModel)]="filesChild" name="fuChild"
					show [maxFileSize]="23068672" [enabledFileTypes]="'pdf'" designType="fuDesignType.Single">
				</moj-file-upload>
			</div>
		</moj-line>
	</div>

	<div *ngIf="isChildAlive==false" class="row">
		<div class="col-xs-6 font-16 blue3 bold paddingTop10">
			האם נולדו לילד שנפטר ילדים אי פעם ?
			<span style="color:#c90101;">*</span>
		</div>
		<div class="col-xs-6 paddingRight0">
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isDeceasedChildHadChildren" [radiovalue]="true"
					name="deceasedChildHadChildren" labelTextKey="כן">
				</moj-radiobutton>
			</div>
			<div class="col-xs-4 paddingX0">
				<moj-radiobutton [(ngModel)]="isDeceasedChildHadChildren" [radiovalue]="false"
					name="deceasedChildHadChildren" labelTextKey="לא">
				</moj-radiobutton>
			</div>
		</div>
	</div>

	<div *ngIf="isDeceasedChildHadChildren" class="row paddingTop10">
		<h2 class="col-xs-12 font-16 orange2 bold">אלה הילדים שנולדו לבן/בת שנפטר/ה</h2>
	</div>

	<div *ngIf="isDeceasedChildHadChildren" class="col-sm-12">
		<moj-line>
			<h3 class="font-14 bold" style="float:right;">שורה 1</h3>
			<h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
					style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
		</moj-line>
	</div>

	<div *ngIf="isDeceasedChildHadChildren">
		<moj-line>
			<moj-dropdownlist required [ngModel]="idTypeChildChildSelected" labelWidthColumns="1"
				[controlWidthColumns]="4" name="idTypeChildChild" fieldID="id" labelTextKey="סוג תעודה מזהה"
				isLabelAboveControl="true" [items]="idTypes">
			</moj-dropdownlist>
		</moj-line>
	</div>

	<div *ngIf="isDeceasedChildHadChildren">
		<moj-line>
			<moj-textbox [required]="true" [identification]='idTypeChildChildSelected==1' ngModel
				name="idNumberChildChild" labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4"
				isLabelAboveControl="true" [maxlength]="9">
			</moj-textbox>
			<moj-textbox [required]="true" ngModel name="firstNameChildChild" [labelTextKey]="'שם פרטי'"
				[maxlength]="50" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
			</moj-textbox>
			<moj-textbox [required]="true" ngModel name="lastNameChildChild" [labelTextKey]="'שם משפחה'"
				[maxlength]="50" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
			</moj-textbox>
		</moj-line>
	</div>

	<div *ngIf="isDeceasedChildHadChildren">
		<moj-line>
			<moj-textbox [required]="true" ngModel name="otherParentChildChild" [labelTextKey]="'שם ההורה השני'"
				[maxlength]="50" labelWidthColumns="2" [controlWidthColumns]="6" isLabelAboveControl="true">
			</moj-textbox>
		</moj-line>
	</div>

	<div *ngIf="isDeceasedChildHadChildren">
		<moj-line>
			<div style="padding-left:30px;">
				<button style="background-color:#B7D3E4;padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
			</div>
		</moj-line>
	</div>

	<div *ngIf="isOffsprings">
		<moj-line>
			<div style="padding-left:30px;">
				<button style="background-color:#B7D3E4;padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
			</div>
		</moj-line>
	</div>

	<hr *ngIf="isOffsprings==false" class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

	<div *ngIf="isOffsprings==false">
		<h2 class="font-16 orange2 bold">אב המוריש/ה</h2>
	</div>

	<div *ngIf="isOffsprings==false" class="row">
		<div class="col-xs-6 paddingRight0">
			<moj-radiobutton [controlWidthColumns]=9 [(ngModel)]="isFatherAlive" [radiovalue]="true" name="fatherAlive"
				labelTextKey="בעת מותה של המורישה היה אביה בין החיים">
			</moj-radiobutton>
		</div>
	</div>

	<div *ngIf="isOffsprings==false" class="row">
		<div class="col-xs-6 paddingRight0">
			<moj-radiobutton [controlWidthColumns]=7 [(ngModel)]="isFatherAlive" [radiovalue]="false" name="fatherAlive"
				labelTextKey="אביו/ה של המוריש/ה נפטר לפניו/ה">
			</moj-radiobutton>
		</div>
	</div>

	<div *ngIf="isFatherAlive">
		<moj-line>
			<moj-dropdownlist required [ngModel]="idTypeFatherSelected" labelWidthColumns="1" [controlWidthColumns]="4"
				name="idTypeFather" fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="true"
				[items]="idTypes">
			</moj-dropdownlist>
		</moj-line>
	</div>

	<div *ngIf="isFatherAlive">
		<moj-line>
			<moj-textbox [required]="true" [identification]='idTypeFatherSelected==1' ngModel name="idNumberFather"
				labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
				[maxlength]="9">
			</moj-textbox>
			<moj-textbox [required]="true" ngModel name="firstNameFather" [labelTextKey]="'שם פרטי'" [maxlength]="50"
				labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
			</moj-textbox>
			<moj-textbox [required]="true" ngModel name="lastNameFather" [labelTextKey]="'שם משפחה'" [maxlength]="50"
				labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
			</moj-textbox>
		</moj-line>
	</div>

	<div *ngIf="isFatherAlive" class="paddingTop10 paddingRight15">
		<h2 class="font-16 blue3 bold">מענו</h2>
	</div>

	<div *ngIf="isFatherAlive">
		<moj-line>
			<moj-autocomplete *ngIf="dataStoreService.countries$ | async as countriesItems" isLabelAboveControl="true"
				required name="countryFather" [labelTextKey]="'ארץ'" labelWidthColumns="1" [controlWidthColumns]="4"
				[items]="countriesItems" fieldName="value" fieldID="id" [dropdown]="true"
				[(ngModel)]="countryFatherSelected">
			</moj-autocomplete>
		</moj-line>
	</div>

	<div *ngIf="isFatherAlive">
		<moj-line>
			<moj-autocomplete isLabelAboveControl="true" required name="cityFather" [labelTextKey]="'ישוב'"
				labelWidthColumns="1" [controlWidthColumns]="4" [items]="cities" fieldName="value" fieldID="id"
				[dropdown]="true" [(ngModel)]="cityFatherSelected">
			</moj-autocomplete>
			<moj-autocomplete isLabelAboveControl="true" required name="streetFather" [labelTextKey]="'רחוב'"
				labelWidthColumns="1" [controlWidthColumns]="4" [items]="streets" fieldName="value" fieldID="id"
				[dropdown]="true" [(ngModel)]="streetFatherSelected">
			</moj-autocomplete>
			<moj-textbox required ngModel name="houseNumberFather" [labelTextKey]="'מספר בית'" [maxlength]="50"
				labelWidthColumns="2" [controlWidthColumns]="2" isLabelAboveControl="true">
			</moj-textbox>
			<moj-textbox ngModel name="mikudFather" [labelTextKey]="'מיקוד'" [maxlength]="50" labelWidthColumns="2"
				[controlWidthColumns]="1" isLabelAboveControl="true">
			</moj-textbox>
		</moj-line>
	</div>

	<div *ngIf="isFatherAlive==false">
		<div>
			<h3 class="font-14">נפטר ביום</h3>
		</div>
	</div>

	<div *ngIf="isFatherAlive==false">
		<moj-line>
			<moj-datepicker isLabelAboveControl="true" [ngModel]="dateOfDeathFather" name="dateOfDeathFather"
				labelTextKey="תאריך פטירה לועזי" labelWidthColumns="1" [controlWidthColumns]="2">
			</moj-datepicker>

			<div class="col-xs-10">
				<span style="padding-right: 15px;">תאריך פטירה עברי</span>
				<span style="color:#c90101;">*</span><br>
				<moj-line>
					<moj-dropdownlist placeholder="שנה" required [ngModel]="hebrewYearFatherDeathSelected"
						labelWidthColumns="0" [controlWidthColumns]="2" name="hebrewYearFatherDeath" fieldID="id"
						isLabelAboveControl="true" [items]="hebrewYears">
					</moj-dropdownlist>
					<moj-dropdownlist placeholder="חודש" required [ngModel]="hebrewMonthFatherDeathSelected"
						labelWidthColumns="0" [controlWidthColumns]="2" name="hebrewMonthFatherDeath" fieldID="id"
						isLabelAboveControl="true" [items]="hebrewMonths">
					</moj-dropdownlist>
					<moj-dropdownlist placeholder="יום" required [ngModel]="hebrewDayFatherDeathSelected"
						labelWidthColumns="0" [controlWidthColumns]="2" name="hebrewDayFatherDeath" fieldID="id"
						isLabelAboveControl="true" [items]="hebrewDays">
					</moj-dropdownlist>
				</moj-line>
			</div>
		</moj-line>
	</div>

	<div *ngIf="isFatherAlive==false">
		<moj-line>
			<div class="col-xs-8">
				<moj-file-upload labelTextKey="כמוכח ב (יש לצרף הוכחת פטירה):" isLabelAboveControl="true"
					labelWidthColumns="1" [controlWidthColumns]="6" [required]="true" [id]="'fuFatherProofOfDeath'"
					[(ngModel)]="filesFatherProofOfDeath" name="fuFatherProofOfDeath" show [maxFileSize]="23068672"
					[enabledFileTypes]="'pdf'" designType="fuDesignType.Single">
				</moj-file-upload>
			</div>
		</moj-line>
	</div>
















































	<hr *ngIf="isOffsprings==false" class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

	<div *ngIf="isOffsprings==false">
		<h2 class="font-16 orange2 bold">אם המוריש/ה</h2>
	</div>

	<div *ngIf="isOffsprings==false" class="row">
		<div class="col-xs-6 paddingRight0">
			<moj-radiobutton [controlWidthColumns]=9 [(ngModel)]="isMotherAlive" [radiovalue]="true" name="motherAlive"
				labelTextKey="בעת מות/ה של המוריש/ה היתה אמו/ה בין החיים">
			</moj-radiobutton>
		</div>
	</div>

	<div *ngIf="isOffsprings==false" class="row">
		<div class="col-xs-6 paddingRight0">
			<moj-radiobutton [controlWidthColumns]=7 [(ngModel)]="isMotherAlive" [radiovalue]="false" name="motherAlive"
				labelTextKey="אמו/ה של המוריש/ה נפטר לפניו/ה">
			</moj-radiobutton>
		</div>
	</div>

	<div *ngIf="isMotherAlive">
		<moj-line>
			<moj-dropdownlist required [ngModel]="idTypeMotherSelected" labelWidthColumns="1" [controlWidthColumns]="4"
				name="idTypeMother" fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="true"
				[items]="idTypes">
			</moj-dropdownlist>
		</moj-line>
	</div>

	<div *ngIf="isMotherAlive">
		<moj-line>
			<moj-textbox [required]="true" [identification]='idTypeMotherSelected==1' ngModel name="idNumberMother"
				labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
				[maxlength]="9">
			</moj-textbox>
			<moj-textbox [required]="true" ngModel name="firstNameMother" [labelTextKey]="'שם פרטי'" [maxlength]="50"
				labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
			</moj-textbox>
			<moj-textbox [required]="true" ngModel name="lastNameMother" [labelTextKey]="'שם משפחה'" [maxlength]="50"
				labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
			</moj-textbox>
		</moj-line>
	</div>

	<div *ngIf="isMotherAlive" class="paddingTop10 paddingRight15">
		<h2 class="font-16 blue3 bold">מענה</h2>
	</div>

	<div *ngIf="isMotherAlive">
		<moj-line>
			<moj-autocomplete *ngIf="dataStoreService.countries$ | async as countriesItems" isLabelAboveControl="true"
				required name="countryMother" [labelTextKey]="'ארץ'" labelWidthColumns="1" [controlWidthColumns]="4"
				[items]="countriesItems" fieldName="value" fieldID="id" [dropdown]="true"
				[(ngModel)]="countryMotherSelected">
			</moj-autocomplete>
		</moj-line>
	</div>

	<div *ngIf="isMotherAlive">
		<moj-line>
			<moj-autocomplete isLabelAboveControl="true" required name="cityMother" [labelTextKey]="'ישוב'"
				labelWidthColumns="1" [controlWidthColumns]="4" [items]="cities" fieldName="value" fieldID="id"
				[dropdown]="true" [(ngModel)]="cityMotherSelected">
			</moj-autocomplete>
			<moj-autocomplete isLabelAboveControl="true" required name="streetMother" [labelTextKey]="'רחוב'"
				labelWidthColumns="1" [controlWidthColumns]="4" [items]="streets" fieldName="value" fieldID="id"
				[dropdown]="true" [(ngModel)]="streetMotherSelected">
			</moj-autocomplete>
			<moj-textbox required ngModel name="houseNumberMother" [labelTextKey]="'מספר בית'" [maxlength]="50"
				labelWidthColumns="2" [controlWidthColumns]="2" isLabelAboveControl="true">
			</moj-textbox>
			<moj-textbox ngModel name="mikudMother" [labelTextKey]="'מיקוד'" [maxlength]="50" labelWidthColumns="2"
				[controlWidthColumns]="1" isLabelAboveControl="true">
			</moj-textbox>
		</moj-line>
	</div>

	<div *ngIf="isMotherAlive==false">
		<div>
			<h3 class="font-14">נפטר ביום</h3>
		</div>
	</div>

	<div *ngIf="isMotherAlive==false">
		<moj-line>
			<moj-datepicker isLabelAboveControl="true" [ngModel]="dateOfDeathMother" name="dateOfDeathMother"
				labelTextKey="תאריך פטירה לועזי" labelWidthColumns="1" [controlWidthColumns]="2">
			</moj-datepicker>

			<div class="col-xs-10">
				<span style="padding-right: 15px;">תאריך פטירה עברי</span>
				<span style="color:#c90101;">*</span><br>
				<moj-line>
					<moj-dropdownlist placeholder="שנה" required [ngModel]="hebrewYearMotherDeathSelected"
						labelWidthColumns="0" [controlWidthColumns]="2" name="hebrewYearMotherDeath" fieldID="id"
						isLabelAboveControl="true" [items]="hebrewYears">
					</moj-dropdownlist>
					<moj-dropdownlist placeholder="חודש" required [ngModel]="hebrewMonthMotherDeathSelected"
						labelWidthColumns="0" [controlWidthColumns]="2" name="hebrewMonthMotherDeath" fieldID="id"
						isLabelAboveControl="true" [items]="hebrewMonths">
					</moj-dropdownlist>
					<moj-dropdownlist placeholder="יום" required [ngModel]="hebrewDayMotherDeathSelected"
						labelWidthColumns="0" [controlWidthColumns]="2" name="hebrewDayMotherDeath" fieldID="id"
						isLabelAboveControl="true" [items]="hebrewDays">
					</moj-dropdownlist>
				</moj-line>
			</div>
		</moj-line>
	</div>

	<div *ngIf="isMotherAlive==false">
		<moj-line>
			<div class="col-xs-8">
				<moj-file-upload labelTextKey="כמוכח ב (יש לצרף הוכחת פטירה):" isLabelAboveControl="true"
					labelWidthColumns="1" [controlWidthColumns]="6" [required]="true" [id]="'fuMotherProofOfDeath'"
					[(ngModel)]="filesMotherProofOfDeath" name="fuMotherProofOfDeath" show [maxFileSize]="23068672"
					[enabledFileTypes]="'pdf'" designType="fuDesignType.Single">
				</moj-file-upload>
			</div>
		</moj-line>
	</div>

</div>