import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { FontSize, FontWeight, GridService, MojColor } from "@moj/moj-ng";
import { ColDef, GridApi, GridOptions } from "ag-grid-community";
import { BehaviorSubject } from "rxjs";

import { TranslateService } from "@ngx-translate/core";
import { Bakasha } from "../../models/bakasha.model";
import { DataStoreService } from "../../services/data-store.service";

@Component({
  selector: "bakashot-output",
  templateUrl: "./bakashot-output.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BakashotOutputComponent {
  private _data = new BehaviorSubject<Bakasha[]>([]);

  @Input()
  set showLoading(value: any) {
    if (this.gridApi) {
      value ? this.gridApi.showLoadingOverlay() : this.gridApi.hideOverlay();
    }
  }

  @Input()
  set data(value: Bakasha[]) {
    this._data.next(value);
  }
  get data() {
    return this._data.getValue();
  }

  private gridApi!: GridApi;
  rowData!: Bakasha[];
  columns: ColDef[];
  fontSize = FontSize;
  fontWeight = FontWeight;
  mojColor = MojColor;
  isToManyRecords = false;
  gridOptions: GridOptions;

  requestTexts;

  constructor(
    private gridService: GridService,
    // private extendGridService: ExtendGridService,
    private router: Router,
    private translateService: TranslateService,
    private dataStore: DataStoreService,
  ) {
    this.gridOptions = this.gridService.getMojGridOptions();
    this.gridOptions.domLayout = "normal";

    this.requestTexts = this.translateService.instant("Texts.requestTexts");

    this.columns = [
      this.gridService.getMojDateColumn("taarichHagashatBakasha", {
        colDef: { headerName: "requestTexts.requestDate" },
      }),
      this.gridService.getLinkColumn("misparTik", {
        colDef: { headerName: "requestTexts.caseNumber" },
        clickLink: this.navigateToSpecificRequest.bind(this),
        title: "requestTexts.caseNumber",
      }),
      this.gridService.getMojColumn("misparBakasha", {
        colDef: { headerName: "requestTexts.requestNumber" },
      }),
      this.gridService.getMojColumn("shemSugBakasha", {
        colDef: { headerName: "requestTexts.requestType" },
      }),
      this.gridService.getMojColumn("tzManoach", {
        colDef: { headerName: "requestTexts.deceasedId" },
      }),
      this.gridService.getMojColumn("shemManoach", {
        colDef: { headerName: "requestTexts.deceasedName" },
      }),
      this.gridService.getMojColumn("shemMachoz", {
        colDef: { headerName: "requestTexts.district" },
      }),

      this.gridService.getMojIconColumn("misparTik", {
        icon: {
          iconClass: "far fa-eye",
          clickLink: this.navigateToSpecificRequest.bind(this),
          field: "kod",
          tooltip: "requestTexts.viewDetails",
        },
        colDef: {
          width: 105,
          maxWidth: 105,
          headerName: "requestTexts.details"
        },
      }),

      // this.extendGridService.getMojIconColumn("misparTik", {
        // icon: {
        //   iconClass: "far fa-eye",
        //   clickLink: this.navigateToSpecificRequest.bind(this),
        //   field: "kod",
        //   tooltip: "requestTexts.viewDetails",
        // },
        // colDef: {
        //   width: 105,
        //   maxWidth: 105,
        //   headerName: "requestTexts.details"
        // },
      // }),
    ];
  }

  navigateToSpecificRequest(bakasha: Bakasha) {
    this.dataStore.setBakashaParams$({
      kodBakasha: bakasha.kod,
      kodMachoz: bakasha.kodMachoz,
      misparTik: bakasha.misparTik
    })
    this.router.navigateByUrl("bakasha");
  }

  ngOnInit() {
    this._data.subscribe((x) => {
      if (!x) return;

      this.rowData = x;

      this.isToManyRecords = this.data && this.data.length >= 100;
      document.getElementById("bakashotGridDiv")?.addEventListener("keypress", function (e) {
        if (e.keyCode == 13) {
          var cell = document.activeElement;
          if (cell?.getAttribute("col-id") == "kod") {
            let elem: HTMLElement = cell?.firstChild?.firstChild?.nextSibling as HTMLElement;
            elem.click();
          }
          if (cell?.getAttribute("col-id") == "misparTik") {
            let elem: HTMLElement = cell?.firstChild?.firstChild as HTMLElement;
            elem.click();
          }
        }
      });
      var GO = this.gridOptions;

      var myInput = document.getElementById("searchBakashotBtn");

      myInput?.addEventListener("keydown", function (event) {
        // code for tab key
        var tabKeyCode = 9;

        // ignore non tab key strokes
        if (event.keyCode === tabKeyCode && !event.shiftKey) {
          // prevents tabbing into the url section
          event.preventDefault();

          // scrolls to the first row
          GO?.api?.ensureIndexVisible(0);

          // scrolls to the first column
          var firstCol = GO?.columnApi?.getAllDisplayedColumns()[0];
          GO?.api?.ensureColumnVisible(firstCol!);

          // sets focus into the first grid cell
          GO?.api?.setFocusedCell(0, firstCol!);
        }
      },
        true
      );
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }
}

