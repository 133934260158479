<div>

  <div>
    <div>
      <div class="paddingTop10 paddingBottom10 font-20 blue3 bold">היורשים וחלקיהם בעזבון (שדות המסומנים בכוכבית הנם
        שדות חובה)</div>
    </div>

    <div>
      <div class="paddingTop10 font-18 blue3 bold">היורשים וחלקיהם בעזבון</div>
    </div>

    <div>
      <h2 class="font-16 orange2 bold">לאור האמור בחלקים ב' וג' לעיל, יורשיו של המנוח על פי דין
        וחלקיהם בעזבון הם כדלקמן:</h2>
    </div>

    <section name="heirsInfo">
      <div>
        <moj-line>
          <h3 class="font-14 bold" style="float:right;">שורה 1</h3>
          <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
              style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
        </moj-line>
      </div>
      <div>
        <moj-line>
          <moj-dropdownlist required [ngModel]="idTypeHeirsSelected" labelWidthColumns="1" [controlWidthColumns]="4"
            name="idTypeHeirs" fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="true" [items]="idTypes">
          </moj-dropdownlist>
        </moj-line>
      </div>

      <div>
        <moj-line>
          <moj-textbox [required]="true" [identification]='idTypeHeirsSelected==1' ngModel name="idNumberHeirs"
            labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
            [maxlength]="9">
          </moj-textbox>
          <moj-textbox [required]="true" ngModel name="firstNameHeirs" [labelTextKey]="'שם פרטי'" [maxlength]="50"
            labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
          </moj-textbox>
          <moj-textbox [required]="true" ngModel name="lastNameHeirs" [labelTextKey]="'שם משפחה'" [maxlength]="50"
            labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
          </moj-textbox>
        </moj-line>
      </div>

      <div class="paddingTop10 paddingRight10">
        <h2 class="font-16 orange2 bold">מקום מושבו ומענו</h2>
      </div>


      <div>
        <moj-line>
          <moj-autocomplete *ngIf="dataStoreService.countries$ | async as countriesItems" isLabelAboveControl="true"
            required name="countryHeirs" [labelTextKey]="'ארץ'" labelWidthColumns="1" [controlWidthColumns]="4"
            [items]="countriesItems" fieldName="value" fieldID="id" [dropdown]="true"
            [(ngModel)]="countryHeirsSelected">
          </moj-autocomplete>
        </moj-line>
      </div>

      <div>
        <moj-line>
          <moj-autocomplete isLabelAboveControl="true" required name="cityHeirs" [labelTextKey]="'ישוב'"
            labelWidthColumns="1" [controlWidthColumns]="4" [items]="cities" fieldName="value" fieldID="id"
            [dropdown]="true" [(ngModel)]="cityHeirsSelected">
          </moj-autocomplete>
          <moj-autocomplete isLabelAboveControl="true" required name="streetHeirs" [labelTextKey]="'רחוב'"
            labelWidthColumns="1" [controlWidthColumns]="4" [items]="streets" fieldName="value" fieldID="id"
            [dropdown]="true" [(ngModel)]="streetHeirsSelected">
          </moj-autocomplete>
          <moj-textbox required ngModel name="houseNumberHeirs" [labelTextKey]="'מספר בית'" [maxlength]="50"
            labelWidthColumns="2" [controlWidthColumns]="2" isLabelAboveControl="true">
          </moj-textbox>
          <moj-textbox ngModel name="mikudHeirs" [labelTextKey]="'מיקוד'" [maxlength]="50" labelWidthColumns="2"
            [controlWidthColumns]="2" isLabelAboveControl="true">
          </moj-textbox>
        </moj-line>
      </div>
      <div>
        <moj-line>
          <moj-dropdownlist required [(ngModel)]="relatedSelected" labelWidthColumns="1" [controlWidthColumns]="4"
            name="relatedType" fieldID="id" labelTextKey="קרבתו למנוח" isLabelAboveControl="false" [items]="related">
          </moj-dropdownlist>
        </moj-line>
      </div>
      <div>
        <moj-line>
          <moj-dropdownlist required [(ngModel)]="legalCourtSelected" labelWidthColumns="1" [controlWidthColumns]="4"
            name="legalCourtType" fieldID="id" labelTextKey="כשרותו המשפטית" isLabelAboveControl="false"
            [items]="legalCourt">
          </moj-dropdownlist>
          <div *ngIf="legalCourtSelected==4">
            <moj-file-upload labelTextKey="צו מינוי אפוטרופוס" labelWidthColumns="1" [controlWidthColumns]="4"
              isLabelAboveControl="true" [required]="true" [id]="'filesApotroposTZAV'" [(ngModel)]="filesApotroposTZAV"
              name="filesApotroposTZAV" show [maxFileSize]="23068672" [enabledFileTypes]="'pdf'"
              designType="filesDesignType.Single">
            </moj-file-upload>
          </div>
        </moj-line>
      </div>


      <div>
        <moj-line>
          <div class="col-xs-6">
            <div class="col-xs-12">
              <span class="paddingRight15">חלקו בעיזבון</span>
              <span style="color:#c90101;">*</span>
            </div>
            <div class="col-xs-12 paddingTop5">
              <moj-textbox ngModel isLabelAutowidth="true" name="partNumber" labelTextKey=" " [controlWidthColumns]="3"
                isLabelAboveControl="false" [maxlength]="3">
              </moj-textbox>
              <div style="width:auto" class="col-xs-1 paddingX0 font-18">/</div>
              <moj-textbox ngModel isLabelAutowidth="true" name="partNumber2" labelTextKey=" " [controlWidthColumns]="3"
                isLabelAboveControl="false" [maxlength]="3">
              </moj-textbox>
            </div>
          </div>
        </moj-line>
      </div>

      <div>
        <moj-line>
          <div style="padding-left:30px;">
            <button style="background-color:#B7D3E4;padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
          </div>
        </moj-line>
      </div>
    </section>

    <section name="heirsList">
      <div>
        <moj-line>
          <h2 class="font-16 orange2 bold">
            להלן רשימת היורשים
          </h2>
        </moj-line>
      </div>

      <moj-grid-panel>
        <ag-grid-angular class="ag-theme-balham paddingTop5" [gridOptions]="gridOptions" [rowData]="herisData"
          [columnDefs]="herisColumns" [suppressHorizontalScroll]="true" [infiniteInitialRowCount]="9"
          [pagination]="false" (gridReady)="onHerisGridReady($event)">
        </ag-grid-angular>
      </moj-grid-panel>
    </section>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <section name="message">
      <div>
        <moj-line>
          <h2 class="font-16 orange2 bold">הודעות ליורשים</h2>
        </moj-line>
      </div>

      <div>
        <div class="col-xs-4 line-flex-right paddingRight0 blue3 font-16 bold">
          המבקשת היא היורשת היחידה
          <span style="color:#c90101;">*</span>
        </div>
        <div class="col-xs-5 paddingRight0">
          <div class="col-xs-4 paddingX0">
            <moj-radiobutton [(ngModel)]="isSingelHeirs" [radiovalue]="true" name="singelHeirs" labelTextKey="כן">
            </moj-radiobutton>
          </div>
          <div class="col-xs-4 paddingX0">
            <moj-radiobutton [(ngModel)]="isSingelHeirs" [radiovalue]="false" name="singelHeirs" labelTextKey="לא">
            </moj-radiobutton>
          </div>
        </div>
      </div>
      <div *ngIf="!isSingelHeirs">
        <div class="row">
          <div class="col-xs-4 line-flex-right paddingRight0 blue3 font-16 bold">
            האם היורשים יודעים על הגשת בקשה זו?
            <span style="color:#c90101;">*</span>
          </div>
          <div class="col-xs-5 paddingRight0">
            <div class="col-xs-4 paddingX0">
              <moj-radiobutton [(ngModel)]="isHeirsKnow" [radiovalue]="true" name="heirsKnow" labelTextKey="כן">
              </moj-radiobutton>
            </div>
            <div class="col-xs-4 paddingX0">
              <moj-radiobutton [(ngModel)]="isHeirsKnow" [radiovalue]="false" name="heirsKnow" labelTextKey="לא">
              </moj-radiobutton>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isHeirsKnow">
        <div>
          <moj-line>
            <moj-radiobutton [(ngModel)]="isAllHeirsKnow" [radiovalue]="true" name="allHeirsKnow"
              labelTextKey="המבקשת הודיעה אישית לכל היורשים ומצהירה כי הם מודעים לבקשה">
            </moj-radiobutton>
          </moj-line>
          <moj-line>
            <moj-radiobutton [(ngModel)]="isAllHeirsKnow" [radiovalue]="false" name="allHeirsKnow"
              labelTextKey="אסמכתא על משלוח דואר רשום או אישור היורשים כי קיבלו הודעה על פי בקשה זו.">
            </moj-radiobutton>
          </moj-line>
        </div>
        <div *ngIf="!isAllHeirsKnow">
          <div>
            <moj-line>
              <h3 class="font-14 bold" style="float:right;">שורה 1</h3>
              <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
                  style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
            </moj-line>
          </div>
          <div>
            <moj-line>
              <moj-file-upload labelWidthColumns="0" [controlWidthColumns]="5" isLabelAboveControl="false"
                [required]="true" [id]="'filesHeirsMessage'" [(ngModel)]="filesHeirsMessage" name="filesHeirsMessage"
                show [maxFileSize]="23068672" [enabledFileTypes]="'pdf'" designType="filesDesignType.Single">
              </moj-file-upload>
            </moj-line>
          </div>
        </div>
        <div>
          <moj-line>
            <div style="padding-left:30px;">
              <button style="background-color:#B7D3E4;padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
            </div>
          </moj-line>
        </div>
      </div>

      <div *ngIf="!isHeirsKnow">
        <div>
          <moj-line>
            <moj-textarea [required]="true" ngModel name="messageToHeirs" isLabelAboveControl="true"
              [labelTextKey]="' נא לשלוח הודעה ליורשים עפי תקנה 14. במידה ולא ניתן לשלוח הודעה נמק.'"
              labelWidthColumns="1" [rows]="2" [controlWidthColumns]="11">
            </moj-textarea>
          </moj-line>
        </div>
      </div>
    </section>
  </div>