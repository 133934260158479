<div>
    <div>
        <div class="paddingTop10 font-20 blue3 bold">הנחיות למילוי ופרטי הבקשה (שדות המסומנים בכוכבית הנם שדות חובה)
        </div>
    </div>

    <div class="paddingRight20 paddingTop5">
        <div>
            <h1 class="font-16 bold">לתשומת לב המגישים באופן מקוון: </h1>
        </div>
        <div>
            תשלום האגרה בהגשה מקוונת הופחת ב15% לעומת הגשה ידנית.
        </div>
        <div>
            תשלום האגרה יתבצע במהלך מילוי הטופס המקוון.
        </div>
    </div>

    <hr class="col-md-12 marginBottom10 marginTop10" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
        <div>
            לידיעתכם: בסרגל הכלים קיימים לחצנים לצורך שמירה של הנתונים. בכל אחד משלבי מילוי הטופס, ניתן ורצוי לבצע שמירה
            של הטופס.
        </div>
        <div>
            למעבר לדף הנחיות מפורט
            <a style="color: #285C7E; font-weight: 700;" href="">לחץ כאן</a>
        </div>
    </div>

    <hr class="col-md-12 marginTop10" style="border-top: dotted 1px;color: #488ABC;" />

    <div class="row paddingTop5">
        <div class="col-xs-6 paddingRight0 font-16 blue3 bold">
            האם יש בכוונתך להגיש בקשה נלוות כגון: בקשה למנוי מנהל עיזבון, או תיקון פרטים?
            <span style="color:#c90101">*</span>
        </div>
        <div class="col-xs-5 paddingRight0">
            <div class="col-xs-4">
                <moj-radiobutton [(ngModel)]="isBakashaNilvet" [radiovalue]="true" name="bakashaNilvet"
                    labelTextKey="כן">
                </moj-radiobutton>
            </div>
            <div class="col-xs-4">
                <moj-radiobutton [(ngModel)]="isBakashaNilvet" [radiovalue]="false" name="bakashaNilvet"
                    labelTextKey="לא">
                </moj-radiobutton>
            </div>
        </div>
    </div>

    <div class="row paddingTop10">
        <div class="col-xs-6 paddingRight0 font-16 blue3 bold">
            בידי הצוואה המקורית
            <span style="color:#c90101;">*</span>
        </div>
        <div class="col-xs-5 paddingRight0">
            <div class="col-xs-4">
                <moj-radiobutton [(ngModel)]="isOriginalWill" [radiovalue]="true" name="originalWill" labelTextKey="כן">
                </moj-radiobutton>
            </div>
            <div class="col-xs-4">
                <moj-radiobutton [(ngModel)]="isOriginalWill" [radiovalue]="false" name="originalWill"
                    labelTextKey="לא">
                </moj-radiobutton>
            </div>
        </div>
    </div>

    <div *ngIf="!isOriginalWill">
        <span style="color:#c90101;">*</span>
        <moj-line>
            <moj-radiobutton [(ngModel)]="isApproval" [radiovalue]="true" [name]="'willForm'"
                labelTextKey="הצוואה מופקדת אצל הרשם לענייני ירושה / בידי אישור בימ''ש להגשת צוואה שלא במקור">
            </moj-radiobutton>
        </moj-line>
        <moj-line>
            <moj-radiobutton [(ngModel)]="isApproval" [radiovalue]="false" [name]="'willForm'"
                labelTextKey="אין בידי צוואה מקורית">
            </moj-radiobutton>
        </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div class="paddingTop10">
        <div>
            <h2 class="font-16 orange2 bold">פרטי הבקשה</h2>
        </div>
    </div>

    <div class="row line-flex-right paddingTop10">
        <moj-line>
            <moj-dropdownlist required [ngModel]="idType" labelWidthColumns="1" [controlWidthColumns]="4" name="idType"
                fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="true" [items]="idTypesList">
            </moj-dropdownlist>
        </moj-line>
    </div>

    <div class="paddingTop10">
        <moj-line>
            <moj-textbox [required]="true" [identification]='idType==1' ngModel name="idNumber"
                labelTextKey="מספר זיהוי מוריש/ה" labelWidthColumns="2" [controlWidthColumns]="4"
                isLabelAboveControl="true" [maxlength]="9">
            </moj-textbox>
            <moj-textbox [required]="true" ngModel name="firstName" [labelTextKey]="'שם פרטי מוריש/ה'" [maxlength]="50"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
            </moj-textbox>
            <moj-textbox [required]="true" ngModel name="lastName" [labelTextKey]="'שם משפחה מוריש/ה'" [maxlength]="50"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
            </moj-textbox>
        </moj-line>
    </div>

    <div class="paddingTop10">
        <moj-line>
            <moj-dropdownlist required [ngModel]="machoz" isLabelAboveControl="true" labelWidthColumns="1"
                [controlWidthColumns]="4" name="machoz" fieldID="id" labelTextKey="מוגש בפני הרשם לענייני ירושה ב-"
                *ngIf="dataStoreService.machozot$ | async as machozotItems" [items]="machozotItems">
            </moj-dropdownlist>
        </moj-line>
    </div>

</div>