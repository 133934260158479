<div mojDir moj-dynamic-fontsize class="ws-wrapper">
  <header>
    <moj-accessible-menu></moj-accessible-menu>
    <moj-website-header mainSiteName="הרשם לענייני הירושה" engSiteName="משרד המשפטים" [breadcrumbsMinLength]="100">
      <moj-website-topmenu>
        <app-menu></app-menu>
      </moj-website-topmenu>
      <moj-website-support-container [phone]="texts.phoneNumber" [email]="texts.mailAddress" [showChatbotLink]="true">
      </moj-website-support-container>
    </moj-website-header>
  </header>
  <main>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </main>
  <footer role="contentinfo">
    <moj-website-footer [versionNumber]="version"></moj-website-footer>
  </footer>
  <moj-chatbot [header]="texts.contactUsSubTitle" [infoTitle]="texts.chatTitle"> </moj-chatbot>
</div>
<float-feedback [applicationId]="applicationId" class="feedback-float"></float-feedback>