import { Component, OnInit } from '@angular/core';
import { WizardItem } from '@moj/moj-ng';
import { YerushaTab1Component } from './wizard-tabs/yerusha-tab1/yerusha-tab1.component';
import { YerushaTab2Component } from './wizard-tabs/yerusha-tab2/yerusha-tab2.component';
import { YerushaTab3Component } from './wizard-tabs/yerusha-tab3/yerusha-tab3.component';
import { YerushaTab4Component } from './wizard-tabs/yerusha-tab4/yerusha-tab4.component';
import { YerushaTab5Component } from './wizard-tabs/yerusha-tab5/yerusha-tab5.component';
import { YerushaTab6Component } from './wizard-tabs/yerusha-tab6/yerusha-tab6.component';

@Component({
  selector: 'app-bakasha-letzav-yerusha',
  templateUrl: './bakasha-letzav-yerusha.component.html'
})
export class BakashaLetzavYerushaComponent implements OnInit {
  items!: WizardItem[];

  constructor() { }

  ngOnInit() {
    this.items = [{
        label: 'הנחיות ופרטי הבקשה', component: YerushaTab1Component,
      },
      {
        label: 'חלק א\' מבקש מוריש', component: YerushaTab2Component,
      },
      {
        label: 'חלק ב\' בן הזוג וקרובים', component: YerushaTab3Component,
        componentData: { name: 'my form', id: 1 },
      },
      {
        label: 'חלק ג\' שינוי אחר הפטירה', component: YerushaTab4Component
      },
      {
        label: 'חלק ד\' היורשים', component: YerushaTab5Component,
      },
      {
        label: 'חלק ה\' תצהיר', component: YerushaTab6Component //, isForMetro: true,
      }
    ]
  }

}
