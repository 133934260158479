<div>
    <div>
        <div class="paddingTop10 font-20 blue3 bold">המבקש/ת המוריש/ה ומעמד היורשים (שדות המסומנים בכוכבית הנם שדות
            חובה) </div>
    </div>

    <div>
        <moj-line>
            <div class="line-flex-right paddingRight0 col-md-4">
                <div class="font-16 blue3 bold">1.
                    פרטי ה
                    <span style="color:#c90101;">*</span>
                </div>
                <moj-radiobutton style="width:112px;margin-right:10px;" [(ngModel)]="isMevakesh" [radiovalue]="true"
                    name="mevakeshGender" labelTextKey="מבקש">
                </moj-radiobutton>
                <moj-radiobutton style="width:114px;" [(ngModel)]="isMevakeshet" [radiovalue]="false"
                    name="mevakeshGender" labelTextKey="מבקשת">
                </moj-radiobutton>
            </div>
        </moj-line>
    </div>

    <div>
        <moj-line>
            <moj-dropdownlist required [ngModel]="idTypeSelected" labelWidthColumns="1" [controlWidthColumns]="4"
                name="idType" fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="true" [items]="idTypes">
            </moj-dropdownlist>
        </moj-line>
    </div>

    <div>
        <moj-line>
            <moj-textbox [required]="true" [identification]='idTypeSelected==1' ngModel name="idNumber"
                labelTextKey="מספר זיהוי מבקש/ת" labelWidthColumns="2" [controlWidthColumns]="4"
                isLabelAboveControl="true" [maxlength]="9">
            </moj-textbox>
            <moj-textbox [required]="true" ngModel name="firstName" [labelTextKey]="'שם פרטי מבקש/ת'" [maxlength]="50"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
            </moj-textbox>
            <moj-textbox [required]="true" ngModel name="lastName" [labelTextKey]="'שם משפחה מבקש/ת'" [maxlength]="50"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
            </moj-textbox>
        </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
        <h2 class="font-16 orange2 bold">כתובת המבקש/ת</h2>
    </div>

    <div>
        <moj-line>
            <moj-autocomplete isLabelAboveControl="true" required name="city" [labelTextKey]="'ישוב'"
                labelWidthColumns="1" [controlWidthColumns]="4" [items]="cities" fieldName="value" fieldID="id"
                [dropdown]="true" [(ngModel)]="citySelected">
            </moj-autocomplete>
            <moj-autocomplete isLabelAboveControl="true" required name="street" [labelTextKey]="'רחוב'"
                labelWidthColumns="1" [controlWidthColumns]="4" [items]="streets" fieldName="value" fieldID="id"
                [dropdown]="true" [(ngModel)]="streetSelected">
            </moj-autocomplete>
            <moj-textbox required ngModel name="houseNumber" [labelTextKey]="'מספר בית'" [maxlength]="50"
                labelWidthColumns="2" [controlWidthColumns]="2" isLabelAboveControl="true">
            </moj-textbox>
            <moj-textbox ngModel name="mikud" [labelTextKey]="'מיקוד'" [maxlength]="50" labelWidthColumns="2"
                [controlWidthColumns]="1" isLabelAboveControl="true">
            </moj-textbox>
        </moj-line>
    </div>

    <div>
        <moj-line>
            <moj-textbox [required]="true" ngModel name="phone" [labelTextKey]="'טלפון לתקשרות'" [maxlength]="50"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
            </moj-textbox>
        </moj-line>
    </div>

    <div>
        <moj-line>
            <moj-dropdownlist required [ngModel]="interestSelected" labelWidthColumns="1" [controlWidthColumns]="4"
                name="interest" fieldID="id" labelTextKey="עניין במתן הצו" isLabelAboveControl="true"
                [items]="interests">
            </moj-dropdownlist>
        </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
        <moj-line>
            <div class="col-xs-3">
                <moj-radiobutton [(ngModel)]="isLawyer" [radiovalue]="true" [name]="'lawyerId'"
                    [labelTextKey]="'מיוצג בבקשה על ידי עורך דין'">
                </moj-radiobutton>
            </div>
            <div class="col-xs-4">
                <moj-radiobutton [(ngModel)]="isLawyer" [radiovalue]="false" [name]="'lawyerId'"
                    [labelTextKey]="'לא מיוצג בבקשה על ידי עורך דין'">
                </moj-radiobutton>
            </div>
        </moj-line>
    </div>

    <div *ngIf="isLawyer">
        <moj-line>
            <moj-textbox [required]="true" ngModel name="advoateFirstname" labelTextKey="שם המייצג"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true" [maxlength]="50">
            </moj-textbox>
            <moj-textbox [required]="true" ngModel name="advocateLastName" [labelTextKey]="'שם משפחתו'" [maxlength]="50"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
            </moj-textbox>
            <moj-textbox [required]="true" ngModel name="lastName" [labelTextKey]="'מספר רישיון'" [maxlength]="9"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
            </moj-textbox>
        </moj-line>
    </div>

    <div *ngIf="isLawyer">
        <moj-line>
            <moj-textbox [required]="true" ngModel name="advoatePhone" labelTextKey="טלפון" labelWidthColumns="2"
                [controlWidthColumns]="4" isLabelAboveControl="true" [maxlength]="10" minlength="9">
            </moj-textbox>
            <moj-file-upload labelTextKey="יפוי כח" labelWidthColumns="1" [controlWidthColumns]="4"
                isLabelAboveControl="true" [required]="true" [id]="'fuPoa'" [(ngModel)]="filesPoa" name="fuPoa" show
                [maxFileSize]="23068672" [enabledFileTypes]="'pdf'" designType="fuDesignType.Single">
            </moj-file-upload>
        </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
        <h2 class="font-16 orange2 bold">המען להמצאת כתבי דין</h2>
    </div>

    <div>
        <moj-line>
            <moj-autocomplete isLabelAboveControl="true" required name="cityMaan" [labelTextKey]="'ישוב'"
                labelWidthColumns="1" [controlWidthColumns]="4" [items]="cities" fieldName="value" fieldID="id"
                [dropdown]="true" [(ngModel)]="cityMaanSelected">
            </moj-autocomplete>
            <moj-autocomplete isLabelAboveControl="true" required name="streetMaan" [labelTextKey]="'רחוב'"
                labelWidthColumns="1" [controlWidthColumns]="4" [items]="streets" fieldName="value" fieldID="id"
                [dropdown]="true" [(ngModel)]="streetMaanSelected">
            </moj-autocomplete>
            <moj-textbox required ngModel name="houseNumberMaan" [labelTextKey]="'מספר בית'" [maxlength]="50"
                labelWidthColumns="2" [controlWidthColumns]="2" isLabelAboveControl="true">
            </moj-textbox>
            <moj-textbox ngModel name="mikudMaan" [labelTextKey]="'מיקוד'" [maxlength]="50" labelWidthColumns="2"
                [controlWidthColumns]="1" isLabelAboveControl="true">
            </moj-textbox>
        </moj-line>
    </div>

    <div>
        <moj-line>
            <moj-textbox [required]="true" ngModel name="email" [labelTextKey]="'דואר אלקטרוני'" [maxlength]="50"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
            </moj-textbox>
        </moj-line>
    </div>

    <div>
        <moj-line>
            <div class="paddingRight20 paddingTop5">
                <moj-checkbox [controlWidthColumns]=4 labelWidthColumns=8 [(ngModel)]="isSms" name="sms"
                    labelTextKey="ברצוני לקבל עדכונים במסרון לגבי התקדמות הטיפול בתיק">
                </moj-checkbox>
            </div>
            <div *ngIf="isSms" class="col-xs-4">
                <moj-textbox [required]="true" ngModel name="phone" labelTextKey="מספר טלפון סלולארי לעדכון במסרונים"
                    labelWidthColumns="2" [controlWidthColumns]="9" isLabelAboveControl="true" [maxlength]="10"
                    minlength="9">
                </moj-textbox>
            </div>
        </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
        <moj-line>
            <div class="line-flex-right paddingRight0 col-md-4">
                <div class="font-16 blue3 bold">2.
                    פרטי ה
                    <span style="color:#c90101;">*</span>
                </div>
                <moj-radiobutton style="width:112px;margin-right:10px;" [(ngModel)]="isMorish" [radiovalue]="true"
                    name="morishGender" labelTextKey="מוריש">
                </moj-radiobutton>
                <moj-radiobutton style="width:114px;" [(ngModel)]="isMorisha" [radiovalue]="false" name="morishGender"
                    labelTextKey="מורישה">
                </moj-radiobutton>
            </div>
        </moj-line>
    </div>

    <div>
        <moj-line>
            <moj-dropdownlist [disabled]="true" required [ngModel]="idTypeSelected" labelWidthColumns="1"
                [controlWidthColumns]="4" name="idType" fieldID="id" labelTextKey="סוג תעודה מזהה"
                isLabelAboveControl="true" [items]="idTypes">
            </moj-dropdownlist>
        </moj-line>
    </div>

    <div>
        <moj-line>
            <moj-textbox [disabled]="true" [required]="true" [identification]='idTypeSelected==1' ngModel
                name="idNumber" labelTextKey="מספר זיהוי מוריש/ה" labelWidthColumns="2" [controlWidthColumns]="4"
                isLabelAboveControl="true" [maxlength]="9">
            </moj-textbox>
            <moj-textbox [disabled]="true" [required]="true" ngModel name="firstName" [labelTextKey]="'שם פרטי מוריש/ה'"
                [maxlength]="50" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
            </moj-textbox>
            <moj-textbox [disabled]="true" [required]="true" ngModel name="lastName" [labelTextKey]="'שם משפחה מוריש/ה'"
                [maxlength]="50" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
            </moj-textbox>
        </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
        <div>
            <h2 class="font-16 orange2 bold">ומקום מושבו/ה היה</h2>
        </div>
    </div>

    <div>
        <moj-line>
            <div class="col-sm-3">
                <moj-radiobutton [(ngModel)]="isIsraelResident" [radiovalue]="true" [name]="'israelResident'"
                    labelTextKey="ישראל">
                </moj-radiobutton>
            </div>
            <div class="col-xs-4">
                <moj-radiobutton [(ngModel)]="isIsraelResident" [radiovalue]="false" [name]="'israelResident'"
                    labelTextKey="חו''ל">
                </moj-radiobutton>
            </div>
        </moj-line>
    </div>

    <div *ngIf="isIsraelResident">
        <moj-line>
            <moj-autocomplete isLabelAboveControl="true" required name="cityMorish" [labelTextKey]="'ישוב'"
                labelWidthColumns="1" [controlWidthColumns]="4" [items]="cities" fieldName="value" fieldID="id"
                [dropdown]="true" [(ngModel)]="cityMorishSelected">
            </moj-autocomplete>
            <moj-autocomplete isLabelAboveControl="true" required name="streetMaan" [labelTextKey]="'רחוב'"
                labelWidthColumns="1" [controlWidthColumns]="4" [items]="streets" fieldName="value" fieldID="id"
                [dropdown]="true" [(ngModel)]="streetMaanSelected">
            </moj-autocomplete>
            <moj-textbox required ngModel name="houseNumberMaan" [labelTextKey]="'מספר בית'" [maxlength]="50"
                labelWidthColumns="2" [controlWidthColumns]="2" isLabelAboveControl="true">
            </moj-textbox>
            <moj-textbox ngModel name="mikudMaan" [labelTextKey]="'מיקוד'" [maxlength]="50" labelWidthColumns="2"
                [controlWidthColumns]="1" isLabelAboveControl="true">
            </moj-textbox>
        </moj-line>
    </div>

    <div *ngIf="!isIsraelResident">
        <moj-line>
            <moj-autocomplete *ngIf="dataStoreService.countries$ | async as countriesItems" [items]="countriesItems"
                isLabelAboveControl="true" required name="countryMorish" [labelTextKey]="'ארץ מקום מושבו'"
                labelWidthColumns="1" [controlWidthColumns]="4" fieldName="value" fieldID="id" [dropdown]="true"
                [(ngModel)]="countryMorishSelected">
            </moj-autocomplete>
            <moj-autocomplete isLabelAboveControl="true" required name="cityMorish"
                [labelTextKey]="'מקום הימצאו של הנכס בישראל'" labelWidthColumns="1" [controlWidthColumns]="4"
                [items]="cities" fieldName="value" fieldID="id" [dropdown]="true" [(ngModel)]="cityMorishSelected">
            </moj-autocomplete>
            <moj-file-upload labelTextKey="אסמכתא להימצאות הנכס" labelWidthColumns="1" [controlWidthColumns]="4"
                isLabelAboveControl="true" [required]="true" [id]="'fuAsmachta'" [(ngModel)]="filesAsmachta"
                name="fuAsmachta" show [maxFileSize]="23068672" [enabledFileTypes]="'pdf'"
                designType="fuDesignType.Single">
            </moj-file-upload>
        </moj-line>
    </div>

    <div *ngIf="!isIsraelResident">
        <moj-line>
            <moj-file-upload labelTextKey="חוות דעת דין מקוצר" labelWidthColumns="1" [controlWidthColumns]="4"
                isLabelAboveControl="true" [required]="true" [id]="'fuDinMekutzar'" [(ngModel)]="filesDinMekutzar"
                name="fuDinMekutzar" show [maxFileSize]="23068672" [enabledFileTypes]="'pdf'"
                designType="fuDesignType.Single">
            </moj-file-upload>
        </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
        <h2 class="font-16 orange2 bold">נפטר/ה ביום</h2>
    </div>

    <div>
        <moj-line>
            <moj-datepicker isLabelAboveControl="true" [ngModel]="dateDeceased" name="dateDeceased"
                labelTextKey="תאריך פטירה לועזי" labelWidthColumns="1" [controlWidthColumns]="2">
            </moj-datepicker>

            <div class="col-xs-10">
                <span style="padding-right: 15px;">תאריך פטירה עברי</span>
                <span style="color:#c90101;">*</span><br>
                <moj-line>
                    <moj-dropdownlist placeholder="שנה" required [ngModel]="hebrewYearSelected" labelWidthColumns="0"
                        [controlWidthColumns]="2" name="hebrewYear" fieldID="id" isLabelAboveControl="true"
                        [items]="hebrewYears">
                    </moj-dropdownlist>
                    <moj-dropdownlist placeholder="חודש" required [ngModel]="hebrewMonthSelected" labelWidthColumns="0"
                        [controlWidthColumns]="2" name="hebrewMonth" fieldID="id" isLabelAboveControl="true"
                        [items]="hebrewMonths">
                    </moj-dropdownlist>
                    <moj-dropdownlist placeholder="יום" required [ngModel]="hebrewDaySelected" labelWidthColumns="0"
                        [controlWidthColumns]="2" name="hebrewDay" fieldID="id" isLabelAboveControl="true"
                        [items]="hebrewDays">
                    </moj-dropdownlist>
                </moj-line>
            </div>
        </moj-line>
    </div>


    <div>
        <moj-line>
            <moj-textbox required ngModel name="PlaceOfDeath" [labelTextKey]="'מקום פטירתו/ה (ישוב)'" [maxlength]="50"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
            </moj-textbox>
            <div class="line-flex-right col-xs-6">
                <div class="col-xs-12" style="padding-top:9px;">
                    <span style="padding-right:9px">כשהוא / היא</span>
                    <span style="color:#c90101;">*</span>
                </div>
                <moj-radiobutton style="width:112px;margin-right:10px;" [(ngModel)]="maritalStatusSelected"
                    [radiovalue]="1" name="maritalStatus" labelTextKey="רווק/ה">
                </moj-radiobutton>
                <moj-radiobutton [(ngModel)]="maritalStatusSelected" [radiovalue]="2" name="maritalStatus"
                    style="width:112px;" labelTextKey="נשוי/אה">
                </moj-radiobutton>
                <moj-radiobutton [(ngModel)]="maritalStatusSelected" [radiovalue]="3" name="maritalStatus"
                    style="width:112px;" labelTextKey="אלמנ/ה">
                </moj-radiobutton>
                <moj-radiobutton [(ngModel)]="maritalStatusSelected" [radiovalue]="4" name="maritalStatus"
                    style="width:112px;" labelTextKey="גרוש/ה">
                </moj-radiobutton>
            </div>
        </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
        <h2 class="font-16 orange2 bold">להוכחת המוות אני מצרף את המסמכים המפורטים להלן:</h2>
    </div>

    <div>
        <moj-line>
            <div class="col-sm-12">
                <h3 class="font-14" style="float:right;">שורה 1</h3>
                <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
                        style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
            </div>
        </moj-line>
    </div>

    <div>
        <moj-line>
            <div class="col-sm-8">
                <moj-file-upload labelTextKey=" " labelWidthColumns="0" [controlWidthColumns]="4" [required]="true"
                    [id]="'fuProof1'" [(ngModel)]="filesProof1" name="fuProof1" show [maxFileSize]="23068672"
                    [enabledFileTypes]="'pdf'" designType="fuDesignType.Single">
                </moj-file-upload>
            </div>
        </moj-line>
    </div>

    <div>
        <moj-line>
            <div style="padding-left:30px;">
                <button style="background-color:#B7D3E4;padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
            </div>
        </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
        <moj-line>
            <div class="line-flex-right paddingRight0 col-md-4">
                <div class="font-16 blue3 bold">3.
                    המוריש/ה
                    <span style="color:#c90101;">*</span>
                </div>
                <moj-radiobutton style="width:112px;margin-right:10px;" [(ngModel)]="isLeftwill" [radiovalue]="true"
                    name="leftwill" labelTextKey="השאיר/ה">
                </moj-radiobutton>
                <moj-radiobutton [(ngModel)]="isLeftwill" [radiovalue]="false" name="leftwill" style="width:112px;"
                    labelTextKey="לא השאיר/ה">
                </moj-radiobutton>
                צוואה
            </div>
        </moj-line>
    </div>

    <div *ngIf="isLeftwill">
        <moj-line>
            <div class="line-flex col-sm-8">
                <moj-radiobutton [(ngModel)]="willForm" [radiovalue]="true" [name]="'willForm'" style="width:112px;"
                    labelTextKey="בכתב יד">
                </moj-radiobutton>
                <moj-radiobutton [(ngModel)]="willForm" [radiovalue]="false" [name]="'willForm'" style="width:112px;"
                    labelTextKey="בפני עדים">
                </moj-radiobutton>
                <moj-radiobutton [(ngModel)]="willForm" [radiovalue]="false" [name]="'willForm'" style="width:112px;"
                    labelTextKey="בע''פ">
                </moj-radiobutton>
                <moj-radiobutton [(ngModel)]="willForm" [radiovalue]="false" [name]="'willForm'" style="width:201px;"
                    labelTextKey="בפני הרשות (לרבות נוטריון)">
                </moj-radiobutton>
            </div>
        </moj-line>
    </div>

    <div *ngIf="isLeftwill">
        <moj-line>
            <moj-textbox [required]="true" ngModel name="requestReason"
                [labelTextKey]="'סיבת הגשת הבקשה לצו ירושה על אף הימצאותה של צוואה'" [maxlength]="150"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
            </moj-textbox>
            <moj-file-upload labelTextKey="העתק צוואה" labelWidthColumns="1" [controlWidthColumns]="4"
                isLabelAboveControl="true" [required]="true" [id]="'fuWillCopy'" [(ngModel)]="filesWillCopy"
                name="fuWillCopy" show [maxFileSize]="23068672" [enabledFileTypes]="'pdf'"
                designType="fuDesignType.Single">
            </moj-file-upload>
        </moj-line>
    </div>

    <div *ngIf="isLeftwill">
        <moj-line>
            <moj-datepicker isLabelAboveControl="true" [ngModel]="dateWill" name="dateWill"
                labelTextKey="תאריך צוואה לועזי" labelWidthColumns="1" [controlWidthColumns]="2">
            </moj-datepicker>

            <div class="col-xs-10">
                <span style="padding-right: 15px;">תאריך צוואה עברי</span>
                <span style="color:#c90101;">*</span><br>
                <moj-line>
                    <moj-dropdownlist placeholder="שנה" required [ngModel]="hebrewYearWillSelected"
                        labelWidthColumns="0" [controlWidthColumns]="2" name="hebrewYearWill" fieldID="id"
                        isLabelAboveControl="true" [items]="hebrewYears">
                    </moj-dropdownlist>
                    <moj-dropdownlist placeholder="חודש" required [ngModel]="hebrewMonthWillSelected"
                        labelWidthColumns="0" [controlWidthColumns]="2" name="hebrewMonthWill" fieldID="id"
                        isLabelAboveControl="true" [items]="hebrewMonths">
                    </moj-dropdownlist>
                    <moj-dropdownlist placeholder="יום" required [ngModel]="hebrewDayWillSelected" labelWidthColumns="0"
                        [controlWidthColumns]="2" name="HebrewDayWill" fieldID="id" isLabelAboveControl="true"
                        [items]="hebrewDays">
                    </moj-dropdownlist>
                </moj-line>
            </div>
        </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
        <h2 class="font-16 orange2 bold">כשרותם המשפטית של היורשים על פי דין</h2>
    </div>

    <div>
        <moj-line>
            <div class="col-xs-4 line-flex-right paddingRight0 blue3 font-16 bold">
                4.
                בין היורשים על פי דין (לרבות יורשים שהסתלקו):
                <span style="color:#c90101;">*</span>
            </div>
            <div class="col-xs-5 paddingRight0">
                <div class="col-xs-4">
                    <moj-radiobutton [(ngModel)]="isIncluded" [radiovalue]="true" name="included" labelTextKey="נכלל">
                    </moj-radiobutton>
                </div>
                <div class="col-xs-4">
                    <moj-radiobutton [(ngModel)]="isIncluded" [radiovalue]="false" name="included"
                        labelTextKey="לא נכלל">
                    </moj-radiobutton>
                </div>
            </div>
        </moj-line>
        <div>
            <moj-line>
                <div class="line-flex-right col-sm-12">
                    <moj-checkbox [controlWidthColumns]=1 labelWidthColumns=2 [(ngModel)]="isMinor" name="minor"
                        labelTextKey="קטין">
                    </moj-checkbox>
                    <moj-checkbox [controlWidthColumns]=1 labelWidthColumns=9 [(ngModel)]="isGuardian" name="guardian"
                        style="width:203px" ~ labelTextKey="אדם שמונה לו אפוטרופוס">
                    </moj-checkbox>
                    <moj-checkbox [controlWidthColumns]=1 labelWidthColumns=2 [(ngModel)]="isMissing" name="missing"
                        labelTextKey="נעדר">
                    </moj-checkbox>
                </div>
            </moj-line>
        </div>

    </div>