<moj-line>
    <h2 class="moj-primary-color">{{ tguvotTexts.searchResults }}</h2>
    <div class="paddingTop15">
        <moj-grid-panel>
            <ag-grid-angular style="height: 400px" class="ag-theme-balham" [gridOptions]="gridOptions"
                [rowData]="rowData" [columnDefs]="columns" [suppressHorizontalScroll]="true"
                [infiniteInitialRowCount]="9" [pagination]="false" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </moj-grid-panel>
    </div>
</moj-line>