<moj-line>
  <h1 class="font-28 moj-bold moj-primary-color">
    רישום לקבלת התראה על הגשת בקשה לצו ירושה/קיום צוואה אצל הרשם לענייני ירושה
  </h1>
</moj-line>

<moj-line>
  <p class="font-20">
    שירות קבלת התראה על הגשת בקשה שהוגשה לרשם הירושה, מאפשר לכל אדם לקבל עדכון על הגשת בקשה לצו לרשם הירושה באופן יעיל
    ומהיר וללא הצורך לבדוק באופן יזום מידי יום באתר.
    לאחר הרישום לשירות, אם תוגש בקשה לצו לרשם הירושה בעניין עזבון של אדם, תשלח הודעה על כך למבקש. הרישום לשירות תקף למשך
    שנתיים.


  </p>
</moj-line>

<form #f="ngForm" #pnl>
  <moj-line>
    <div class="font-20 moj-bold moj-orange-color">פרטי מבקש</div>
  </moj-line>
  <moj-line>
    <moj-textbox [required]="true" pattern="^[A-Za-zא-ת\.\-\s\,\']*$" ngModel name="firstName"
      [labelTextKey]="'שם פרטי'" [maxlength]="50" labelWidthColumns="2" [controlWidthColumns]="3"
      isLabelAboveControl="true">
    </moj-textbox>

    <moj-textbox [required]="true" pattern="^[A-Za-zא-ת\.\-\s\,\']*$" ngModel name="lastName"
      [labelTextKey]="'שם משפחה'" [maxlength]="50" labelWidthColumns="2" [controlWidthColumns]="3"
      isLabelAboveControl="true">
    </moj-textbox>
  </moj-line>

  <moj-line>
    <moj-textbox ngModel (keyup)="onEmailorPhoneChanged($event)" name="phone" [required]="isPhoneRequired"
      pattern="^\d+$" [maxlength]="10" minlength="9" [labelTextKey]="'מספר טלפון נייד'" labelWidthColumns="2"
      [controlWidthColumns]="3" isLabelAboveControl="true">
    </moj-textbox>

    <moj-textbox ngModel (keyup)="onEmailorPhoneChanged($event)" email [required]="isEmailRequired" name="email"
      [maxlength]="50" [labelTextKey]="'דואר אלקטרוני'" labelWidthColumns="2" [controlWidthColumns]="3"
      isLabelAboveControl="true">
    </moj-textbox>
  </moj-line>

  <moj-line>
    <div class="font-14 moj-bold" style="margin-top: -1em;">לצורך עדכונים חובה להזין אחד מפרטי ההתקשרות</div>
  </moj-line>

  <moj-line>
    <div class="font-20 moj-bold moj-orange-color">פרטי המנוח/ה </div>
  </moj-line>

  <moj-line>
    <moj-button-toggle [items]="buttonToggleItems" [(ngModel)]="identityType" name="identityType" required
      [controlWidthColumns]="1"> </moj-button-toggle>
  </moj-line>

  <moj-line></moj-line>

  <moj-line>
    <moj-textbox [required]="true" [identification]='identityType == 1' ngModel name="idNumber"
      [labelTextKey]="identityType == 1 ? 'תעודת זהות' : 'דרכון'" labelWidthColumns="2" [controlWidthColumns]="3"
      isLabelAboveControl="true" [maxlength]="15">
    </moj-textbox>

    <div *ngIf="identityType == 2">
      <moj-autocomplete required name="countryId" [placeholder]="'בחר'" [labelTextKey]="'ארץ הנפקת דרכון'"
        labelWidthColumns="2" [controlWidthColumns]="3" *ngIf="dataStoreService.countries$ | async as countriesItems"
        [items]="countriesItems" fieldName="value" fieldID="id" [dropdown]="true" isLabelAboveControl="true"
        [(ngModel)]="countries">
      </moj-autocomplete>
    </div>
  </moj-line>

  <moj-line>
    <moj-textbox [required]="true" pattern="^[A-Za-zא-ת\.\-\s\,\']*$" ngModel name="entityFirstName"
      [labelTextKey]="'שם פרטי'" [maxlength]="50" labelWidthColumns="2" [controlWidthColumns]="3"
      isLabelAboveControl="true">
    </moj-textbox>

    <moj-textbox [required]="true" pattern="^[A-Za-zא-ת\.\-\s\,\']*$" ngModel name="entityLastName"
      [labelTextKey]="'שם משפחה'" [maxlength]="50" labelWidthColumns="2" [controlWidthColumns]="3"
      isLabelAboveControl="true">
    </moj-textbox>
  </moj-line>

  <moj-buttons-line>
    <moj-submit-button [buttonStyle]="buttonStyle.Primary" [form]="f" (onSubmit)="sendRequestNotification()"
      [textKey]="'רישום לשירות'" [isEnable]=true>
    </moj-submit-button>
  </moj-buttons-line>
</form>