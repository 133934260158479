<moj-line class="container">
  <form #f="ngForm">
    <moj-expansion-panel [hideToggle]="true">
      <moj-expansion-panel-header [addButton]="false">
        <moj-line>
          <h1 class="font-28 moj-bold moj-primary-color">
            {{requestsTexts.searchRequests}} </h1>
        </moj-line>
      </moj-expansion-panel-header>
      <moj-line>
        <div class="font-20 moj-bold">
          <!-- {{ requestsTexts.searchAdviseMessage }} -->
          תשומת הלב כי על פי חוק הירושה התשכ"ה 1965 ותקנות הירושה התשנ"ח 1998 ניתן להגיש כתב התנגדות בתוך תקופה של
          שבועיים
          מיום פירסום הבקשה באתר.
        </div>
      </moj-line>
      <moj-line>
        <div class="font-18 moj-bold">
          <!-- {{ requestsTexts.searchAdviseMessage }} -->
          אפשר לאתר בקשות לפי הפרטים האישיים של המנוח. בקשות שהוגשו ברשם הירושה אפשר לאתר לפי פרטי הבקשה
        </div>
      </moj-line>
      <div role="search">
        <!-- <moj-line>
            <div class="font-20 moj-bold moj-orange-color">
              {{ requestsTexts.deceasedDetails }}
            </div>
          </moj-line> -->

        <moj-line>
          <moj-textbox [identification]='gridType == 2' [ngModel]="tzManoah" [maxlength]="15" name="tzManoah"
            pattern="^[A-Za-z0-9]*$" [labelTextKey]="gridType == 1 ? requestsTexts.idNumber : 'מספר זהות'"
            [controlWidthColumns]="2">
          </moj-textbox>

          <!-- <div class="col-md-1"></div> -->

          <div class="col-md-5 paddingX0">
            <moj-textbox [maxlength]="30" [ngModel]="shemMishpachaManoach" minlength="2" name="shemMishpachaManoach"
              pattern="[a-z'A-Zא-ת- ]*" [labelTextKey]="requestsTexts.lastName" [controlWidthColumns]="6">
            </moj-textbox>

            <moj-textbox [maxlength]="20" [ngModel]="shemPratiManoach" minlength="2" name="shemPratiManoach"
              pattern="[a-z'A-Zא-ת- ]*" [labelTextKey]="requestsTexts.firstName" [controlWidthColumns]="6">
            </moj-textbox>
          </div>

        </moj-line>

        <moj-line>
          <!-- <div class="font-20 moj-orange-color moj-bold">
              {{ requestsTexts.requestDetails + ' ' +'ברשם הירושה'}}
            </div> -->
          <div class="font-18">עבור בקשות שהוגשו לרשם לענייני ירושה ניתן לאתר גם על פי נתונים אלה:</div>
        </moj-line>

        <moj-line>
          <moj-textbox [disabled]='gridType == 2' [ngModel]="misparTik" name="misparTik"
            [labelTextKey]="requestsTexts.caseNumber + ' ברשם הירושה'" pattern="^\d+$" [maxlength]="10"
            [controlWidthColumns]="2">
          </moj-textbox>

          <!-- <div class="col-md-1"></div> -->

          <div class="col-md-5 paddingX0">
            <moj-datepicker [disabled]='gridType == 2' [ngModel]="taarichFrom" name="taarichFrom"
              [labelTextKey]="requestsTexts.fromDate + ' בקשה ברשם הירושה'" [controlWidthColumns]="6"
              #dateFromVal="ngModel">
            </moj-datepicker>
            <moj-datepicker [disabled]='gridType == 2' [ngModel]="taarichTo"
              [labelTextKey]="'עד תאריך בקשה ברשם הירושה'" name="taarichTo" [controlWidthColumns]="6"
              #dateToVal="ngModel" [greaterThan]="dateFromVal">
            </moj-datepicker>
          </div>

          <!-- <div class="col-md-1"></div> -->

          <moj-dropdownlist *ngIf=" dataStoreService.machozot$| async as machozotItems" [disabled]='gridType == 2'
            [ngModel]="machoz" [controlWidthColumns]="2" name="machoz" fieldID="id"
            [labelTextKey]="requestsTexts.district + ' ברשם הירושה'" [items]="machozotItems">
          </moj-dropdownlist>
        </moj-line>

        <moj-line>
          <div>
            <moj-buttons-line style="float: left">
              <moj-clear-button [form]="f"> </moj-clear-button>
              <moj-submit-button id="searchBakashotBtn" [buttonStyle]="buttonStyle.Primary" [form]="f"
                (onSubmit)="onSubmit()" [textKey]="requestsTexts.search" [isEnable]="(f.valid && !showLoading)!">
              </moj-submit-button>
            </moj-buttons-line>
          </div>
        </moj-line>
      </div>
    </moj-expansion-panel>

  </form>

  <moj-line>

    <div *ngIf="showGrid" style="margin: auto; width: fit-content;">
      <moj-line>
        <moj-button-toggle [items]="buttonToggleItems" [(ngModel)]="gridType" name="gridType" required
          [controlWidthColumns]="1"> </moj-button-toggle>
      </moj-line>
    </div>
  </moj-line>

  <moj-line *ngIf="gridType==1">
    <bakashot-output *ngIf="showGrid" [data]="bakashot" [showLoading]="showLoading"></bakashot-output>
  </moj-line>

  <moj-line *ngIf="gridType==2">
    <councel-request-output *ngIf="showGridCouncel" [data]="bdikatBakashotResponse"
      [showLoading]="showLoadingCouncel"></councel-request-output>
  </moj-line>

</moj-line>