import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';

import { map } from 'rxjs/operators';
import { Client } from '../models/client-data.model';
import { InheritanceDataService } from '../services/inheritance-data.service';

@Injectable()
export class AuthService {

    constructor(private http: HttpClient,
       private router: Router,
       private inherService: InheritanceDataService) {
    }
    
    private data! : Client;

    private loggedIn = new BehaviorSubject<boolean>(false);

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get clientData(){
      return this.data;
  }

  isIpAuthorized(){
    return this.inherService.isIpAuthorized();
  }

  login(user: Client, model: string){
    if (user.idNumber !== 0 && user.firstName !== '' && user.lastName !== '') {
      
      return this.inherService.isPersonalDataExistsCorp(user).pipe(
        map(isValidClient=> {
          if(isValidClient)
          {
              this.data = user;
              this.loggedIn.next(true);
              this.router.navigate(['/find-court-order']);
              return true;
          }
          return false;
        })
      )
    }
    else return of(false);
  }

  logout() {
    this.loggedIn.next(false);
    this.router.navigate(['/login-corp']);
  }
}