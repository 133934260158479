import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FontSize, FontWeight, GridService, MojColor } from '@moj/moj-ng';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { InheritanceDataService } from '../../services/inheritance-data.service';
import { DetailCellRenderer } from './detail-cell-renderer.components';

@Component({
  selector: 'debts-output',
  templateUrl: './debts-output.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebtsOutputComponent implements OnInit {

  private _data = new BehaviorSubject<any[]>([]);

  @Input() set showLoading(value: any) {
    if (this.gridApi) {
      (value) ? this.gridApi.showLoadingOverlay() : this.gridApi.hideOverlay();
    }
  }

  @Input()
  set data(value: any[]) {
    this._data.next(value);
  };

  get data() {
    return this._data.getValue();
  }

  localAuthorityName!: string;

  private gridApi!: GridApi;
  gridColumnApi: any;
  detailCellRenderer;
  frameworkComponents;
  detailCellRendererParams: any;

  oldIndex = -1;



  rowData!: any[];
  columnDefs: ColDef[];
  defaultColDef

  fontSize = FontSize;
  fontWeight = FontWeight;
  mojColor = MojColor;
  isToManyRecords: boolean = false;
  gridOptions: GridOptions;
  //Enums: Enums = ENUMS;

  deceasedText;


  constructor(private gridService: GridService,
    private translateService: TranslateService,
    private dataService: InheritanceDataService) {

    this.defaultColDef = { flex: 1 };
    this.detailCellRenderer = 'myDetailCellRenderer';
    this.frameworkComponents = { myDetailCellRenderer: DetailCellRenderer };

    this.gridOptions = this.gridService.getMojGridOptions();
    this.gridOptions.domLayout = 'normal';
    this.deceasedText = this.translateService.instant('Texts.deceasedText');


    this.columnDefs = [
      this.gridService.getMojIconColumn('', {
        icon: { iconClass: "far fa-eye", field: "debtId", tooltip: "requestTexts.viewDetails" },
        colDef: { width: 105, maxWidth: 105, headerName: "deceasedText.debtsDetails" }
      }),
      this.gridService.getMojColumn("idNumber", { colDef: { headerName: "deceasedText.idNumber" } }),
      this.gridService.getMojColumn("firstName", { colDef: { headerName: "deceasedText.firstName" } }),
      this.gridService.getMojColumn("lastName", { colDef: { headerName: "deceasedText.lastName" } }),
      this.gridService.getMojColumn("amount", { colDef: { headerName: "deceasedText.amount" } }),
      this.gridService.getMojColumn("isGuard", { colDef: { hide: true } }),
      this.gridService.getMojColumn("localAuthorityName", { colDef: { headerName: "deceasedText.localAuthorityName" } }),
    ]
  }

  ngOnInit() {
    this.dataService.getAuthorityName().subscribe(res => {
      if (res)
        this.localAuthorityName = res;
    });
  }


  onRowClicked(params:any) {
    params.data.isGuard = (params.data.localAuthorityName == this.localAuthorityName);
    if (isNaN(params.node.id)) return;
    if (this.oldIndex != -1 && params.api.getRowNode(this.oldIndex))
      params.api.getDisplayedRowAtIndex(this.oldIndex).setExpanded(false);
    if (this.oldIndex != params.node.id) {
      params.api.getDisplayedRowAtIndex(params.node.id).setExpanded(true);
      this.oldIndex = params.node.id;
    }
    else this.oldIndex = -1;
  }


  onGridReady(params:any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.initGridData();

  }

  initGridData() {
    this._data.subscribe(x => {
      if (!x) return;
      this.rowData = x;
      this.isToManyRecords = this.data && (this.data.length >= 100);
    });
  }


}
