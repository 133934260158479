export enum SugNewBakashaEnum {
    MinuyMenahelIzavon = 7,
    TikunTsavYerusha = 11,
    TikunTsavKiumTsavaa = 12,
    BitulTsavYerusha = 14,
    BitulTsavkiumTsavaa = 15,
    OchachatMavet = 17,
    TikunTautSofer = 25,
    OchachatTsavaaInEtek = 26 ,    
    IkuvBitsua = 29,
    OsafatPratim = 31,
    HaarachatMoed = 41,
    YiunBatik = 50,
    BakashatTsavYerushaMetukenet = 96,
    BakashatTsavKiumTsavaaMetukenet =97,
    ChangeRepresentation =35
 }

