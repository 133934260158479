<div class="paddingTop10 font-20 blue3 bold">שינויים לפני פטירת המוריש/ה (שדות המסומנים בכוכבית הנם שדות חובה)
</div>

<moj-line>

  <div>
    <div class="col-sm-6 blue3 font-16">
      האם יש מבין הזוכים לפי הצוואה שנפטר לפני המוריש/ה?
      <span style="color:#c90101;">*</span>
    </div>
    <div class="col-sm-6 paddingRight0">
      <div class="col-xs-4 paddingX0">
        <moj-radiobutton [(ngModel)]="IsBeforeMorishNiftaar" [radiovalue]="true" name="beforeMorishNiftaar"
          labelTextKey="כן">
        </moj-radiobutton>
      </div>
      <div class="col-xs-4 paddingX0">
        <moj-radiobutton [(ngModel)]="IsBeforeMorishNiftaar" [radiovalue]="false" name="beforeMorishNiftaar"
          labelTextKey="לא">
        </moj-radiobutton>
      </div>
    </div>
  </div>
</moj-line>

<hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />
<section>
  <div *ngIf="IsBeforeMorishNiftaar">
    <div>
      <moj-line>
        <div class="col-sm-12">
          <h3 class="font-14" style="float:right;">שורה 1</h3>
          <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
              style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
        </div>
      </moj-line>
    </div>

    <div>
      <moj-line>
        <moj-dropdownlist required [ngModel]="idTypeSelected" labelWidthColumns="1" [controlWidthColumns]="4"
          name="idType" fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="true" [items]="idTypes">
        </moj-dropdownlist>
      </moj-line>
    </div>

    <div>
      <moj-line>
        <moj-textbox [required]="true" [identification]='idTypeSelected==1' ngModel name="idNumber"
          labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
          [maxlength]="9">
        </moj-textbox>
        <moj-textbox [required]="true" ngModel name="firstName" [labelTextKey]="'שם פרטי'" [maxlength]="50"
          labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
        </moj-textbox>
        <moj-textbox [required]="true" ngModel name="lastName" [labelTextKey]="'שם משפחה'" [maxlength]="50"
          labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
        </moj-textbox>
      </moj-line>
    </div>

    <div>
      <moj-line>
        <moj-dropdownlist required [ngModel]="reletiveSelected" labelWidthColumns="1" [controlWidthColumns]="4"
          name="reletive" fieldID="id" labelTextKey="הקרבה למוריש/ה" isLabelAboveControl="true" [items]="reletives">
        </moj-dropdownlist>
      </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />


    <div>
      <moj-line>
        <moj-datepicker isLabelAboveControl="true" [ngModel]="dateDeceased" name="dateDeceased"
          labelTextKey="תאריך פטירה לועזי" labelWidthColumns="1" [controlWidthColumns]="2">
        </moj-datepicker>

        <div class="col-xs-10">
          <span style="padding-right: 15px;">תאריך פטירה עברי</span>
          <span style="color:#c90101;">*</span><br>
          <moj-line>
            <moj-dropdownlist placeholder="שנה" required [ngModel]="hebrewYearSelected" labelWidthColumns="0"
              [controlWidthColumns]="2" name="hebrewYear" fieldID="id" isLabelAboveControl="true" [items]="hebrewYears">
            </moj-dropdownlist>
            <moj-dropdownlist placeholder="חודש" required [ngModel]="hebrewMonthSelected" labelWidthColumns="0"
              [controlWidthColumns]="2" name="hebrewMonth" fieldID="id" isLabelAboveControl="true"
              [items]="hebrewMonths">
            </moj-dropdownlist>
            <moj-dropdownlist placeholder="יום" required [ngModel]="hebrewDaySelected" labelWidthColumns="0"
              [controlWidthColumns]="2" name="hebrewDay" fieldID="id" isLabelAboveControl="true" [items]="hebrewDays">
            </moj-dropdownlist>
          </moj-line>
        </div>
      </moj-line>
    </div>


    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />




    <div>
      <moj-line>
        <moj-file-upload labelTextKey="אישורי פטירה של זוכים שנפטרו" labelWidthColumns="1" [controlWidthColumns]="4"
          [required]="true" [id]="'fuProof'" isLabelAboveControl="true" [(ngModel)]="filesProof" name="fuProof" show
          [maxFileSize]="23068672" [enabledFileTypes]="'pdf'" designType="fuDesignType.Single">
        </moj-file-upload>
      </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
      <h2 class="font-16 orange2 bold">שמות כל הצאצאים שנולדו לזוכה אי פעם
      </h2>
    </div>
    <section>
      <div style="padding-left:30px;">
        <div>
          <moj-line>
            <div class="col-sm-12">
              <h3 class="font-14" style="float:right;">שורה 1</h3>
              <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
                  style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
            </div>
          </moj-line>
        </div>
        <div>
          <moj-line>
            <moj-dropdownlist required [ngModel]="idZocheTypeSelected" labelWidthColumns="1" [controlWidthColumns]="4"
              name="idZocheTypeSelected" fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="true"
              [items]="idTypes">
            </moj-dropdownlist>
          </moj-line>
        </div>

        <div>
          <moj-line>
            <moj-textbox [required]="true" [identification]='idZocheTypeSelected==1' ngModel name="idNumber"
              labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
              [maxlength]="9">
            </moj-textbox>
            <moj-textbox [required]="true" ngModel name="firstName" [labelTextKey]="'שם פרטי'" [maxlength]="50"
              labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
            </moj-textbox>
            <moj-textbox [required]="true" ngModel name="lastName" [labelTextKey]="'שם משפחה'" [maxlength]="50"
              labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
            </moj-textbox>
          </moj-line>
        </div>
        <div>
          <moj-line>
            <div style="padding-left:30px;">
              <button style="background-color:#B7D3E4;padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
            </div>
          </moj-line>
        </div>
      </div>
    </section>

    <div>
      <moj-line>
        <div style="padding-left:30px;">
          <button style="background-color:#B7D3E4;padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
        </div>
      </moj-line>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

  </div>
</section>