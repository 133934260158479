import { CommonModule, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HttpBackend, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, RouterOutlet } from "@angular/router";
import { GridService, MojCardsModule, MojChatbotComponent, MojDirectiveModule, MojGridModule, MojInputModule, MojSharedModule, MojTranslateLoader, MojWebsiteModule } from "@moj/moj-ng";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { environment } from "../environments/environment";
import { AgrotComponent } from "./agrot/agrot.component";
import { AppComponent } from "./app.component";
import { BakashaComponent } from "./bakasha/bakasha.component";
import { BakashotOutputComponent } from "./bakashot/bakashot-output/bakashot-output.component";
import { BakashotComponent } from "./bakashot/bakashot.component";
import { BdikatBakashaComponent } from "./bdikat-bakasha/bdikat-bakasha.component";
import { FindCourtOrderComponent } from "./court-order/find-court-order.component";
import { RequestCopyComponent } from "./court-order/request-copy/request-copy.component";
import { HomeComponent } from "./home/home.component";
import { CitizenLayoutComponent } from "./layouts/citizen-layout.component";
import { CorpLayoutComponent } from "./layouts/corp-layout.component";
import { AuthGuard } from "./login/auth-guard.service";
import { AuthService } from "./login/auth-service.service";
import { LoginCorpComponent } from "./login/login-corp/login-corp.component";
import { MenuComponent } from "./menu/menu.component";
import { DataStoreService } from "./services/data-store.service";
import { InheritanceDataService } from "./services/inheritance-data.service";
import { TguvotOutputComponent } from "./tguvot/tguvot-output/tguvot-output.component";
import { TguvotComponent } from "./tguvot/tguvot.component";
// import { RequestInterceptor } from "./services/request-interceptor";
import { MojFeedbackModule } from "@moj/moj-feedback";
import { MojConfigService, MojFileUploadModule, MojUtilsService, MojWizardModule } from "@moj/moj-ng";
import { DataViewModule } from "primeng/dataview";
import { AppRoutingModule } from "./app-routing";
import { BakashaLetzavKyumZavaaComponent } from './bakasha-letzav-kyum-zavaa/bakasha-letzav-kyum-zavaa.component';
import { ZavaaTab1Component } from "./bakasha-letzav-kyum-zavaa/wizard-tabs/zavaa-tab1/zavaa-tab1.component";
import { ZavaaTab2Component } from './bakasha-letzav-kyum-zavaa/wizard-tabs/zavaa-tab2/zavaa-tab2.component';
import { ZavaaTab3Component } from './bakasha-letzav-kyum-zavaa/wizard-tabs/zavaa-tab3/zavaa-tab3.component';
import { ZavaaTab4Component } from './bakasha-letzav-kyum-zavaa/wizard-tabs/zavaa-tab4/zavaa-tab4.component';
import { ZavaaTab5Component } from './bakasha-letzav-kyum-zavaa/wizard-tabs/zavaa-tab5/zavaa-tab5.component';
import { ZavaaTab6Component } from './bakasha-letzav-kyum-zavaa/wizard-tabs/zavaa-tab6/zavaa-tab6.component';
import { BakashaLetzavYerushaComponent } from './bakasha-letzav-yerusha/bakasha-letzav-yerusha.component';
import { YerushaTab1Component } from './bakasha-letzav-yerusha/wizard-tabs/yerusha-tab1/yerusha-tab1.component';
import { YerushaTab2Component } from './bakasha-letzav-yerusha/wizard-tabs/yerusha-tab2/yerusha-tab2.component';
import { YerushaTab3Component } from './bakasha-letzav-yerusha/wizard-tabs/yerusha-tab3/yerusha-tab3.component';
import { YerushaTab4Component } from './bakasha-letzav-yerusha/wizard-tabs/yerusha-tab4/yerusha-tab4.component';
import { YerushaTab5Component } from './bakasha-letzav-yerusha/wizard-tabs/yerusha-tab5/yerusha-tab5.component';
import { YerushaTab6Component } from './bakasha-letzav-yerusha/wizard-tabs/yerusha-tab6/yerusha-tab6.component';
import { CouncelRequestOutputComponent } from "./bakashot/councel-request-output/councel-request-output.component";
// import { IconColumnComponent } from "./bakashot/icon-column.component";
// import { GenericPaymentModule } from "@moj/generic-payment/src/app/generic-payment/generic-payment.module";
import { GenericPaymentModule } from "@moj/generic-payment";
import { BdikatBakashaOutputComponent } from './bdikat-bakasha/bdikat-bakasha-output/bdikat-bakasha-output.component';
import { AddNewDebtComponent } from './debts/add-new-debt/add-new-debt.component';
import { DebtsOutputComponent } from './debts/debts-output/debts-output.component';
import { DetailCellRenderer } from "./debts/debts-output/detail-cell-renderer.components";
import { DebtsComponent } from './debts/debts.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginComponent } from "./login/login.component";
import { RequestNotification } from './RequestNotification/RequestNotification.component';
import { BahashotDataService } from "./services/bakashot-data.service";
import { RecaptchaUtilsService } from "./services/recaptcha-utils.service";
import { SiteConfigService } from "./services/SiteConfigService";

@NgModule({
    entryComponents: [
        YerushaTab1Component,
        YerushaTab2Component,
        YerushaTab3Component,
        YerushaTab4Component,
        YerushaTab5Component,
        YerushaTab6Component,

        ZavaaTab1Component,
        ZavaaTab2Component,
        ZavaaTab3Component,
        ZavaaTab4Component,
        ZavaaTab5Component,
        ZavaaTab6Component,

        AddNewDebtComponent, DetailCellRenderer, 

    ],
    declarations: [
        AppComponent,
        BakashotComponent,
        HomeComponent,
        AgrotComponent,
        TguvotComponent,
        TguvotOutputComponent,
        BakashotOutputComponent, 
        BakashaComponent,
        BdikatBakashaComponent,
        FindCourtOrderComponent,
        RequestCopyComponent,
        MenuComponent,
        LoginCorpComponent,
        CitizenLayoutComponent,
        CorpLayoutComponent,
        LoginComponent,
        BdikatBakashaOutputComponent,
        CouncelRequestOutputComponent,
        BakashaLetzavYerushaComponent,
        YerushaTab1Component,
        YerushaTab2Component,
        YerushaTab3Component,
        YerushaTab4Component,
        YerushaTab5Component,
        YerushaTab6Component,
        LandingPageComponent,
        BakashaLetzavKyumZavaaComponent,
        ZavaaTab1Component,
        ZavaaTab2Component,
        ZavaaTab3Component,
        ZavaaTab4Component,
        ZavaaTab5Component,
        ZavaaTab6Component,
        DebtsComponent,
        DebtsOutputComponent,
        AddNewDebtComponent, DetailCellRenderer,      
        RequestNotification
    ],
    imports: [
        BrowserModule,MojChatbotComponent,
        MojFeedbackModule,
        BrowserAnimationsModule,
        MojSharedModule,
        MojInputModule,
        MojWebsiteModule,
        MojGridModule,
        AppRoutingModule, MojDirectiveModule, RouterOutlet,MojCardsModule ,
        HttpClientModule,
        RouterModule, BrowserModule,
        FormsModule,
        CommonModule,
        MojSharedModule,
        MojFileUploadModule,
        GenericPaymentModule,
        DataViewModule,
        MojWizardModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpBackend) => new MojTranslateLoader(http),
                deps: [HttpBackend]
            }
        })
    ],
    bootstrap: [AppComponent],
    providers: [
        MojConfigService,
        SiteConfigService,
        GridService,
        InheritanceDataService,
        TranslateService,
        AuthService,
        AuthGuard,
        BahashotDataService, DataStoreService,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (utils: MojUtilsService) => {
                return () => {
                    return utils.initialize(environment.configFile)
                }
            },
            deps: [MojUtilsService],
            multi: true
        },
        RecaptchaUtilsService,
        // InheritanceDataService,
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: RequestInterceptor,
        //     multi: true,
        // },
        {
            provide: ErrorHandler,
            // useClass: GlobalErrorHandler
        }
    ]
})
export class AppModule {
}
