<moj-line>
    <h2 class="moj-primary-color">{{ requestTexts.searchResults }}</h2>
    <moj-line> </moj-line>
    <div *ngIf="isToManyRecords">
        <div class="font-12 moj-bold">{{ requestTexts.SearchResultsMessage }}</div>
        <moj-line> </moj-line>
    </div>
    <moj-line> </moj-line>

    <div *ngIf="data?.length! < 100">
        <div class="font-12 moj-bold">{{'נמצאו ' + (data?.length || 0) + ' תוצאות התואמות לחיפוש ברשם הירושה '}}</div>
    </div>
    <div class="paddingTop10" id="bakashotGridDiv">
        <moj-grid-panel>
            <ag-grid-angular style="height: 400px" [suppressHorizontalScroll]="true" class="ag-theme-balham"
                [gridOptions]="gridOptions" [rowData]="rowData" [columnDefs]="columns" [pagination]="false"
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </moj-grid-panel>
    </div>
</moj-line>
<moj-line></moj-line>