import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ButtonStyle, DialogResult, DialogResultEnum, FontSize, FontWeight, MessageType, MojColor, MojLoadingService, MojMessagesService, MojRecaptchaService, ValidationPatterns } from '@moj/moj-ng';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Client } from '../../models/client-data.model';
import { RecaptchaModel } from '../../models/recaptcha-model';
import { DataStoreService } from '../../services/data-store.service';
import { InheritanceDataService } from '../../services/inheritance-data.service';
import { SiteConfigService } from '../../services/SiteConfigService';

@Component({
  selector: 'app-add-new-debt',
  templateUrl: './add-new-debt.component.html',
  styleUrls: ['./add-new-debt.component.css']
})
export class AddNewDebtComponent implements OnInit {

  @ViewChild('form', { static: false }) formModel!: NgForm;
  @ViewChild("pnl", { read: ViewContainerRef, static: false }) pnl!: ViewContainerRef;

  @Input() data: any; // Getting data from the opener (DialogComponent)
  @Output() closeDialog: EventEmitter<DialogResult> = new EventEmitter<DialogResult>();


  buttonStyle = ButtonStyle;
  fontSize = FontSize;
  fontWeight = FontWeight;
  mojColor = MojColor;

  deceasedText;
  autorityName: any;
  localAuthorityId: any;
  maxDate: Date = new Date();

  isTz = true;
  isSubmit: boolean = false;
  isPersonalDataOk!: boolean;
  request = <any>{};
  model?: RecaptchaModel ;
  phonePattern = ValidationPatterns.PhonePattern;

  constructor(private dataService: InheritanceDataService,
    public dataStoreService: DataStoreService,
    private translateService: TranslateService,
    private loadingService: MojLoadingService,
    private recaptchaService: MojRecaptchaService,
    private messagesService: MojMessagesService,
    private configService: SiteConfigService

  ) {
    this.deceasedText = this.translateService.instant('Texts.deceasedText');

  }

  ngOnInit() {
    this.getAuthorityName();
  }

  resetData() {
    this.getAuthorityName();
  }

  getAuthorityName() {
    this.dataService.getAuthorityName().subscribe(res => {
      if (res)
        this.autorityName = res
    })
  }

  onSubmit() {
    this.loadingService.show(this.pnl);
    this.isSubmit = true;
    this.model = new RecaptchaModel();
    if ((<any>this.configService.configuration).isRecaptchaOn) {
      this.recaptchaService.executeRecaptcha().subscribe((res) => {
        this.model!.recaptchaStringTz = res;
        this.checkPersonalDataExist()
      })
    }
    else {
      this.model!.recaptchaStringTz = '';
      this.checkPersonalDataExist()
    }
  }
  checkPersonalDataExist() {
    this.isPersonalDataExists().pipe(take(1)).subscribe(
      (res) => {
        if (res)
          this.checkAndSubmitForm();
        else
          this.loadingService.hide();
      },
      (err) => this.loadingService.hide())
  }

  isPersonalDataExists(): Observable<boolean> {

    // check the personal details with aviv
    let clientData = new Client(<any>this.formModel);

    if (!this.isTz) return of(true); // במקרה של דרכון אין בדיקה מול אביב

    return this.dataService.isPersonalInDead(clientData, this.model!.recaptchaStringTz).pipe(
      map((res: boolean) => {
        if (res) return true;

        this.messagesService.showMessage({ messageText: "הפריטים שהזנת אינם תקינים נא בדיקתך שוב.", messageTitle: this.deceasedText.Message, messageType: MessageType.Message })
        this.isSubmit = false;
        return false;
      },
        (err: any) => {
          this.isSubmit = false;
          return false;
        }
      )
    )
  }

  checkAndSubmitForm() {
    this.isPersonalDataOk = true;
    let f = this.formModel.value;
    if (!this.formModel.valid) return;
    this.request = f;
    this.request.claimToAuthorityCollection = f.authorityCollection;
    this.saveDebtForm();
  }

  saveDebtForm() {
    this.dataService.saveDebtForm(this.request)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.loadingService.hide();
        if (!res.ok) {
          this.messagesService.showMessage({ messageText: res.message, messageTitle: this.deceasedText.Message, messageType: MessageType.Message })
          this.isSubmit = false;
        }
        else {
          this.closeDialog.emit({ dialogResultType: DialogResultEnum.OK, data: { ok: true } });
          this.messagesService.showMessage({ messageText: res.message, messageTitle: this.deceasedText.Message, messageType: MessageType.Message })
        }
      },
        (error) => this.loadingService.hide());
  }
}
