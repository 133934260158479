<div class="flex-container">
    <div class="div1">
        <moj-line>
            <h1 class="font-28 moj-bold moj-primary-color">
                {{ homeTexts.header }}
            </h1>
        </moj-line>
        <moj-line>
            <h4>{{homeTexts.headerMessage}}</h4>
        </moj-line>
        <moj-line>
            <ul>
                <li> {{ homeTexts.bullet1 }} </li>
                <li> {{ homeTexts.bullet2 }} </li>
                <li> {{ homeTexts.bullet3 }} </li>
                <li> {{ homeTexts.bullet4 }} </li>
            </ul>
        </moj-line>
    </div>
    <div class="div2">
        <moj-line>
            <h4>{{homeTexts.secondHeaderMessage}}</h4>
        </moj-line>
        <moj-line>
            <ul>
                <li> {{ homeTexts.bullet5 }} </li>
                <li> {{ homeTexts.bullet6 }} </li>
                <li> {{ homeTexts.bullet7 }} </li>
                <li> {{ homeTexts.bullet8 }} </li>
            </ul>
        </moj-line>
        <moj-line>
            <moj-design-line [widthColumns]="12" mojColor="mojColor.lightBlue"></moj-design-line>
        </moj-line>
        <moj-line>
            <div> {{ homeTexts.howTo1 }}
                <a style="text-decoration:underline;color:#026e8a;" [routerLink]="['/bakashot']">{{
                    homeTexts.searchRequests}}</a>
                {{ homeTexts.howTo2 }}
            </div>
        </moj-line>
        <moj-line>
            <div *ngIf="dateUpdated">
                {{ homeTexts.bodyText2 }} &nbsp; | &nbsp; {{ homeTexts.lastUpdate }}: {{ dateUpdated | date:
                'dd/MM/yyyy'}}
            </div>
        </moj-line>
        <moj-line>
            <moj-design-line [widthColumns]="12" mojColor="mojColor.lightBlue"></moj-design-line>
        </moj-line>
        <moj-line>
            <a href="https://www.gov.il/he/service/inheritance_order" target="_blank"
                style="text-decoration:underline;color:#026e8a;">{{ homeTexts.bottomText1 }}</a>
        </moj-line>
        <moj-line>
            <a href="https://www.gov.il/he/service/probate_order" target="_blank"
                style="text-decoration:underline;color:#026e8a;">{{ homeTexts.bottomText2 }}</a>
        </moj-line>
    </div>
    <div class="contact-us">
        <!-- <moj-line>
            <h4 class="moj-bold moj-primary-color">
                {{ homeTexts.contactUsTitle1 }} <br>
                {{ homeTexts.contactUsTitle2 }}
            </h4>
        </moj-line> -->
        <moj-line>
            <h4 class="moj-bold moj-primary-color"> {{ homeTexts.contactUsSubTitle }}
            </h4>
        </moj-line>
        <moj-line>
            <h3>
                <a dir="ltr" [href]="'tel:' + homeTexts.phoneNumber"> {{ homeTexts.phoneNumber }} </a>
            </h3>
        </moj-line>

        <h5>
            <moj-line>
                {{ homeTexts.phone }}: <br>
                {{ homeTexts.abroadPhone }} -
                <a dir="ltr" [href]="'tel:' + homeTexts.abroadNumber">{{ homeTexts.abroadNumber }}</a> <br>

                {{homeTexts.serviceHouers}} - <strong>{{homeTexts.extension}}</strong><br>

                {{homeTexts.service24}} - <strong>{{homeTexts.extension24}}</strong>
            </moj-line>
            <moj-line>{{ homeTexts.mail }}:
                <a [href]="'mailto:' + homeTexts.mailAddress ">{{ homeTexts.mailAddress }}</a>
            </moj-line>
        </h5>
        <moj-line class="more-info">
            <h4 class="moj-bold moj-primary-color">{{homeTexts.moreInfo}}
            </h4>
            <h5>{{ homeTexts.moreInfoContact }}
            </h5>
        </moj-line>
    </div>
</div>