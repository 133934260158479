<div id="requestCopy" #pnl *ngIf="sendRequest">
  <form #f="ngForm">
    <moj-line>
      <moj-line
        *ngIf="isShowHeaderRequestsCombo || sugBakasha == SugBakashaEnum.etekTzav || sugBakasha == SugBakashaEnum.etekTzavApostille"
        class="container-fluid">
        <div class="font-20 moj-orange-color moj-bold">{{requestTexts.attention}}
        </div>
      </moj-line>
      <moj-line>
        <div *ngIf="!isShowHeaderRequestsCombo" class="col-md-6 moj-primary-color font-24 moj-bold">{{ requestHeader }}
        </div>
        <div *ngIf="isShowHeaderRequestsCombo" class="col-md-6 text-right paddingBottom5 paddingRight0">
          <div class="font-24 moj-primary-color moj-bold col-md-6">בקשה להגשה בתיק</div>
        </div>
        <div class="col-md-6 text-left paddingLeft30">
          <label class="font-18 moj-primary-color moj-bold">{{today | date:'dd/MM/yyyy'}}</label>
        </div>
      </moj-line>

      <moj-line *ngIf="isShowHeaderRequestsCombo">
        <moj-line>
          <moj-dropdownlist (onChange)="onChangeSugNewBakasha(sugNewBakashaValue.value)" required #sugNewBakashaValue
            [(ngModel)]="sugNewBakasha" [controlWidthColumns]="3" name="sugNewBakasha" fieldID="id" fieldName="value"
            labelTextKey="סוג הבקשה" [items]="sugNewBakashaList">
          </moj-dropdownlist>
          <div class="info-tooltip col-sm-5 marginRight5" role="alert"
            *ngIf="agraFee!=undefined && agraFee!=0 && (!isPtor || !isShowPtorPanel)">
            {{ priceTooltip }}
          </div>
        </moj-line>
        <moj-line *ngIf="agraFee!=undefined && agraFee!==0 && !isCreditCard && (!isPtor || !isShowPtorPanel)">
          <div class="font-12 bold line-flex-right col-md-7">
            <div style="color: #A20000;padding-left:4px;padding-top:2px;float: right;"
              class="fas fa-exclamation-triangle"></div>
            <div style="float: right;">
              {{ attention }}
              <a class="bold" (click)="openWindows()"
                style="padding-left: 4px;text-decoration: underline;cursor: pointer;color:#2072D7;">לתשלום באתר
                התשלומים.</a>
              <br>
              {{ attentionEnd }}
            </div>
          </div>
        </moj-line>
      </moj-line>
    </moj-line>
    <div id="layout-content" class="container-fluid">
      <div>
        <div role="search">
          <!-- <div class="col-md-6 font-16 bold paddingTop10" *ngIf="agraFee!=0 && (!isPtor || !isShowPtorPanel)">{{ attention }}</div> -->
          <moj-line class="paddingTop10">
            <h4 class="font-20 moj-orange-color moj-bold">פרטי המבקש</h4>
          </moj-line>
          <moj-line>
            <div class="col-md-12 paddingX0">
              <div class="col-sm-2 paddingLeft0" style="width: auto;">
                <moj-radiobutton [(ngModel)]="isTz" [radiovalue]="true" [name]="'optionId'"
                  [labelTextKey]="'תעודת זהות ישראלית'">
                </moj-radiobutton>
              </div>
              <div *ngIf="isTz" class="font-12 isTzLabel paddingX0 col">
                (יש להזין את השם בהתאם למופיע בתעודת הזהות)
              </div>
              <div class="col-sm-2">
                <moj-radiobutton [(ngModel)]="isTz" [radiovalue]="false" [name]="'optionId'" [labelTextKey]="'דרכון'">
                </moj-radiobutton>
              </div>
            </div>
          </moj-line>
          <moj-line *ngIf="!isTz">
            <moj-autocomplete *ngIf="dataStoreService.countries$ | async as countriesItems" required name="countryId"
              [placeholder]="'בחר'" [labelTextKey]="'ארץ הנפקת דרכון'" labelWidthColumns="2" [controlWidthColumns]="4"
              [items]="countriesItems" fieldName="value" fieldID="id" [dropdown]="true" isLabelAboveControl="true"
              [(ngModel)]="countries">
            </moj-autocomplete>
          </moj-line>
          <moj-line>
            <moj-textbox [required]="true" [identification]='isTz' ngModel name="idNumber"
              [labelTextKey]="ToggleIdLabel()" labelWidthColumns="2" [controlWidthColumns]="4"
              isLabelAboveControl="true" [maxlength]="9">
            </moj-textbox>
            <moj-textbox [required]="true" pattern="^[A-Za-zא-ת\.\-\s\,\']*$" ngModel name="firstName"
              [labelTextKey]="'שם פרטי'" [maxlength]=50 labelWidthColumns="2" [controlWidthColumns]="4"
              isLabelAboveControl="true">
            </moj-textbox>
            <moj-textbox [required]="true" pattern="^[A-Za-zא-ת\.\-\s\,\']*$" ngModel name="lastName"
              [labelTextKey]="'שם משפחה'" [maxlength]=50 labelWidthColumns="2" [controlWidthColumns]="4"
              isLabelAboveControl="true">
            </moj-textbox>
          </moj-line>
          <moj-line>
            <moj-textbox ngModel (keyup)="onEmailorPhoneChanged($event)" name="phone" [required]="isPhoneRequired"
              pattern="^\d+$" [maxlength]=10 minlength="9" [labelTextKey]="'טלפון נייד'" labelWidthColumns="2"
              [controlWidthColumns]=4 isLabelAboveControl="true">
            </moj-textbox>
            <moj-textbox ngModel (keyup)="onEmailorPhoneChanged($event)" email [required]="isEmailRequired" name="email"
              [maxlength]="50" [labelTextKey]="emailLabelText" labelWidthColumns="2" [controlWidthColumns]="4"
              isLabelAboveControl="true">
            </moj-textbox>
            <moj-textbox *ngIf="isShowVerifyEmailField" ngModel email name="emailVerify"
              [labelTextKey]="'דואר אלקטרוני - הקלד שנית לאימות'" labelWidthColumns="2" [controlWidthColumns]="4"
              isLabelAboveControl="true" (paste)="false">
            </moj-textbox>
          </moj-line>
        </div>
        <moj-line></moj-line>
        <hr class="split"
          *ngIf="(sugBakasha != SugBakashaEnum.BakashaChadasha) && (sugBakasha != SugBakashaEnum.etekTzav) && (sugBakasha != SugBakashaEnum.etekTzavApostille)">
        <div
          *ngIf="(sugBakasha != SugBakashaEnum.BakashaChadasha) && (sugBakasha != SugBakashaEnum.etekTzav) && (sugBakasha != SugBakashaEnum.etekTzavApostille)">
          <div class="font-20 moj-bold moj-orange-color">צירוף מסמכים</div>
        </div>
        <div class="col-md-7" *ngIf="sugBakasha == SugBakashaEnum.HashlamatMismachimMazkirut">
          <div>עליך לצרף מסמכים בהתאם להנחיות הבאות:</div>
          <ul style="list-style-type: disc;padding-inline-start: 40px;">
            <li *ngFor="let f of FaultyDocumentsRemarks">{{ f }}</li>
          </ul>
        </div>
        <div class="col-md-7 paddingTop5"
          *ngIf="sugBakasha != SugBakashaEnum.HashlamatMismachimMazkirut && (sugBakasha != SugBakashaEnum.BakashaChadasha) && (sugBakasha != SugBakashaEnum.etekTzav) && (sugBakasha != SugBakashaEnum.etekTzavApostille)">
          <div><span style="color:#C90101">*</span>{{ documentLabelText }}</div>
        </div>
        <div class="col-md-7 paddingTop5 font-14 line-flex-right"
          *ngIf="sugBakasha != SugBakashaEnum.HashlamatMismachimMazkirut && (sugBakasha != SugBakashaEnum.BakashaChadasha) && (sugBakasha != SugBakashaEnum.etekTzav) && (sugBakasha != SugBakashaEnum.etekTzavApostille)">
          <span class="fuLabel fas fa-exclamation-triangle"></span>
          <span>
            במקרה בו קיימים כמה מסמכים, עלייך לאחד ולצרף אותם כמסמך סרוק אחד
          </span>
        </div>
        <div class="col-md-6 paddingTop5"
          *ngIf="(sugBakasha != SugBakashaEnum.BakashaChadasha) && (sugBakasha != SugBakashaEnum.etekTzav) && (sugBakasha != SugBakashaEnum.etekTzavApostille)">
          <!-- <label for="fuDocument" style="white-space: pre-wrap;">{{  }}</label> -->
          <moj-file-upload isLabelAboveControl="true" [required]="true" [id]="'fuDocument'" [(ngModel)]="files"
            name="fu" show [maxFileSize]=23068672 [enabledFileTypes]="'pdf'"
            (fileUploadComplete)="fileUploadComplete($event)" designType="fuDesignType.Single" [multiple]="false">
          </moj-file-upload>
        </div>
        <hr *ngIf="isShowLawerPanel" class="col-md-12 split">
        <moj-line *ngIf="isShowLawerPanel">
          <moj-line></moj-line>
          <div class="col-sm-4 paddingX0 font-16 moj-primary-color moj-bold">האם הבקשה מיוצגת על ידי עורך דין?</div>
          <div class="col-sm-8">
            <div class="col-sm-2">
              <moj-radiobutton [(ngModel)]="isLawyer" [radiovalue]="true" [name]="'lawyerId'" [labelTextKey]="'כן'">
              </moj-radiobutton>
            </div>
            <div class="col-sm-2">
              <moj-radiobutton [(ngModel)]="isLawyer" [radiovalue]="false" [name]="'lawyerId'" [labelTextKey]="'לא'">
              </moj-radiobutton>
            </div>
          </div>
        </moj-line>
        <div *ngIf="isLawyer">
          <moj-line>
            <div class="font-20 moj-bold moj-orange-color">פרטי עו"ד</div>
          </moj-line>
          <div class="paddintTop10">
            <moj-line>
              <moj-textbox [customValidationErrors]="[{ key:'pattern', value:'digitsPattern'}]" [required]="true"
                (blur)="onBlurLicenseNumber($event)" [(ngModel)]="licenseNumber" pattern="^\d+$" name="licenseNumber"
                [labelTextKey]="'מספר רישיון'" labelWidthColumns="2" [controlWidthColumns]="4"
                isLabelAboveControl="true" [maxlength]="9">
              </moj-textbox>
              <moj-textbox [required]="true" pattern="^[A-Za-zא-ת\.\-\s\,\']*$" [(ngModel)]="advocateFirstName"
                name="advocateFirstName" [labelTextKey]="'שם פרטי'" [readOnly]="true" labelWidthColumns="2"
                [controlWidthColumns]="4" isLabelAboveControl="true">
              </moj-textbox>
              <moj-textbox [required]="true" pattern="^[A-Za-zא-ת\.\-\s\,\']*$" [(ngModel)]="advocateLastName"
                name="advocateLastName" [labelTextKey]="'שם משפחה'" [readOnly]="true" labelWidthColumns="2"
                [controlWidthColumns]="4" isLabelAboveControl="true">
              </moj-textbox>
            </moj-line>
            <moj-line>
              <moj-textbox [required]="true" [(ngModel)]="advocatePhone" name="advocatePhone" pattern="^\d+$"
                [maxlength]="10" minlength="9" [labelTextKey]="'טלפון נייד'" [readOnly]="!isLicenseNumberOk"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
              </moj-textbox>
              <moj-textbox [required]="true" [(ngModel)]="advocateEmail" email [maxlength]="50" name="advocateEmail"
                [labelTextKey]="'דואר אלקטרוני'" [readOnly]="!isLicenseNumberOk" labelWidthColumns="2"
                [controlWidthColumns]="4" isLabelAboveControl="true">
              </moj-textbox>
            </moj-line>
          </div>
        </div>
        <moj-line class="paddingTop15" *ngIf="isShowPtorPanel">
          <div class="col-xs-4 font-16 paddingX0 moj-primary-color moj-bold">האם הינך זכאי לפטור מתשלום?</div>
          <div class="col-xs-8">
            <div class="col-xs-2">
              <moj-radiobutton [(ngModel)]="isPtor" [radiovalue]="true" [name]="'ptor'" [labelTextKey]="'כן'">
              </moj-radiobutton>
            </div>
            <div class="col-xs-2">
              <moj-radiobutton [(ngModel)]="isPtor" [radiovalue]="false" [name]="'ptor'" [labelTextKey]="'לא'">
              </moj-radiobutton>
            </div>
          </div>
          <moj-line>
            <div class="font-16 bold" *ngIf="isPtor">{{ ptorMessage }}</div>
          </moj-line>
        </moj-line>

        <div *ngIf="sugBakasha == SugBakashaEnum.etekTzav || sugBakasha == SugBakashaEnum.etekTzavApostille">
          <hr class="col-md-12 split">
          <moj-line class="paddingTop10">
            <h4 class="font-20 moj-orange-color moj-bold">סוג חיתום</h4>
          </moj-line>
          <moj-line>
            <div class="col-md-12 paddingX0">
              <div class="col-sm-2 paddingLeft0" style="width: auto;">
                <moj-radiobutton [(ngModel)]="isApostilleSignature" [radiovalue]="false" #etkZav
                  (checkedChange)="changeSugBakagaToApostille(isApostilleSignature,etkZav.labelTextKey)"
                  [name]="'signatureType'" [labelTextKey]="'העתק צו חתום דיגיטאלית'">
                </moj-radiobutton>
              </div>
              <div class="col-sm-6">
                <moj-radiobutton [(ngModel)]="isApostilleSignature" [radiovalue]="true" #apostille
                  (checkedChange)="changeSugBakagaToApostille(isApostilleSignature, apostille.labelTextKey)"
                  [name]="'signatureType'" [labelTextKey]="requestTexts.apostilleSignature">
                </moj-radiobutton>
              </div>
            </div>
          </moj-line>
          <moj-line *ngIf="isApostilleSignature">
            <moj-autocomplete required *ngIf="dataStoreService.apostilleCountries$ | async as apostilleCountriesItems "
              name="apostilleCountryId" [placeholder]="'בחר'" (onSelect)="selectApostille($event)"
              [labelTextKey]="'המדינה עבורה יונפק האפוסטיל'" labelWidthColumns="2" [controlWidthColumns]="4"
              [items]="apostilleCountriesItems" fieldName="hebrewName" fieldID="id" [dropdown]="true"
              isLabelAboveControl="true" [(ngModel)]="apostilleCountry">
            </moj-autocomplete>


            <div class="col-md-5 paddingX0">
              <div class="info-tooltip col-md-12" role="alert"
                *ngIf="apostilleCountry && !apostilleCountry?.convention">
                <moj-notification [message]="requestTexts.apostilleAlert" [iconClass]="'fas fa-exclamation-triangle'"
                  [type]="mojNotificationType.Warning">
                </moj-notification>
                <a href="https://www.gov.il/he/Departments/General/document_verification" target="_blank"
                  style="text-decoration:underline;color:#026e8a;">לחצו כאן למידע נוסף בנוגע לאימות מסמכים במשרד
                  החוץ</a>
              </div>
            </div>


          </moj-line>
        </div>

        <div
          *ngIf="(sugBakasha != SugBakashaEnum.BakashaChadasha) &&  ((isShowPtorPanel && !isPtor) || (agraFee!=0 && (!isPtor || !isShowPtorPanel)))">
          <hr class="col-md-12 split">
          <moj-line>
            <h2 class="font-20 moj-bold moj-orange-color">תשלום אגרה עבור הבקשה</h2>
          </moj-line>
          <moj-line>
            <div>
              <moj-radiobutton [(ngModel)]="isCreditCard" [radiovalue]="true" name="paymentType"
                [labelTextKey]="' בכרטיס אשראי - מאובטח'">
              </moj-radiobutton>
            </div>
          </moj-line>
          <moj-line>
            <div>
              <moj-radiobutton [(ngModel)]="isCreditCard" [radiovalue]="false" name="paymentType"
                [labelTextKey]="'באמצעות אסמכתא'">
              </moj-radiobutton>
            </div>
            <moj-line *ngIf="!isCreditCard" style="margin-top:0 !important;">
              <div class="col-md-6 font-12">
                <span class="font-14 moj-bold">יש להזין את מספר האסמכתא שהתקבל בזמן התשלום </span>
                <a class="moj-bold" (click)="openWindows()"
                  style="padding-left: 4px;text-decoration: underline;cursor: pointer;color:#2072D7;">באתר
                  התשלומים</a>
                <!-- <span class="bold">{{ asmachtaMessage }}</span> -->
                <!-- <div>
                                <span class="bold">לאחר התשלום יש להזין את מספר האסמכתא שתקבל.</span>
                            </div> -->
              </div>
            </moj-line>
          </moj-line>
          <moj-line *ngIf="!isCreditCard">
            <moj-textbox [required]="true" class="paddingX0" ngModel id="asmachta" name="asmachta"
              [labelTextKey]="'מספר אסמכתא לתשלום'" labelWidthColumns="2" [controlWidthColumns]="4" pattern="^\d+$"
              [maxlength]="10" isLabelAboveControl="true">
            </moj-textbox>
            <div class="col-md-5 paddingX0">
              <div class="info-tooltip col-md-12" role="alert"
                *ngIf="agraFee!=undefined && agraFee!=0 && (!isPtor || !isShowPtorPanel)">
                {{ priceTooltip }}
              </div>
            </div>
          </moj-line>
        </div>
        <moj-line></moj-line>
        <moj-buttons-line [isInDialogFooter]="false">

          <moj-submit-button id="requestCopyBtn" [buttonStyle]="buttonStyle.Primary" [form]="f" (onSubmit)="onSubmit()"
            [textKey]="(sugBakasha != SugBakashaEnum.BakashaChadasha) && agraFee!=0 && isCreditCard && (!isPtor || !isShowPtorPanel)?'לתשלום מאובטח':'שליחת טופס'"
            [isEnable]="f.valid!">
          </moj-submit-button>
        </moj-buttons-line>
      </div>
    </div>
  </form>
</div>
<iframe name="payment-iframe" width="100%" height="300" frameBorder="0" *ngIf="!sendRequest"></iframe>