import {
  AfterViewInit, Component,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { ButtonStyle, DialogData, DialogSize, FontSize, FontWeight, GridNoResultOptions, GridService, MessageType, MojColor, MojDialogService, MojLoadingService, MojMessagesService, NoResultOptions, PanelStyle, TooltipAction } from "@moj/moj-ng";
import { ColDef, GridApi, GridOptions } from "ag-grid-community";
import { isEmpty } from "lodash";
import { Observable } from "rxjs";
import { finalize, first, map, switchMap } from "rxjs/operators";
import { TranslateService } from "../../../node_modules/@ngx-translate/core";
import { RequestCopyComponent } from "../court-order/request-copy/request-copy.component";
import { BakashaButtonsLogicResponse } from "../models/bakasha-buttons-logic.model";
import {
  BakashaExtended,
  BakashaResponse,
  Hachlata,
  Mevakesh,
  Tguva
} from "../models/bakasha.model";
import { DictItem } from "../models/dict-item.model";
import { SugBakashaEnum } from "../models/sug-bakasha-enum";
import { DataStoreService } from "../services/data-store.service";
import { InheritanceDataService } from "../services/inheritance-data.service";

@Component({
  selector: "bakasha",
  templateUrl: "./bakasha.component.html",
  styleUrls: ["./bakasha.component.css"],
})
export class BakashaComponent implements AfterViewInit {
  @ViewChild("pnl", { read: ViewContainerRef, static: true })
  pnl!: ViewContainerRef;
  mevakshimData!: Mevakesh[];
  tguvotData!: Tguva[];
  hachlatotData!: Hachlata[];
  bakashaData!: BakashaExtended;
  data!: BakashaResponse;
  mevakshimColumns!: ColDef[];
  tguvotColumns!: ColDef[];
  hachlatotColumns!: ColDef[];

  buttonStyle = ButtonStyle;
  fontSize = FontSize;
  fontWeight = FontWeight;
  mojColor = MojColor;
  panelStyle = PanelStyle;

  gridMevakshimApi!: GridApi;
  gridTguvotApi!: GridApi;
  gridHachlatotApi!: GridApi;

  gridOptions!: GridOptions;

  misparTik: any;
  kodMachoz: any;
  kodBakasha: any;

  requestTexts;

  actionList: TooltipAction[] = [];

  isBakashaRequestEnabled = true;
  logicResponse = {} as BakashaButtonsLogicResponse;

  SugBakashaEnum = SugBakashaEnum;

  tooltip!: string;
  betMishpatName$!: Observable<DictItem | undefined>;
  misparTikBetMishpat!: string;
  noResultOptions:NoResultOptions = new GridNoResultOptions();
  imgs = new Array();

  constructor(
    private dataService: InheritanceDataService,
    private messagesService: MojMessagesService,
    private gridService: GridService,
    private loadingService: MojLoadingService,
    private translateService: TranslateService,
    private dataStore: DataStoreService,
    private dialogService: MojDialogService
  ) {
    this.requestTexts = this.translateService.instant("Texts.requestTexts");
    this.noResultOptions.title=this.requestTexts.noRowsToShow;

    dataStore.bakashaParams$
      .pipe(
        first((value) => !isEmpty(value)),
        switchMap((params) => {
          this.misparTik = params["misparTik"];
          this.kodBakasha = params["kodBakasha"];
          this.kodMachoz = params["kodMachoz"];
          return this.dataService.getBakasha(this.kodBakasha).pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          );
        })
      )
      .subscribe((bakasha) => {
        this.data = bakasha;
        this.bakashaData = this.data.bakashaEx;
        if (
          this.bakashaData.shanb &&
          this.bakashaData.misTil > 0 &&
          this.bakashaData.misTikChodeshBetMishpat > 0
        ) {
          this.betMishpatName$ = this.findBetMishpat(
            this.bakashaData.betMishpat
          );
          this.misparTikBetMishpat = //( this.bakashaData.shanb && this.bakashaData.shanb.trim() != "" ) ?
            `${this.bakashaData.misTil}-` +
            `${this.bakashaData.misTikChodeshBetMishpat}-` +
            `${this.bakashaData.shanb}`; //: null
        }

        this.tooltip = `לא ניתן להגיש את הבקשה עבור תיק מספר ${this.bakashaData.bakashaBasic.misparTik}. לברור ניתן לפנות למרכז שרות 2416*`;
        this.mevakshimData = this.data.mevakeshim;
        this.tguvotData = this.data.tguvot;
        this.hachlatotData = this.data.hachlatot;
      });
  }

  pload(args: any[]): void {
    for (var i = 0; i < args.length; i++) {
      this.imgs[i] = new Image();
      this.imgs[i].src = args[i];
    }
  }

  ngOnInit() {
    this.pload([
      "./assets/actionIcons/new/Icn_TzavNorm.png",
      "./assets/actionIcons/new/Icn_TzavHover.png",
      "assets/actionIcons/new/Icn_TzavDis.png",
      "./assets/actionIcons/new/Icn_tgouvaNorm.png",
      "./assets/actionIcons/new/Icn_tgouvaHover.png",
      "./assets/actionIcons/new/Icn_tgouvaDis.png",
      "./assets/actionIcons/new/Icn_MazkiroutNorm.png",
      "./assets/actionIcons/new/Icn_MazkiroutHover.png",
      "assets/actionIcons/new/Icn_MazkiroutDis.png",
      "assets/actionIcons/new/Icn_BaKoachNorm.png",
      "assets/actionIcons/new/Icn_BaKoachHover.png",
      "assets/actionIcons/new/Icn_BaKoachDis.png",
      "assets/actionIcons/new/Icn_OzerNorm.png",
      "assets/actionIcons/new/Icn_OzerHover.png",
      "assets/actionIcons/new/Icn_OzerDis.png",
      "assets/actionIcons/new/Icn_HitnagdoutNorm.png",
      "assets/actionIcons/new/Icn_HitnagdoutHover.png",
      "assets/actionIcons/new/Icn_HitnagdoutDis.png",
      "assets/actionIcons/new/Icn_BakashaNorm.png",
      "assets/actionIcons/new/Icn_BakashaHover.png",
    ]);

    this.gridOptions = this.gridService.getMojGridOptions();
    // this.gridOptions.domLayout = 'autoHeight';

    this.initColumns();

    this.loadingService.show(this.pnl);

    this.dataService
      .getBakashaButtonsLogic(this.kodBakasha)
      .subscribe((response) => {
        this.logicResponse = response;
      });
  }

  public openContentDialog(sugBakasha: string) {
    const dialogData: DialogData = {
      title: "טופס בקשה",
      componentInputData: {
        sugBakasha,
        misparTik: this.misparTik,
        kodBakasha: this.kodBakasha,
        kodMachoz: this.kodMachoz,
        taarichSgira: this.bakashaData.taarichSgira,
        logicResponse: this.logicResponse
      },
      dialogSize: DialogSize.Large,
      isAddFooter: false,
      preventScroll: true, closable: true
    };
    this.dialogService.openDialog(dialogData, RequestCopyComponent);
    this.dialogService.dialogResult.subscribe((res) => {
      const obj = JSON.parse(JSON.stringify(res));

      // if (obj.data && obj.data.ok) {
      //   // באג בדיאלוג מקבל איוונטים גם כשלא נסגר
      //   // let msg = "בקשתך נשלחה למשרדי הרשם לענייני ירושה, תוכל לצפות בבקשה באתר בעוד כ24 שעות בכל בעיה ניתן לפנות למוקד שירות *2416  ..."
      //   // this.messagesService.showMessage(msg,this.requestTexts.Message,null, MessageType.Message);
      // }
    });
  }
  actions: any = [
    {
      name: 'הגשת בקשה בתיק',
      iconClass: 'apply-request',
      onClick: () => {
        this.onClickAppealButton(SugBakashaEnum.BakashaChadasha)
      },
    },
    {

      name: 'קבלת העתק צו ואפוסטיל דיגיטלי',
      iconClass: 'view-court-order',
      disabled: this.logicResponse.isViewCourtOrder,
      onClick: () => {
        this.logicResponse.isViewCourtOrder && this.onClickAppealButton(SugBakashaEnum.etekTzav)
      },
    }

  ];
  //* טיפול במתודות של כפתורי הבקשות
  showMessage() {
    this.messagesService.showMessage({
      messageText:
        `לא ניתן להגיש את הבקשה עבור תיק מספר ${this.bakashaData.bakashaBasic.misparTik}.
          לברור ניתן לפנות למרכז שרות 2416*`,
      messageTitle: this.requestTexts.Message,
      messageType: MessageType.Message
    });
  }

  onClickAppealButton(sugBakasha: any) {
    switch (sugBakasha) {
      case SugBakashaEnum.Itnagdut: {
        if (!this.logicResponse.isApplyObjection) {
          this.showMessage();
          return;
        }
        break;
      }
      case SugBakashaEnum.etekTzav: {
        if (!this.logicResponse.isViewCourtOrder) {
          this.showMessage();
          return;
        }
        break;
      }
      case SugBakashaEnum.BakashaChadasha: {
        break;
      }
      case SugBakashaEnum.TguvaLehachlatatRasham: {
        if (!this.logicResponse.isResponseDecision) {
          this.showMessage();
          return;
        }
        break;
      }
      case SugBakashaEnum.HashlamatMismachimMazkirut: {
        if (!this.logicResponse.isCompletionDocsSecretariat) {
          this.showMessage();
          return;
        }
        break;
      }
      case SugBakashaEnum.HashlamatMismachimYoetz: {
        if (!this.logicResponse.isCompletionDocsLegalAdviser) {
          this.showMessage();
          return;
        }
        break;
      }
      case SugBakashaEnum.HashlamatMismachimOzerRasham: {
        if (!this.logicResponse.isCompletionDocsOzerRasham) {
          this.showMessage();
          return;
        }
        break;
      }
    }
    this.openContentDialog(sugBakasha);
  }
  //**********************************

  onMevakshimGridReady(params: any) {
    this.gridMevakshimApi = params.api;
  }

  onTguvotGridReady(params: any) {
    this.gridTguvotApi = params.api;
  }

  onHachlatotGridReady(params: any) {
    this.gridHachlatotApi = params.api;
  }

  initColumns() {
    this.mevakshimColumns = [
      this.gridService.getMojColumn("shemMevakesh", {
        colDef: { headerName: "requestTexts.submitter" },
      }),
      this.gridService.getMojColumn("shemMeyatzeg", {
        colDef: { headerName: "requestTexts.representor" },
      }),
      // this.gridService.getMojColumn("ktovetMeyatzeg", {
      //   colDef: { headerName: "requestTexts.representorAddress" },
      // }),
      this.gridService.getMojColumn("telMeyatzeg", {
        colDef: { headerName: "requestTexts.representorTel" },
      }),
    ];
    this.tguvotColumns = [
      this.gridService.getMojColumn("shemSugTguva", {
        colDef: { headerName: "requestTexts.responseType" },
      }),
      this.gridService.getMojDateColumn("taarichMatanTguva", {
        colDef: { headerName: "requestTexts.responseDate" },
      }),
    ];
    this.hachlatotColumns = [
      this.gridService.getMojColumn("shemHachlata", {
        colDef: { headerName: "requestTexts.resolution" },
      }),
      this.gridService.getMojDateColumn("taarichHachlata", {
        colDef: { headerName: "requestTexts.resolutionDate" },
      }),
    ];
  }

  ngAfterViewInit() {
    /*if (!(<any>this.configService.configuration).isRecaptchaOn) return;

    this.recaptchaService.recaptchaReady.subscribe((res) => {
      if(res){
        this.recaptchaService.appendInvisibleRecaptchaToElement();
      }
    });*/
  }

  findBetMishpat(betMispatId: number): Observable<DictItem | undefined> {
    let betMispatValue = this.dataStore.bateyMishpat$.pipe(
      map((bateyMishpat) => {
        return bateyMishpat.find((b) => b.id == betMispatId);
      })
    );
    console.log("betMispatId=", betMispatId);

    return betMispatValue;
  }
}
