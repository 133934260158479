import { FormGroup } from "@angular/forms";

export class Client{
    idNumber: number;
    ip!: string;
    firstName: string;
    lastName: string;
    email: string;
    misTik!: number;
    region!: number;

    // constructor(idNumber: number,
    //             firstName: string,
    //             lastName: string,
    //             email: string){
    //                 this.idNumber = idNumber;
    //                 this.firstName = firstName;
    //                 this.lastName = lastName;
    //                 this.email = email;
    // }

    constructor(f: FormGroup){
        this.idNumber = f.value.idNumber;
        this.firstName = f.value.firstName;
        this.lastName = f.value.lastName;
        this.email = f.value.email;
    }
}