import { Component, OnInit } from '@angular/core';
import { MojFileUploadDesignType } from '@moj/moj-ng';
import { DictItem } from '../../../models/dict-item.model';

@Component({
  selector: 'app-zavaa-tab4',
  templateUrl: './zavaa-tab4.component.html',
  styleUrls: ['./zavaa-tab4.component.css']
})
export class ZavaaTab4Component implements OnInit {

  isHasZavaa!:boolean;

  isYorshimHistalku!:boolean;

  isYoreshDeadAfter!:boolean;
  filesYorshimHistalku = [];
  filesDesignType = MojFileUploadDesignType;    
  idTypeSelected: number = 1;
  idTypeHeirsSelected: number = 1;
  idTypeChildSelected: number = 1;
  relatedSelected: number = 1;

  dateDeceased:any;
  hebrewDaySelected:any;
  hebrewMonthSelected:any;
  hebrewYearSelected:any;
  remarks:any;

  idTypes: Array<DictItem> = [];
  hebrewDays: Array<DictItem> = [];
  hebrewMonths: Array<DictItem> = [];
  hebrewYears: Array<DictItem> = [];
  related: Array<DictItem> = [];


  filesDiedHeirs= [];  
  filesZavaa = [];

  constructor() { }

  ngOnInit() {
    this.initDropDownValues();
  }


  initDropDownValues() {
    this.idTypes.push({ id: 1, value: "ת. זהות" })
    this.idTypes.push({ id: 2, value: "דרכון" })
    this.idTypes.push({ id: 3, value: "מס' ביטוח לאומי ארה\"ב" })
    this.idTypes.push({ id: 4, value: "מס' זהות זר" })

 
    this.hebrewDays.push({id:1,value:"א'"})
    this.hebrewDays.push({id:2,value:"ב'"})
    this.hebrewDays.push({id:3,value:"ג'"})
    this.hebrewDays.push({id:4,value:"ד'"})

    this.hebrewMonths.push({id:1,value:"תשרי"})
    this.hebrewMonths.push({id:2,value:"חשון"})
    this.hebrewMonths.push({id:3,value:"כסלו"})
    this.hebrewMonths.push({id:4,value:"טבת"})

    this.hebrewYears.push({id:1,value:"תרס''א"})
    this.hebrewYears.push({id:2,value:"תרס''ב"})
    this.hebrewYears.push({id:3,value:"תרס''ג"})
    this.hebrewYears.push({id:4,value:"תרס''ד"})
  }
}
