<div>
  <moj-line>
    <moj-line>
      <h1 class="font-24 moj-bold moj-primary-color">
        {{ tguvotTexts.legalAdvisorResponses }}
      </h1>
    </moj-line>
  </moj-line>
  <div>
    <form (ngSubmit)="retrieveData()" #f="ngForm">
      <div role="search">
        <moj-line class="paddingTop5">
          <moj-autocomplete *ngIf="dataStoreService.bateyMishpat$  | async as bateyMishpatItems"
            (onChange)="courtChanged($event)" (onSelect)="courtChanged($event)" ngModel class="rightPadding0Label"
            name="court" [filterType]="filterType.includes" [labelTextKey]="tguvotTexts.court" labelWidthColumns="1"
            [controlWidthColumns]="3" [items]="bateyMishpatItems" [dropdown]="true" [required]="true">
          </moj-autocomplete>

          <div class="col-sm-2 text-left moj-bold">
            {{ tguvotTexts.courtFile }}
          </div>

          <moj-autocomplete ngModel [labelTextKey]="tguvotTexts.year" name="year" [controlWidthColumns]="2"
            [items]="yearListItems" fieldName="" [dropdown]="true" [required]="isYearRequired"
            [disabled]="!isYearRequired">
          </moj-autocomplete>

          <moj-autocomplete ngModel [labelTextKey]="tguvotTexts.month" name="month" [controlWidthColumns]="2"
            [items]="monthListItems" fieldName="" [dropdown]="true">
          </moj-autocomplete>

          <moj-textbox ngModel [labelTextKey]="tguvotTexts.caseNumber" name="number" pattern="^\d{1,20}$"
            [controlWidthColumns]="2" #number="ngModel" [required]="true">
          </moj-textbox>
        </moj-line>

        <moj-line>
          <moj-dropdownlist ngModel *ngIf="dataStoreService.sugeyTik$ | async as sugeyTikItems"
            [labelTextKey]="tguvotTexts.caseType" [controlWidthColumns]="3" name="type" [items]="sugeyTikItems">
          </moj-dropdownlist>
          <div class="col-sm-2"></div>
          <moj-autocomplete ngModel [labelTextKey]="tguvotTexts.requestYear" name="shnatBakasha"
            [controlWidthColumns]="2" [items]="yearListItems" fieldName="" [dropdown]="true">
          </moj-autocomplete>

          <moj-textbox ngModel [labelTextKey]="tguvotTexts.requestNumber" name="requestNumber" labelWidthColumns="2"
            [controlWidthColumns]="2" pattern="^\d{1,10}$">
          </moj-textbox>
        </moj-line>
        <moj-line>
          <div class="col-md-11">
            <moj-buttons-line style="float: left">
              <moj-clear-button [form]="f"> </moj-clear-button>
              <moj-submit-button id="tguvotBtn" [buttonStyle]="buttonStyle.Primary" [form]="f" (onSubmit)="onSubmit()"
                [textKey]="tguvotTexts.search" [isEnable]="f.valid! && !showLoading!">
              </moj-submit-button>
            </moj-buttons-line>
          </div>
        </moj-line>
      </div>
    </form>
  </div>
  <moj-line *ngIf="showGrid">
    <tguvot-output [data]="tguvot" [showLoading]="showLoading"></tguvot-output>
  </moj-line>
</div>