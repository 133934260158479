import { Component } from '@angular/core';
import { ButtonStyle, Link, MojConfigService, MojDirection, MojLanguage, MojRecaptchaService } from '@moj/moj-ng';
import { TranslateService } from '@ngx-translate/core';
import { SiteConfigService } from './services/SiteConfigService';

@Component({
  // standalone: true,
  selector: 'app-root',
  // imports: [MojDirectiveModule, MojWebsiteModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'app';
  buttonStyle = ButtonStyle;
  links!: Link[];
  languages: MojLanguage[] = [
    { code: 'he', value: 'עברית', dir: MojDirection.rtl },
    { code: 'en', value: 'English', dir: MojDirection.ltr },
  ];
  public items: any[] = [
    { productName: 'Albania', id: 1 },
    { productName: 'Andorra', id: 2 },
    { productName: 'Armenia', id: 3 },
    { productName: 'Austria', id: 4 },
    { productName: 'Azerbaijan', id: 5 },
  ];
  constructor(translate: TranslateService,
    public configService: MojConfigService,
    private configSiteService: SiteConfigService,
    private recaptchaService: MojRecaptchaService,
     
  ) { }

  ngOnInit() {
    if (!(<any>this.configService.configuration).isRecaptchaOn) return;
    this.recaptchaService.recaptchaReady.subscribe((res) => {
      if (res)
        this.recaptchaService.appendInvisibleRecaptchaToElement();
    });
  }
}
