import { Component, OnInit } from '@angular/core';
import { GridService, MojFileUploadDesignType } from '@moj/moj-ng';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { DictItem } from '../../../models/dict-item.model';
import { DataStoreService } from '../../../services/data-store.service';

@Component({
  selector: 'app-zavaa-tab5',
  templateUrl: './zavaa-tab5.component.html',
  styleUrls: ['./zavaa-tab5.component.css']
})
export class ZavaaTab5Component implements OnInit {

  idTypeHeirsSelected: number = 1;
  idTypeSelected = 1;

  countryHeirsSelected: number = 1;
  cityHeirsSelected: number = 1;
  streetHeirsSelected: number = 1;
  relatedSelected: number = 1;
  legalCourtSelected: number = 1;

  citySelected:any;
  streetSelected:any;
  cityMaanSelected:any;



  isApotropos!: boolean;
  isSingelHeirs: boolean=true;
  isHeirsKnow!: boolean;
  isAllHeirsKnow!: boolean;

  idTypes: Array<DictItem> = [];
  cities: Array<DictItem> = [];
  streets: Array<DictItem> = [];
  related: Array<DictItem> = [];
  legalCourt: Array<DictItem> = [];

  filesApotroposTZAV = [];
  filesHeirsMessage = [];

  filesDesignType = MojFileUploadDesignType;
  gridOptions!: GridOptions;
  herisColumns!: ColDef[];
  herisData:any;
  gridHerisApi!: GridApi;


  constructor(public dataStoreService: DataStoreService,
    private gridService: GridService) { }

  ngOnInit() {
    this.gridOptions = this.gridService.getMojGridOptions();
    this.gridOptions.domLayout = 'autoHeight';

    this.initColumns();

    this.initDropDownData();
  }
  onHerisGridReady(params:any) {
    this.gridHerisApi = params.api;
  }
  initColumns() {
    this.herisColumns = [
      this.gridService.getMojColumn("num", { colDef: { headerName: "requestTexts.num" } }),
      this.gridService.getMojColumn("name", { colDef: { headerName: "requestTexts.name" } }),
      this.gridService.getMojColumn("related", { colDef: { headerName: "requestTexts.related" } }),
      this.gridService.getMojColumn("legalCourt", { colDef: { headerName: "requestTexts.legalCourt" } }),
    ];
  }
  initDropDownData() {
    this.idTypes.push({ id: 1, value: "ת. זהות" })
    this.idTypes.push({ id: 2, value: "דרכון" })
    this.idTypes.push({ id: 3, value: "מס' ביטוח לאומי ארה\"ב" })
    this.idTypes.push({ id: 4, value: "מס' זהות זר" })


    this.related.push({ id: 1, value: "בן/ בת זוג" })
    this.related.push({ id: 2, value: "בן" })
    this.related.push({ id: 3, value: "בת" })
    this.related.push({ id: 4, value: "אח" })
    this.related.push({ id: 5, value: "אחות" })

    this.streets.push({ id: 1, value: "הצבי" })
    this.streets.push({ id: 2, value: "האייל" })
    this.streets.push({ id: 3, value: "אלון" })
    this.streets.push({ id: 4, value: "השמש" })

    this.cities.push({ id: 1, value: "ירושלים" })
    this.cities.push({ id: 2, value: "תל אביב" })
    this.cities.push({ id: 3, value: "חיפה" })
    this.cities.push({ id: 4, value: "באר שבע" })

    this.legalCourt.push({ id: 1, value: "כשיר" })
    this.legalCourt.push({ id: 2, value: "קטן" })
    this.legalCourt.push({ id: 3, value: "נעדר" })
    this.legalCourt.push({ id: 4, value: "אדם שמונה לו אפוטרופוס" })

  }
}
