<div>
  <div>
    <div class="paddingTop10 paddingBottom10 font-20 blue3 bold">תצהיר צרופות ותשלום (שדות המסומנים בכוכבית הנם שדות
      חובה)</div>
  </div>

  <div>
    <div class="paddingTop10 font-18 blue3 bold">צרופות</div>
  </div>

  <div>
    <h2 class="font-16 orange2 bold">נא בדוק שכל הצרופות להלן צורפו:</h2>
  </div>

  <div class="row" style="padding: 10px 30px 10px 0;">
    <div class="col-xs-6 paddingRight0">
      <!-- <span style="color:#c90101;;">*</span> -->
      יפוי כח
    </div>
    <div class="col-xs-5 paddingRight0">
      <moj-line>
        <moj-file-upload labelWidthColumns="0" [controlWidthColumns]="12" isLabelAboveControl="false" [required]="true"
          [id]="'filesPo'" [(ngModel)]="filesPo" name="filesPo" show [maxFileSize]="23068672" [enabledFileTypes]="'pdf'"
          designType="filesDesignType.Single">
        </moj-file-upload>
      </moj-line>
    </div>
  </div>

  <div class="row" style="padding: 10px 30px 10px 0;margin-top: 3px;">
    <div class="col-xs-6 paddingRight0">
      <!-- <span style="color:#c90101;;">*</span> -->
      תעודת פטירה של המנוח
    </div>
    <div class="col-xs-5 paddingRight0">
      <moj-line>
        <moj-file-upload labelWidthColumns="0" [controlWidthColumns]="12" isLabelAboveControl="false" [required]="true"
          [id]="'fileDeathCertificateDeceased'" [(ngModel)]="fileDeathCertificateDeceased"
          name="fileDeathCertificateDeceased" show [maxFileSize]="23068672" [enabledFileTypes]="'pdf'"
          designType="filesDesignType.Single">
        </moj-file-upload>
      </moj-line>
    </div>
  </div>

  <div class="row" style="padding: 10px 30px 10px 0;margin-top: 3px;">
    <div class="col-xs-6 paddingRight0">
      <!-- <span style="color:#c90101;;">*</span> -->
      אישור על סגירת תיק מבית הדין הדתי ללא מתן צו
    </div>
    <div class="col-xs-5 paddingRight0">
      <moj-line>
        <moj-file-upload labelWidthColumns="0" [controlWidthColumns]="12" isLabelAboveControl="false" [required]="true"
          [id]="'fileConfirmationCloseTik'" [(ngModel)]="fileConfirmationCloseTik" name="fileConfirmationCloseTik" show
          [maxFileSize]="23068672" [enabledFileTypes]="'pdf'" designType="filesDesignType.Single">
        </moj-file-upload>
      </moj-line>
    </div>
  </div>

  <div class="row" style="padding: 10px 30px 10px 0;margin-top: 3px;">
    <div class="col-xs-6 paddingRight0">
      <!-- <span style="color:#c90101;;">*</span> -->
      אישורי פטירה של היורשים שנפטרו
    </div>
    <div class="col-xs-5 paddingRight0">
      <moj-line>
        <moj-file-upload labelWidthColumns="0" [controlWidthColumns]="12" isLabelAboveControl="false" [required]="true"
          [id]="'fileDeathCertificateHeirs'" [(ngModel)]="fileDeathCertificateHeirs" name="fileDeathCertificateHeirs"
          show [maxFileSize]="23068672" [enabledFileTypes]="'pdf'" designType="filesDesignType.Single">
        </moj-file-upload>
      </moj-line>
    </div>
  </div>
  <div class="row" style="padding: 10px 30px 10px 0;margin-top: 3px;">
    <div class="col-xs-6 paddingRight0">
      <!-- <span style="color:#c90101;;">*</span> -->
      צו ירושה/צו קיום צוואה של יורשים שנפטרו אחרי המנוח
    </div>
    <div class="col-xs-5 paddingRight0">
      <moj-line>
        <moj-file-upload labelWidthColumns="0" [controlWidthColumns]="12" isLabelAboveControl="false" [required]="true"
          [id]="'fileTZAVforDeceasedHeirsAfterDeceased'" [(ngModel)]="fileTZAVforDeceasedHeirsAfterDeceased"
          name="fileTZAVforDeceasedHeirsAfterDeceased" show [maxFileSize]="23068672" [enabledFileTypes]="'pdf'"
          designType="filesDesignType.Single">
        </moj-file-upload>
      </moj-line>
    </div>
  </div>

  <div class="paddingTop10 font-18 blue3 bold">צרופות נוספות</div>

  <div style="padding: 10px 15px 10px 0;">
    <moj-line>
      <h3 class="font-14 bold" style="float:right;">שורה 1</h3>
      <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span style="color:#CC0000;"
          class="fa fa-times"></span>מחק שורה</h3>
    </moj-line>
  </div>
  <div class="row" style="padding: 10px 30px 10px 0;margin-top: 3px;">
    <div class="col-xs-6 paddingRight0">
      <moj-line>
        <moj-textbox ngModel name="attachments" [maxlength]="50" labelWidthColumns="0" isLabelAboveControl="false">
        </moj-textbox>
      </moj-line>
    </div>
    <div class="col-xs-5 paddingRight0">
      <moj-line>
        <moj-file-upload labelWidthColumns="0" [controlWidthColumns]="12" isLabelAboveControl="false"
          [id]="'fileAttachments'" [(ngModel)]="fileAttachments" name="fileAttachments" show [maxFileSize]="23068672"
          [enabledFileTypes]="'pdf'" designType="filesDesignType.Single">
        </moj-file-upload>
      </moj-line>
    </div>
  </div>
  <div style="padding: 10px 30px 10px 0;margin-top: 3px;">
    <moj-line>
      <div style="padding-left:30px;">
        <button style="background-color:#B7D3E4;padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
      </div>
    </moj-line>
  </div>
  <div>
    <h2 class="font-16 orange2 bold">אישור מגיש הבקשה</h2>
  </div>

  <div style="padding: 10px 30px 10px 0;">
    <moj-line>
      <moj-checkbox [controlWidthColumns]=1 labelWidthColumns=11 [(ngModel)]="isApproval" name="approval"
        [required]="true"
        labelTextKey="הריני , מספר רשיון עו''ד: , מאשר/ת לאחר שזיהיתי את גב'   , כי כל העובדות שציינו בבקשה נמסרו לי על ידי המבקשת, ולאחר שהזהרתיה כי עליה להצהיר את האמת וכי תהיה צפויה לעונשים הקבועים בחוק אם לא תעשה כן.">
      </moj-checkbox>
    </moj-line>
  </div>

  <div>
    <h3 class="font-16 orange2 bold">תשלום</h3>
  </div>

  <div class="paddingRight30 paddingTop5 bold"> תשלום אגרה</div>

  <div class="row" style="padding: 10px 30px 10px 0;">
    <div class="col-xs-6 paddingRight0 blue3 font-16 bold">
      האם זכאי לפטור מאגרה? (ניתן לקבל פטור מאגרה כאשר המנוח נפטר בשרות צבאי או בפעולות איבה ומגיש הבקשה הינו בן/בת
      זוג,ילד,הורה,או אח/ות של המנוח/ה)
      <span style="color:#c90101;;">*</span>
    </div>
    <div class="col-xs-5 paddingRight0">
      <div class="col-xs-4 paddingX0">
        <moj-radiobutton [(ngModel)]="isPtor" [radiovalue]="true" name="out" labelTextKey="כן">
        </moj-radiobutton>
      </div>
      <div class="col-xs-4 paddingX0">
        <moj-radiobutton [(ngModel)]="isPtor" [radiovalue]="false" name="out" labelTextKey="לא">
        </moj-radiobutton>
      </div>
    </div>
  </div>


</div>