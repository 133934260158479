<div>
  <div>
    <div class="paddingTop10 paddingBottom10 font-20 blue3 bold">שינויים לאחר פטירת המוריש/ה (שדות המסומנים בכוכבית הנם
      שדות חובה)</div>
  </div>

  <div class="row">
    <div class="col-xs-6 paddingRight0 paddingTop10 font-18 blue3 bold">
      האם יש יורשים שהסתלקו מחלקם בעיזבון ?
      <span style="color:red;">*</span>
    </div>
    <div class="col-xs-5 paddingRight0">
      <div class="col-xs-4 paddingX0">
        <moj-radiobutton [(ngModel)]="isOutHeirs" [radiovalue]="true" name="out" labelTextKey="כן">
        </moj-radiobutton>
      </div>
      <div class="col-xs-4 paddingX0">
        <moj-radiobutton [(ngModel)]="isOutHeirs" [radiovalue]="false" name="out" labelTextKey="לא">
        </moj-radiobutton>
      </div>
    </div>
  </div>

  <div *ngIf="isOutHeirs">
    <div class="row paddingTop10">
      <div class="col-xs-6 bold font-16">
        יורשים אלו הסתלקו מחלקם בעזבון כמפורט במסמכים המצורפים בזה
        <span style="color:red;">*</span>
      </div>
      <div class="col-xs-5 paddingRight0">
        <moj-file-upload labelWidthColumns="0" [controlWidthColumns]="12" isLabelAboveControl="false" [required]="true"
          [id]="'filesOutHeirs'" [(ngModel)]="filesOutHeirs" name="filesOutHeirs" show [maxFileSize]="23068672"
          [enabledFileTypes]="'pdf'" designType="filesDesignType.Single">
        </moj-file-upload>
      </div>
    </div>

    <div class="col-sm-12">
      <moj-line>
        <h3 class="font-14 bold" style="float:right;">שורה 1</h3>
        <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
            style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
      </moj-line>
    </div>

    <div class="paddingTop10">
      <h2 class="col-sm-12 font-16 orange2 bold">היורש</h2>
    </div>

    <div>
      <moj-line>
        <moj-dropdownlist required [ngModel]="idTypeHeirsSelected" labelWidthColumns="1" [controlWidthColumns]="4"
          name="idTypeHeirs" fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="false" [items]="idTypes">
        </moj-dropdownlist>
      </moj-line>
    </div>

    <div>
      <moj-line>
        <moj-textbox [required]="true" [identification]='idTypeChildSelected==1' ngModel name="idNumberChild"
          labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
          [maxlength]="9">
        </moj-textbox>
        <moj-textbox [required]="true" ngModel name="firstNameChild" [labelTextKey]="'שם פרטי'" [maxlength]="50"
          labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
        </moj-textbox>
        <moj-textbox [required]="true" ngModel name="lastNameChild" [labelTextKey]="'שם משפחה'" [maxlength]="50"
          labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
        </moj-textbox>
      </moj-line>
    </div>
    <div class="row paddingTop10">
      <div class="col-xs-6 font-16 blue3 bold">
        מסתלק מהעיזבון לטובת אדם או מסתלק בהסתלקות כללית
        <span style="color:red;">*</span>
      </div>
      <div class="col-xs-6 paddingRight0">
        <div class="col-xs-4 paddingX0">
          <moj-radiobutton [(ngModel)]="isOutForPerson" [radiovalue]="true" name="outForPerson"
            labelTextKey="לטובת אדם">
          </moj-radiobutton>
        </div>
        <div class="col-xs-7 paddingX0">
          <moj-radiobutton [(ngModel)]="isOutForPerson" [radiovalue]="false" name="outForPerson"
            labelTextKey="מסתלק בהסתלקות כללית">
          </moj-radiobutton>
        </div>
      </div>
    </div>
    <div *ngIf="isOutForPerson" style="padding: 0 70px 0 0;">
      <div>
        <moj-line>
          <h3 class="font-14 bold paddingRight10" style="float:right;">שורה 1</h3>
          <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
              style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
        </moj-line>
      </div>
      <div>
        <moj-line>
          <div class="col-sm-4">
            <moj-dropdownlist required [ngModel]="outForPersonTypesSelected" name="outForPersonTypes" fieldID="id"
              labelTextKey="הסתלק לטובת אדם מסוים שהוא" isLabelAboveControl="true" [items]="outForPersonTypes">
            </moj-dropdownlist>
          </div>
          <div class="col-sm-4">
            <div>האם מסתלק מכל חלקו בעיזבון</div>
            <div class="col-xs-6 paddingX0">
              <moj-radiobutton [(ngModel)]="isOutAll" [radiovalue]="true" name="outAll" labelTextKey="כן">
              </moj-radiobutton>
            </div>
            <div class="col-xs-6 paddingX0">
              <moj-radiobutton [(ngModel)]="isOutAll" [radiovalue]="false" name="outAll" labelTextKey="לא">
              </moj-radiobutton>
            </div>
          </div>
          <div *ngIf="isOutAll==false" class="row">
            <div class="col-sm-4">
              <moj-dynamic-label>פרטי החלק שממנו הסתלק</moj-dynamic-label><br>
              <moj-textbox ngModel name="outNumber" labelTextKey=" " [controlWidthColumns]="4"
                isLabelAboveControl="false" [maxlength]="3">
              </moj-textbox>
              <div class="col-xs-1">/</div>
              <moj-textbox ngModel name="outNumber2" labelTextKey=" " [controlWidthColumns]="4"
                isLabelAboveControl="false" [maxlength]="3">
              </moj-textbox>
            </div>
          </div>
        </moj-line>
      </div>

      <div class="paddingTop10 paddingRight10">
        <h2 class="font-16 orange2 bold">פרטי האדם שלטובתו הסתלק</h2>
      </div>

      <div>
        <moj-line>
          <moj-dropdownlist required [ngModel]="idTypeHeirsSelected" labelWidthColumns="1" [controlWidthColumns]="4"
            name="idTypeHeirs" fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="false" [items]="idTypes">
          </moj-dropdownlist>
        </moj-line>
      </div>

      <div>
        <moj-line>
          <moj-textbox [required]="true" [identification]='idTypeChildSelected==1' ngModel name="idNumberChild"
            labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
            [maxlength]="9">
          </moj-textbox>
          <moj-textbox [required]="true" ngModel name="firstNameChild" [labelTextKey]="'שם פרטי'" [maxlength]="50"
            labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
          </moj-textbox>
          <moj-textbox [required]="true" ngModel name="lastNameChild" [labelTextKey]="'שם משפחה'" [maxlength]="50"
            labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
          </moj-textbox>
        </moj-line>
      </div>
      <div>
        <moj-line>
          <div style="padding-left:60px;">
            <button style="background-color:#B7D3E4;padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
          </div>
        </moj-line>
      </div>


    </div>
    <div>
      <moj-line>
        <div style="padding-left:30px;">
          <button style="background-color:#B7D3E4;padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
        </div>
      </moj-line>
    </div>
  </div>

  <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

  <div class="row">
    <div class="col-xs-6 paddingRight0 font-18 blue3 bold">
      האם יש מי מבין היורשים שנפטר לאחר המנוח ?
      <span style="color:red;">*</span>
    </div>
    <div class="col-xs-5 paddingRight0">
      <div class="col-xs-4 paddingX0">
        <moj-radiobutton [(ngModel)]="IsDiedHeirs" [radiovalue]="true" name="died" labelTextKey="כן">
        </moj-radiobutton>
      </div>
      <div class="col-xs-4 paddingX0">
        <moj-radiobutton [(ngModel)]="IsDiedHeirs" [radiovalue]="false" name="died" labelTextKey="לא">
        </moj-radiobutton>
      </div>
    </div>
  </div>

  <div *ngIf="IsDiedHeirs">
    <div class="col-sm-12">
      <moj-line>
        <h3 class="font-14 bold" style="float:right;">שורה 1</h3>
        <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
            style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
      </moj-line>
    </div>

    <div>
      <moj-line>
        <moj-dropdownlist required [ngModel]="idTypeHeirsSelected" labelWidthColumns="1" [controlWidthColumns]="4"
          name="idTypeHeirs" fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="false" [items]="idTypes">
        </moj-dropdownlist>
      </moj-line>
    </div>

    <div>
      <moj-line>
        <moj-textbox [required]="true" [identification]='idTypeChildSelected==1' ngModel name="idNumberChild"
          labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
          [maxlength]="9">
        </moj-textbox>
        <moj-textbox [required]="true" ngModel name="firstNameChild" [labelTextKey]="'שם פרטי'" [maxlength]="50"
          labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
        </moj-textbox>
        <moj-textbox [required]="true" ngModel name="lastNameChild" [labelTextKey]="'שם משפחה'" [maxlength]="50"
          labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
        </moj-textbox>
      </moj-line>
    </div>
    <div>
      <moj-line>
        <moj-dropdownlist required [ngModel]="relatedSelected" labelWidthColumns="1" [controlWidthColumns]="4"
          name="relatedType" fieldID="id" labelTextKey="הקרבה למוריש/ה" isLabelAboveControl="false" [items]="related">
        </moj-dropdownlist>
      </moj-line>
    </div>
    <div>
      <moj-line>
        <moj-datepicker isLabelAboveControl="true" [ngModel]="dateDeceased" name="dateDeceased"
          labelTextKey="תאריך פטירה לועזי" labelWidthColumns="1" [controlWidthColumns]="2">
        </moj-datepicker>

        <div class="col-xs-10">
          <span style="padding-right: 15px;">תאריך פטירה עברי</span>
          <span style="color:red;">*</span><br>
          <moj-line>
            <moj-dropdownlist placeholder="שנה" required [ngModel]="hebrewYearSelected" labelWidthColumns="0"
              [controlWidthColumns]="2" name="hebrewYear" fieldID="id" isLabelAboveControl="true" [items]="hebrewYears">
            </moj-dropdownlist>
            <moj-dropdownlist placeholder="חודש" required [ngModel]="hebrewMonthSelected" labelWidthColumns="0"
              [controlWidthColumns]="2" name="hebrewMonth" fieldID="id" isLabelAboveControl="true"
              [items]="hebrewMonths">
            </moj-dropdownlist>
            <moj-dropdownlist placeholder="יום" required [ngModel]="hebrewDaySelected" labelWidthColumns="0"
              [controlWidthColumns]="2" name="hebrewDay" fieldID="id" isLabelAboveControl="true" [items]="hebrewDays">
            </moj-dropdownlist>
          </moj-line>
        </div>
      </moj-line>
    </div>

    <div class="row paddingTop20">
      <div class="col-xs-6 blue3 font-16 bold">
        אישורי פטירה של יורשים שנפטרו
        <span style="color:red;">*</span>
      </div>
      <div class="col-xs-5 paddingRight0">
        <moj-file-upload labelWidthColumns="0" [controlWidthColumns]="12" isLabelAboveControl="false" [required]="true"
          [id]="'filesDiedHeirs'" [(ngModel)]="filesDiedHeirs" name="filesDiedHeirs" show [maxFileSize]="23068672"
          [enabledFileTypes]="'pdf'" designType="filesDesignType.Single">
        </moj-file-upload>
      </div>
    </div>

    <div class="row paddingTop20">
      <div class="col-xs-6 blue3 font-16 bold">
        האם ניתן צו ירושה/קיום צוואה אחרי היורש שנפטר?
        <span style="color:red;">*</span>
      </div>
      <div class="col-xs-5 paddingRight0">
        <div class="col-xs-4 paddingX0">
          <moj-radiobutton [(ngModel)]="IsHasTZAV" [radiovalue]="true" name="hasTZAV" labelTextKey="כן">
          </moj-radiobutton>
        </div>
        <div class="col-xs-4 paddingX0">
          <moj-radiobutton [(ngModel)]="IsHasTZAV" [radiovalue]="false" name="hasTZAV" labelTextKey="לא">
          </moj-radiobutton>
        </div>
      </div>
    </div>
    <div class="row paddingTop20">
      <div class="col-xs-6 blue3 font-16 bold">
        צו ירושה/צו קיום צוואה של יורשים שנפטרו אחרי המנוח
        <span style="color:red;">*</span>
      </div>
      <div class="col-xs-5 paddingRight0">
        <moj-file-upload labelWidthColumns="0" [controlWidthColumns]="12" isLabelAboveControl="false" [required]="true"
          [id]="'filesTZAV'" [(ngModel)]="filesTZAV" name="filesTZAV" show [maxFileSize]="23068672"
          [enabledFileTypes]="'pdf'" designType="filesDesignType.Single">
        </moj-file-upload>
      </div>
    </div>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
      <h2 class="font-16 orange2 bold">והניח/ה אחריו/ה את היורשים הבאים כמוכח בראיות הרשומות להלן (המצ"ב)</h2>
    </div>

    <div class="col-sm-12">
      <moj-line>
        <div>הערות</div>
        <textarea cols="50"></textarea>
      </moj-line>
    </div>

    <div class="col-sm-12 paddingTop10">
      <h2 class="font-16 orange2 bold">שמות היורשים על פי דין</h2>
    </div>

    <div style="padding:0 70px 0 0">
      <div>
        <moj-line>
          <h3 class="font-14 bold paddingRight10" style="float:right;">שורה 1</h3>
          <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
              style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
        </moj-line>
      </div>
      <div>
        <moj-line>
          <moj-dropdownlist required [ngModel]="idTypeHeirsSelected" labelWidthColumns="1" [controlWidthColumns]="4"
            name="idTypeHeirs" fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="false" [items]="idTypes">
          </moj-dropdownlist>
        </moj-line>
      </div>

      <div>
        <moj-line>
          <moj-textbox [required]="true" [identification]='idTypeChildSelected==1' ngModel name="idNumberChild"
            labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
            [maxlength]="9">
          </moj-textbox>
          <moj-textbox [required]="true" ngModel name="firstNameChild" [labelTextKey]="'שם פרטי'" [maxlength]="50"
            labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
          </moj-textbox>
          <moj-textbox [required]="true" ngModel name="lastNameChild" [labelTextKey]="'שם משפחה'" [maxlength]="50"
            labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
          </moj-textbox>
        </moj-line>
      </div>

      <div>
        <moj-line>
          <div class="col-xs-6">
            <div class="col-xs-12">
              <span class="paddingRight15">החלק היחסי בעיזבון</span>
              <span style="color:#c90101;">*</span>
            </div>
            <div class="col-xs-12 paddingTop5">
              <moj-textbox ngModel isLabelAutowidth="true" name="outNumber" labelTextKey=" " [controlWidthColumns]="3"
                isLabelAboveControl="false" [maxlength]="3">
              </moj-textbox>
              <div style="width:auto" class="col-xs-1 paddingX0 font-18">/</div>
              <moj-textbox ngModel isLabelAutowidth="true" name="outNumber2" labelTextKey=" " [controlWidthColumns]="3"
                isLabelAboveControl="false" [maxlength]="3">
              </moj-textbox>
            </div>
          </div>
        </moj-line>
      </div>

      <div>
        <moj-line>
          <div style="padding-left:60px;">
            <button style="background-color:#B7D3E4;padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
          </div>
        </moj-line>
      </div>
    </div>
    <div>
      <moj-line>
        <div style="padding-left:30px;">
          <button style="background-color:#B7D3E4;padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
        </div>
      </moj-line>
    </div>
  </div>
</div>