import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// bootstrapApplication(AppComponent, {
//   providers: [
//     importProvidersFrom(
//       BrowserModule,
//       MojSharedModule,
//       MojInputModule,
//       MojWebsiteModule,
//       TranslateModule.forRoot({
//         loader: {
//           provide: TranslateLoader,
//           useFactory: (http: HttpBackend) => new MojTranslateLoader(http),
//           deps: [HttpBackend],
//         },
//       })
//     ),
//     provideRouter(appRoutes),
//     {
//       provide: APP_INITIALIZER,
//       useFactory: (utils: MojUtilsService) => () => utils.initialize(environment.configFile),
//       deps: [MojUtilsService],
//       multi: true,
//     },
//     { provide: ErrorHandler, useClass: GlobalErrorHandler },
//     provideAnimations(),
//   ],
//   // eslint-disable-next-line no-console
// }).catch((err: any) => console.error(err));

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((err: any) => console.error(err));