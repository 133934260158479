import { Injectable } from '@angular/core';

import { RecaptchaModel } from '../models/recaptcha-model';

@Injectable()
export class BahashotDataService {
    taarichfrom: any;
    TaarichTo: any;
    shemPratiManoach: any;
    shemMishpachaManoach: any;
    tzManoah: any;
    misparTik: any;
    machoz: any;

    constructor() {

    }

    clearAll() {
        this.taarichfrom = "";
        this.TaarichTo = "";
        this.shemPratiManoach = "";
        this.shemMishpachaManoach = "";
        this.tzManoah = "";
        this.misparTik = "";
        this.machoz = "";
    }

    getPreviousRequestForSearch(rModel?: RecaptchaModel) {
        let req =
        {
            kod: null,
            tZManoach: this.tzManoah ? this.tzManoah : "",
            shemMishpachaManoach: this.shemMishpachaManoach ? this.shemMishpachaManoach : "",
            shemPratiManoach: this.shemPratiManoach ? this.shemPratiManoach : "",
            misparTik: this.misparTik ? this.misparTik : "",
            date_From: this.taarichfrom ? new Date(this.taarichfrom).toDateString() : null,
            date_To: this.TaarichTo ? new Date(this.TaarichTo).toDateString() : null,
            kodMachoz: this.machoz ? this.machoz : "",
            recaptchaModel: rModel
        };
        return req;
    }

    setCurrentRequestForSearch(request: any) {
        this.shemPratiManoach = request.shemPratiManoach;
        this.shemMishpachaManoach = request.shemMishpachaManoach;
        this.tzManoah = request.tZManoach;
        this.misparTik = request.misparTik;
        this.taarichfrom = request.date_From ? request.date_From : "";
        this.TaarichTo = request.date_To ? request.date_To : "";
        this.machoz = request.kodMachoz;
    }
}