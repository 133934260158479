<div #pnl>

    <moj-line>
        <h1 class="font-28 moj-bold moj-primary-color" style="float: right">
            {{ requestTexts.requestDetails }}
        </h1>
        <a class="font-18 moj-bold" style="float: left" [routerLink]="['/bakashot']"> חזרה לאיתור בקשות ➦</a>
    </moj-line>
    <moj-line> </moj-line>
    <div class="paddingTop15">
        <div class="panel line-flex space-between paddingTop10 paddingX10">
            <div>
                <div>
                    <label class="BanerLabel">{{ requestTexts.requestNumber }}:</label>
                    <label class="paddingRight5">{{bakashaData?.bakashaBasic?.misparBakasha}}</label>
                </div>
                <div>
                    <label class="BanerLabel">{{ requestTexts.deceasedId }}:</label>
                    <label class="paddingRight5">{{bakashaData?.bakashaBasic?.tzManoach}}</label>
                </div>
                <div>
                    <label class="BanerLabel"> {{ requestTexts.requestStatus }}:</label>
                    <label class="paddingRight5">{{bakashaData?.shemstatusBakasha}}</label>
                </div>
            </div>
            <div>
                <div>
                    <label class="BanerLabel">{{ requestTexts.caseNumber }}:</label>
                    <label class="paddingRight5">{{bakashaData?.bakashaBasic?.misparTik}}</label>
                </div>
                <div>
                    <label class="BanerLabel">{{ requestTexts.deceasedName }}:</label>
                    <label class="paddingRight5">{{bakashaData?.bakashaBasic?.shemManoach}}</label>
                </div>
                <div>
                    <label class="BanerLabel">{{ requestTexts.closingDate }}:</label>
                    <label class="paddingRight5">{{bakashaData?.taarichSgira | date: 'dd/MM/yyyy'}}</label>
                </div>
            </div>
            <div>
                <div>
                    <label class="BanerLabel">{{ requestTexts.requestDate }}:</label>
                    <label class="paddingRight5">{{bakashaData?.bakashaBasic?.taarichHagashatBakasha | date:
                        'dd/MM/yyy'}}</label>
                </div>
                <div>
                    <label class="BanerLabel">{{ requestTexts.district }}:</label>
                    <label class="paddingRight5">{{bakashaData?.bakashaBasic?.shemMachoz}}</label>
                </div>
                <div>
                    <label class="BanerLabel">{{ requestTexts.closingReason }}:</label>
                    <label class="paddingRight5">{{bakashaData?.shemSibatSgira}}</label>
                </div>
            </div>
            <div>
                <div>
                    <label class="BanerLabel">{{ requestTexts.requestType }}:</label>
                    <label class="paddingRight5">{{bakashaData?.bakashaBasic?.shemSugBakasha}}</label>
                </div>
                <div *ngIf="bakashaData?.statusShlichaLaTabu">
                    <label class="BanerLabel">{{ requestTexts.statusShlichaLaTabu }}:</label>
                    <label class="paddingRight5">{{bakashaData?.statusShlichaLaTabu}}</label>
                </div>
                <div>
                    <label class="BanerLabel" *ngIf="(betMishpatName$ | async)">שם בית משפט:</label>
                    <label class="paddingRight5">{{ (betMishpatName$ | async)?.value }}</label>
                </div>
                <div>
                    <label class="BanerLabel" *ngIf="misparTikBetMishpat">מספר תיק בית משפט:</label>
                    <label class="paddingRight5">{{ misparTikBetMishpat }}</label>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="col-sm-2 paddingX0">
            <label class="BanerLabel">{{ requestTexts.publishDate }}:</label>
            <label class="BanerField paddingRight5">{{ bakashaData?.taarichPirsum | date: 'dd/MM/yyyy'}}</label>
        </div>
        <!--<div class="col-sm-3">
            <label class="BanerLabel">{{ requestTexts.newspaperPublish }}:</label>
            <label class="paddingRight5">{{ bakashaData?.shemItonMefarsem }}</label>
        </div>-->
    </div>

    <moj-line> </moj-line>

    <moj-line>
        <div class="paddingTop20">
            <moj-line>
                <h2 class="font-20 moj-orange-color moj-bold">פעולות בתיק</h2>
            </moj-line>
        </div>
    </moj-line>
    <moj-line> </moj-line>


    <div class="line-flex-center">
        <div class="line-flex paddingTop5">
            <a href="javascript:void(0)" class="big-button"
                (click)="onClickAppealButton(SugBakashaEnum.BakashaChadasha);">
                <div class="big-button-img-container">
                    <div class="big-button-img ApplyRequest"></div>
                </div>
                <div class="big-button-text">הגשת בקשה בתיק</div>
            </a>
            <a [attr.href]="(logicResponse.isViewCourtOrder)?'javascript:void(0)':null"
                [ngClass]="(logicResponse.isViewCourtOrder)?'big-button':'big-button-dis'"
                [title]="!logicResponse.isViewCourtOrder ? tooltip :''"
                (click)="logicResponse.isViewCourtOrder && onClickAppealButton(SugBakashaEnum.etekTzav)">
                <div class="big-button-img-container">
                    <div class="big-button-img ViewCourtOrder"></div>
                </div>
                <div class="big-button-text">קבלת העתק צו ואפוסטיל דיגיטלי</div>
            </a>
            <a [attr.href]="(logicResponse.isApplyObjection)?'javascript:void(0)':null"
                [ngClass]="(logicResponse.isApplyObjection)?'big-button':'big-button-dis'"
                [title]="!logicResponse.isApplyObjection ? tooltip : ''"
                (click)="logicResponse.isApplyObjection && onClickAppealButton(SugBakashaEnum.Itnagdut)">
                <div class="big-button-img-container">
                    <div class="big-button-img ApplyObjection"></div>
                </div>
                <div class="big-button-text">הגשת התנגדות</div>
            </a>
            <a [attr.href]="(logicResponse.isResponseDecision)?'javascript:void(0)':null"
                [ngClass]="(logicResponse.isResponseDecision)?'big-button':'big-button-dis'"
                [title]="!logicResponse.isResponseDecision ? tooltip : ''"
                (click)="logicResponse.isResponseDecision && onClickAppealButton(SugBakashaEnum.TguvaLehachlatatRasham)">
                <div class="big-button-img-container">
                    <div class="big-button-img ResponseDecision"></div>
                </div>
                <div class="big-button-text">תגובה להחלטת רשם</div>
            </a>
            <a [attr.href]="(logicResponse.isCompletionDocsSecretariat)?'javascript:void(0)':null"
                [ngClass]="(logicResponse.isCompletionDocsSecretariat)?'big-button':'big-button-dis'"
                [title]="!logicResponse.isCompletionDocsSecretariat ? tooltip : ''"
                (click)="logicResponse.isCompletionDocsSecretariat && onClickAppealButton(SugBakashaEnum.HashlamatMismachimMazkirut)">
                <div class="big-button-img-container">
                    <div class="big-button-img CompletionDocsSecretariat"></div>
                </div>
                <div class="big-button-text">השלמת מסמכים למזכירות</div>
            </a>
            <a [attr.href]="(logicResponse.isCompletionDocsLegalAdviser)?'javascript:void(0)':null"
                [ngClass]="(logicResponse.isCompletionDocsLegalAdviser)?'big-button':'big-button-dis'"
                [title]="!logicResponse.isCompletionDocsLegalAdviser ? tooltip : ''"
                (click)="logicResponse.isCompletionDocsLegalAdviser && onClickAppealButton(SugBakashaEnum.HashlamatMismachimYoetz)">
                <div class="big-button-img-container">
                    <div class="big-button-img CompletionDocsLegalAdviser"></div>
                </div>
                <div class="big-button-text">השלמת מסמכים לבא-כח היועמ"ש</div>
            </a>

            <a [attr.href]="(logicResponse.isCompletionDocsOzerRasham)?'javascript:void(0)':null"
                [ngClass]="(logicResponse.isCompletionDocsOzerRasham)?'big-button':'big-button-dis'"
                [title]="!logicResponse.isCompletionDocsOzerRasham ? tooltip : ''"
                (click)="logicResponse.isCompletionDocsOzerRasham && onClickAppealButton(SugBakashaEnum.HashlamatMismachimOzerRasham)">
                <div class="big-button-img-container">
                    <div class="big-button-img CompletionDocsOzerRasham"></div>
                </div>
                <div class="big-button-text">השלמת מסמכים לעוזר רשם</div>
            </a>
        </div>
    </div>

    <div class="space">
        <moj-line>
            <h2 class="font-20 moj-orange-color moj-bold">{{ requestTexts.submitters }}</h2>
        </moj-line>
        <moj-line>
            <moj-grid-panel [noResultOptions]="noResultOptions">
                <ag-grid-angular [gridOptions]="gridOptions" [rowData]="mevakshimData" [columnDefs]="mevakshimColumns"
                    [suppressHorizontalScroll]="true" [pagination]="false" (gridReady)="onMevakshimGridReady($event)">
                </ag-grid-angular>
            </moj-grid-panel>
        </moj-line>
    </div>

    <div class="flex-line">
        <div class="col-sm-6 col-xs-12 paddingLeft30 paddingRight0 paddingTop15 space">
            <h2 class="font-20 moj-orange-color moj-bold">{{ requestTexts.legalAdvisorResponses }}</h2>
            <moj-line><moj-grid-panel [noResultOptions]="noResultOptions">
                    <ag-grid-angular [gridOptions]="gridOptions" [rowData]="tguvotData" [columnDefs]="tguvotColumns"
                        [pagination]="false" (gridReady)="onTguvotGridReady($event)">
                    </ag-grid-angular>
                </moj-grid-panel>
            </moj-line>
        </div>

        <div class="col-sm-6 col-xs-12 paddingLeft0 padddingRight30 paddingTop15 space">
            <h2 class="font-20 moj-orange-color moj-bold">{{ requestTexts.resolutions }}</h2>
            <moj-line> <moj-grid-panel [noResultOptions]="noResultOptions">
                    <ag-grid-angular [gridOptions]="gridOptions" [rowData]="hachlatotData"
                        [columnDefs]="hachlatotColumns" [pagination]="false" (gridReady)="onHachlatotGridReady($event)">
                    </ag-grid-angular>
                </moj-grid-panel>
            </moj-line>
        </div>
    </div>
</div>