import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ButtonStyle } from '../../../node_modules/@moj/moj-ng';
import { TranslateService } from '../../../node_modules/@ngx-translate/core';
// import { MojRecaptchaService } from '../../../node_modules/@moj/moj-ng';
// import { RecaptchaModel } from '../models/recaptcha-model';
import { MessageType, MojMessagesService } from '@moj/moj-ng';
import { FontSize, FontWeight, MojColor } from '../../../node_modules/@moj/moj-ng';
import { BdikatBakashaResponse } from '../models/bdikat-bakasha.model';
import { InheritanceDataService } from '../services/inheritance-data.service';

@Component({
  selector: 'bdikat-bakasha',
  templateUrl: './bdikat-bakasha.component.html',
  styleUrls: ['./bdikat-bakasha.component.css']
  // ,changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdikatBakashaComponent {
  @ViewChild('f', { static: false }) formModel!: NgForm;
  // bdikatBakashot: BdikatBakashotRequest[];
  buttonStyle = ButtonStyle;
  fontSize = FontSize;
  fontWeight = FontWeight;
  mojColor = MojColor;
  showLoading!: boolean;
  showGrid: boolean = false;
  // model: RecaptchaModel = { recaptchaString: "" }

  checkRequestTexts;
  bdikatBakashotResponse!: BdikatBakashaResponse[];

  constructor(private inheritanceDataService: InheritanceDataService,
    private translateService: TranslateService,
    private messageService: MojMessagesService,
    // private cdr: ChangeDetectorRef
    // private recaptchaService: MojRecaptchaService
  ) {
    this.checkRequestTexts = this.translateService.instant('Texts.checkRequestTexts');
  }

  ngOnInit() {
    // this.recaptchaService.recaptchaReady.subscribe(isReady => {
    //     if (isReady) {
    //        this.retrieveData();
    //     }
    // });
  }

  isAllFieldsEmpty() {
    let data = this.formModel.value;
    if (!data.tzManoach &&
      !data.shemMishpachaManoach &&
      !data.shemPratiManoach) {
      return true;
    }
    return false;
  }

  retrieveData() {
    if (this.isAllFieldsEmpty()) {
      this.messageService.showMessage({ messageText: "Texts.atLeastOneFieldRequired", messageTitle: "MojTexts.errorMessage", messageType: MessageType.Error })
      return;
    }

    let f = this.formModel.value;
    this.showGrid = true;
    this.showLoading = true;
    // this.recaptchaService.executeRecaptcha()
    //     .subscribe(res => {
    //         this.model.recaptchaString = res;

    this.inheritanceDataService.getBdikatBakasha(f)
      .subscribe(output => {
        this.bdikatBakashotResponse = output;
        this.showLoading = false;
        // this.cdr.markForCheck();
      },
        error => {
          this.showLoading = false;
          // this.cdr.markForCheck();
        });
    // }, error => this.showLoading = false);
  }
}