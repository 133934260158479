import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogResultEnum, MessageType, MojLoadingService, MojMessagesService } from '@moj/moj-ng';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { take } from 'rxjs/operators';
import { InheritanceDataService } from '../../services/inheritance-data.service';

@Component({
  selector: 'app-detail-cell-renderer',
  template: `
    <style>
      th, td {padding: 15px;}
    
    /*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
	*/
	@media
	  only screen 
    and (max-width: 760px), (min-device-width: 768px) 
    and (max-device-width: 1024px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

    tr {
      margin: 0 0 1rem 0;
    }
      
    tr:nth-child(odd) {
      background: #ccc;
    }
    
		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
		}
  }
  </style>

<div #pnl>
    <div style="border: 1px solid black; padding:15px 30px">
    <moj-line>
      <h2 class="moj-orange-color moj-bold">{{deceasedText.debtsDetails }}</h2>
    </moj-line>
    <moj-line>
        <table>
            <tr>
                <td>{{deceasedText.idNumber}}: {{data.idNumber }}</td>
                <td>{{deceasedText.firstName}}: {{data.firstName }}</td>
                <td>{{deceasedText.lastName}}: {{data.lastName }}</td>
                <!-- <td>{{deceasedText.isActive}}: {{data.isActive?'כן':'לא'}}</td> -->
            </tr>
            <tr>
              <td>{{deceasedText.localAuthorityName}}: {{data. localAuthorityName}}</td>
              <td>{{deceasedText.amount}}: {{data.amount }}</td>
              <td>{{deceasedText.debtsDate}}: {{data.debtDate|date:'dd/MM/yyyy' }}</td>
            </tr>
            <tr>                
                <td>{{deceasedText.claimAuthority}}: {{data.claimToAuthorityCollection?'כן':'לא'}}</td>
                <td>{{deceasedText.phone}}: {{data.phone }}</td>
                <td>{{deceasedText.email}}: {{data.email }}</td>
            </tr>
            <tr>
              <td colspan="3" style="white-space: initial;">{{deceasedText.debtDesc}}: {{data.debtDesc}}</td>
              <td>
                  <div *ngIf="data.isGuard" style="position: absolute; bottom: 60px; left: 30px;vertical-align:bottom; text-align: left; width: 100%;">
                    <moj-submit-button id="deleteDebtsBtn" (click)="deleteDebts()" [textKey]="deceasedText.delete">
                    </moj-submit-button>
                  </div>
              </td>
            </tr>
      </table>  
    </moj-line>
    <moj-line></moj-line>          
  </div>
</div>       
  `,
})
export class DetailCellRenderer implements ICellRendererAngularComp {
  params: any;
  data: any;
  api: any;
  showLoading!: boolean;
  @ViewChild("pnl", { read: ViewContainerRef, static: false }) pnl!: ViewContainerRef;

  deceasedText;
  agInit(params: any): void {
    this.api = params.api;
    this.data = params.data;
    this.params = params;
  }

  refresh(params: any): boolean {
    return true;
  }
  constructor(private dataService: InheritanceDataService,
    private loadingService: MojLoadingService,
    private messagesService: MojMessagesService,
    private translateService: TranslateService) {
    this.deceasedText = this.translateService.instant('Texts.deceasedText');
  }

  deleteDebts() {

    this.messagesService.showMessage({ messageTitle: this.deceasedText.confirmDeleteMessage, messageText: this.deceasedText.Message, messageType: MessageType.Confirm })
      .subscribe(res => {
        if (res.dialogResultType == DialogResultEnum.OK) {
          this.loadingService.show(this.pnl);
          this.showLoading = true;
          this.dataService.deleteDebt(this.data.debtId)
            .pipe(take(1))
            .subscribe((res: any) => {
              if (res == true) {
                //this.data.isActive = false;

                const selectedNodes = this.api.getSelectedNodes();
                const allNodesData = Array<any>()
                this.api.forEachNode((node: any) => {
                  if (selectedNodes.indexOf(node) < 0)
                    allNodesData.push(node.data)
                })
                this.api.setRowData(allNodesData)
                this.showLoading = false;
                this.loadingService.hide();
              }
            },
              (error) => this.loadingService.hide());
        }
      });
  }
}
