<div class="login-background" id="content" (click)="onCheckedChange($event)">
</div>
<style>
    .login-background {
    background-image: url(../../assets/Images/BGlogin_mock2.jpg);
    background-repeat: no-repeat;
    position: absolute;
    width: 1200px;
    left: 0;
    right: 0;
    height: 790px;
}
</style>

