import { Component, OnInit } from '@angular/core';
import { MojFileUploadDesignType } from '@moj/moj-ng';
import { DictItem } from '../../../models/dict-item.model';
import { DataStoreService } from '../../../services/data-store.service';

@Component({
  selector: 'app-yerusha-tab2',
  templateUrl: './yerusha-tab2.component.html'
})
export class YerushaTab2Component implements OnInit {
  isMevakesh!: boolean;
  isMevakeshet !:boolean;
  idTypeSelected = 1;
  interestSelected:any;
  citySelected:any;
  streetSelected:any;
  cityMaanSelected:any;
  streetMaanSelected:any;
  countryMorishSelected:any;
  cityMorishSelected:any;
  streetMorishSelected:any;
  dateDeceased:any;
  dateWill:any;
  hebrewDayWillSelected:any;
  hebrewMonthWillSelected:any;
  hebrewYearWillSelected:any;
  hebrewDaySelected:any;
  hebrewMonthSelected:any;
  hebrewYearSelected:any;
  maritalStatusSelected:any;
  isLawyer!: boolean;
  isSms!: boolean;
  isMorish!: boolean;
  isMorisha!: boolean;
  isIsraelResident = true;
  isLeftwill = true;
  isIncluded = true;
  isMinor = false;
  isGuardian = false;
  isMissing = false;
  willForm!: number;
  idTypes: Array<DictItem> = [];
  cities: Array<DictItem> = [];
  streets: Array<DictItem> = [];
  interests: Array<DictItem> = [];
  hebrewDays: Array<DictItem> = [];
  hebrewMonths: Array<DictItem> = [];
  hebrewYears: Array<DictItem> = [];
  filesPoa = [];
  filesAsmachta = [];
  filesDinMekutzar = [];
  filesProof1 = [];
  filesWillCopy = [];
  fuDesignType = MojFileUploadDesignType;    
  
  constructor(public dataStoreService: DataStoreService) { }

  ngOnInit() {
    this.idTypes.push({id:1,value:"ת. זהות"})
    this.idTypes.push({id:2,value:"דרכון"})
    this.idTypes.push({id:3,value:"מס' ביטוח לאומי ארה\"ב"})
    this.idTypes.push({id:4,value:"מס' זהות זר"})

    this.streets.push({id:1,value:"השלום"})
    this.streets.push({id:2,value:"הרואה"})
    this.streets.push({id:3,value:"האלה"})
    this.streets.push({id:4,value:"ויצמן"})

    this.cities.push({id:1,value:"ירושלים"})
    this.cities.push({id:2,value:"תל אביב"})
    this.cities.push({id:3,value:"חיפה"})
    this.cities.push({id:4,value:"באר שבע"})

    this.interests.push({id:1,value:"יורש/ת"})
    this.interests.push({id:2,value:"נושה"})
    this.interests.push({id:3,value:"מנהל/ת עיזבון"})
    this.interests.push({id:4,value:"אחר"})

    this.hebrewDays.push({id:1,value:"א'"})
    this.hebrewDays.push({id:2,value:"ב'"})
    this.hebrewDays.push({id:3,value:"ג'"})
    this.hebrewDays.push({id:4,value:"ד'"})

    this.hebrewMonths.push({id:1,value:"תשרי"})
    this.hebrewMonths.push({id:2,value:"חשון"})
    this.hebrewMonths.push({id:3,value:"כסלו"})
    this.hebrewMonths.push({id:4,value:"טבת"})

    this.hebrewYears.push({id:1,value:"תרס''א"})
    this.hebrewYears.push({id:2,value:"תרס''ב"})
    this.hebrewYears.push({id:3,value:"תרס''ג"})
    this.hebrewYears.push({id:4,value:"תרס''ד"})
  }

}
