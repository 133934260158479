import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../login/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class IpGuard {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isIpAuthorized()
      .pipe(
        map(res => {
          if (res) return res;

          window.location.href = '/assets/unauthorized.html';
          return false;
        }));
  }
}  
 