

import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ButtonStyle, FontSize, FontWeight, MojColor, MojLoadingService } from '@moj/moj-ng';

import { MessageType, MojMessagesService } from '@moj/moj-ng';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { Client } from '../../models/client-data.model';
import { AuthService } from '../auth-service.service';

@Component({
  selector: 'login-corp',
  templateUrl: './login-corp.component.html',
  styleUrls: ['./login-corp.component.css']
})
export class LoginCorpComponent {

  @ViewChild('f', { static: false }) formModel!: NgForm;
  @ViewChild("pnl", { read: ViewContainerRef, static: false }) pnl!: ViewContainerRef;

  data: any;
  rowData: any;
  buttonStyle = ButtonStyle;
  fontSize = FontSize;
  fontWeight = FontWeight;
  mojColor = MojColor;
  submitted !: boolean;
  isValid !: boolean;
  clientData!: Client;

  loginTexts;

  constructor(private authService: AuthService,
    private loadingService: MojLoadingService,
    private messageService: MojMessagesService,
    private translateService: TranslateService) {

    this.loginTexts = this.translateService.instant('Texts.loginTexts');
  }

  onSubmit() {
    this.loadingService.show(this.pnl);
    this.clientData = new Client(<any>this.formModel);
    this.loadingService.show(this.pnl);
    if (this.clientData != null && this.clientData != undefined)
      this.authService.login(this.clientData, '')
        .pipe(finalize(() => this.loadingService.hide(this.pnl)))
        .subscribe(data => {
          if (!data)
            this.messageService.showMessage({ messageTitle: "MojTexts.errorMessage", messageText: "יש להזין את השם המלא כפי שהוא מופיע בתעודת הזהות", messageType: MessageType.Error });
        })
    // }, error => this.loadingService.hide(this.pnl));
  }
}