import { Injectable } from '@angular/core';
import { MojRecaptchaService } from '@moj/moj-ng';

@Injectable()
export class RecaptchaUtilsService {

    isInvisibleRecaptchaAppended: boolean = false;

    constructor(private recaptchaService: MojRecaptchaService) {
        debugger
        this.initInvisible();
     }

    initInvisible() {
        this.recaptchaService.recaptchaReady.subscribe(isReady => {
            if(isReady){
                this.recaptchaService.appendInvisibleRecaptchaToElement();
                this.isInvisibleRecaptchaAppended = true;
            }
        });
    }
}