import { Injectable } from '@angular/core';
import { MojConfigService } from '@moj/moj-ng';
// import { SiteConfigModel } from '../models/SiteConfigModel';


@Injectable()
export class SiteConfigService {

  public get configuration() {// SiteConfigModel 
    return this.configService.configuration;//as SiteConfigModel;
  }

  constructor(private configService: MojConfigService) { }

}
