import { Component, OnInit } from '@angular/core';
import { MojFileUploadDesignType } from '@moj/moj-ng';
import { DictItem } from '../../../models/dict-item.model';

@Component({
  selector: 'app-zavaa-tab3',
  templateUrl: './zavaa-tab3.component.html',
  styleUrls: ['./zavaa-tab3.component.css']
})
export class ZavaaTab3Component implements OnInit {

  IsBeforeMorishNiftaar!:boolean;
  reletiveSelected=1;
  hebrewDaySelected:any;
  hebrewMonthSelected:any;
  hebrewYearSelected:any;
  dateDeceased:any;
  idTypeSelected = 1;
  idZocheTypeSelected= 1;
  idTypes: Array<DictItem> = [];
  reletives: Array<DictItem> = [];
  hebrewDays: Array<DictItem> = [];
  hebrewMonths: Array<DictItem> = [];
  hebrewYears: Array<DictItem> = [];

  filesProof = [];
  filesWillCopy = [];
  fuDesignType = MojFileUploadDesignType;
  
  constructor() { }

  ngOnInit() {
    this.initDropDownValues();
  }

  initDropDownValues() {
    this.idTypes.push({ id: 1, value: "ת. זהות" })
    this.idTypes.push({ id: 2, value: "דרכון" })
    this.idTypes.push({ id: 3, value: "מס' ביטוח לאומי ארה\"ב" })
    this.idTypes.push({ id: 4, value: "מס' זהות זר" })


    this.reletives.push({ id: 1, value: "בן/ בת זוג" })
    this.reletives.push({ id: 2, value: "בן" })
    this.reletives.push({ id: 3, value: "בת" })
    this.reletives.push({ id: 4, value: "אח" })
    this.reletives.push({ id: 5, value: "אחות" })

    this.hebrewDays.push({ id: 1, value: "א'" })
    this.hebrewDays.push({ id: 2, value: "ב'" })
    this.hebrewDays.push({ id: 3, value: "ג'" })
    this.hebrewDays.push({ id: 4, value: "ד'" })

    this.hebrewMonths.push({ id: 1, value: "תשרי" })
    this.hebrewMonths.push({ id: 2, value: "חשון" })
    this.hebrewMonths.push({ id: 3, value: "כסלו" })
    this.hebrewMonths.push({ id: 4, value: "טבת" })

    this.hebrewYears.push({ id: 1, value: "תרס''א" })
    this.hebrewYears.push({ id: 2, value: "תרס''ב" })
    this.hebrewYears.push({ id: 3, value: "תרס''ג" })
    this.hebrewYears.push({ id: 4, value: "תרס''ד" })



  }

}
