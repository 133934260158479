<div class="paddingTop10 font-20 blue3 bold">שינויים לפני פטירת המוריש/ה (שדות המסומנים בכוכבית הנם שדות חובה)
</div>
<moj-line>
  <div class="row paddingTop5">
    <div class="col-xs-6 paddingRight0 font-16 blue3 bold">
      האם יש זוכים שהסתלקו מחלקם בעיזבון ?
      <span style="color:#c90101">*</span>
    </div>
    <div class="col-xs-5 paddingRight0">
      <div class="col-xs-4">
        <moj-radiobutton [(ngModel)]="isYorshimHistalku" [radiovalue]="true" name="yorshimHistalku" labelTextKey="כן">
        </moj-radiobutton>
      </div>
      <div class="col-xs-4">
        <moj-radiobutton [(ngModel)]="isYorshimHistalku" [radiovalue]="false" name="yorshimHistalku" labelTextKey="לא">
        </moj-radiobutton>
      </div>
    </div>
  </div>
</moj-line>

<section>
  <div *ngIf="isYorshimHistalku">
    <div class="paddingRight20 paddingTop5">
      במסגרת בקשה לצו קיום צוואה המוגשת באופן מקוון, לא ניתן להסתלק אלא לטובת אדם מסויים (ילדו, בן זוגו או אחיו של
      המוריש). ככל שהינכם מעוניינים להסתלק הסתלקות כללית, יש להגיש את הבקשה לצו קיום צוואה למזכירות הרשם לענייני ירושה.
      תשומת ליבכם כי בהתאם להוראות סעיף 50 לחוק הירושה, הוראת הצוואה לטובת הזוכה המסתלק הסתלקות כללית מתבטלת ובגין חלקו
      של המסתלק יש להגיש בנוסף בקשה לצו ירושה אחר המנוח.
    </div>
    <moj-line>
      <div class="col-xs-6 paddingRight0">
        <span style="color:red;">*</span>
        זוכים אלו הסתלקו מחלקם בעזבון כמפורט במסמכים המצורפים בזה
      </div>
      <div class="col-xs-5 paddingRight0">
        <moj-file-upload labelWidthColumns="0" [controlWidthColumns]="12" isLabelAboveControl="false" [required]="true"
          [id]="'filesYorshimHistalku'" [(ngModel)]="filesYorshimHistalku" name="filesYorshimHistalku" show
          [maxFileSize]="23068672" [enabledFileTypes]="'pdf'" designType="filesDesignType.Single">
        </moj-file-upload>
      </div>
    </moj-line>

    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
      <moj-line>
        <div class="col-sm-12">
          <h3 class="font-14" style="float:right;">שורה 1</h3>
          <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
              style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
        </div>
      </moj-line>
    </div>
    <div>
      <h2 class="font-16 orange2 bold">הזוכה
      </h2>
    </div>
    <div>
      <moj-line>
        <moj-dropdownlist required [ngModel]="idTypeSelected" labelWidthColumns="1" [controlWidthColumns]="4"
          name="idType" fieldID="id" labelTextKey="סוג תעודה מזהה" isLabelAboveControl="true" [items]="idTypes">
        </moj-dropdownlist>
      </moj-line>
    </div>

    <div>
      <moj-line>
        <moj-textbox [required]="true" [identification]='idTypeSelected==1' ngModel name="idNumber"
          labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
          [maxlength]="9">
        </moj-textbox>
        <moj-textbox [required]="true" ngModel name="firstName" [labelTextKey]="'שם פרטי'" [maxlength]="50"
          labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
        </moj-textbox>
        <moj-textbox [required]="true" ngModel name="lastName" [labelTextKey]="'שם משפחה'" [maxlength]="50"
          labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
        </moj-textbox>
      </moj-line>
    </div>
    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div>
      <h2 class="font-16 orange2 bold">בן משפחה שלטובתו הסתלק
      </h2>
    </div>
    <section>
      <div style="padding-left:30px;">
        <div>
          <moj-line>
            <div class="col-sm-12">
              <h3 class="font-14" style="float:right;">שורה 1</h3>
              <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
                  style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
            </div>
          </moj-line>


          <div>
            <moj-line>
              <moj-dropdownlist required [ngModel]="idTypeSelected" labelWidthColumns="1" [controlWidthColumns]="4"
                name="idType" fieldID="id" labelTextKey="בן המשפחה שלטובתו הסתלק" isLabelAboveControl="true"
                [items]="idTypes">
              </moj-dropdownlist>

              <moj-textbox [required]="true" ngModel name="idNumber" labelTextKey="פרטי החלק או המנה (רכוש) שממנו הסתלק"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true" [maxlength]="9">
              </moj-textbox>
            </moj-line>
          </div>

          <div>
            <moj-line>
              <moj-textbox [required]="true" [identification]='idTypeSelected==1' ngModel name="idNumber"
                labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
                [maxlength]="9">
              </moj-textbox>
              <moj-textbox [required]="true" ngModel name="firstName" [labelTextKey]="'שם פרטי'" [maxlength]="50"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
              </moj-textbox>
              <moj-textbox [required]="true" ngModel name="lastName" [labelTextKey]="'שם משפחה'" [maxlength]="50"
                labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
              </moj-textbox>
            </moj-line>
          </div>

          <div>
            <moj-line>
              <div style="padding-left:30px;">
                <button style="padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
              </div>
            </moj-line>
          </div>
        </div>

      </div>
    </section>
    <div>
      <moj-line>
        <div style="padding-left:30px;">
          <button style="padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
        </div>
      </moj-line>
    </div>
  </div>
</section>
<hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />
<moj-line>
  <div class="row paddingTop5">
    <div class="col-xs-6 paddingRight0 font-16 blue3 bold">
      האם יש מי מבין הזוכים לפי הצוואה שנפטר לאחר המנוח ?
      <span style="color:#c90101">*</span>
    </div>
    <div class="col-xs-5 paddingRight0">
      <div class="col-xs-4">
        <moj-radiobutton [(ngModel)]="isYoreshDeadAfter" [radiovalue]="true" name="yoreshDeadAfter" labelTextKey="כן">
        </moj-radiobutton>
      </div>
      <div class="col-xs-4">
        <moj-radiobutton [(ngModel)]="isYoreshDeadAfter" [radiovalue]="false" name="yoreshDeadAfter" labelTextKey="לא">
        </moj-radiobutton>
      </div>
    </div>
  </div>
</moj-line>
<section>
  <div *ngIf="isYoreshDeadAfter">
    <div style="padding: 10px 30px 10px 0;margin-top: 3px;">
      <moj-line>
        <h3 class="font-14" style="float:right;">שורה 1</h3>
        <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
            style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
      </moj-line>
    </div>
    <div style=" padding: 10px 15px 10px 0;">
      <moj-line>
        <moj-dropdownlist required [ngModel]="idTypeHeirsSelected" labelWidthColumns="1" [controlWidthColumns]="4"
          name="idTypeHeirs" fieldID="id" labelTextKey=" " isLabelAboveControl="false" [items]="idTypes">
        </moj-dropdownlist>
      </moj-line>
    </div>

    <div style=" padding: 10px 15px 10px 0;margin-top: 3px;">
      <moj-line>
        <moj-textbox [required]="true" [identification]='idTypeChildSelected==1' ngModel name="idNumberChild"
          labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
          [maxlength]="9">
        </moj-textbox>
        <moj-textbox [required]="true" ngModel name="firstNameChild" [labelTextKey]="'שם פרטי'" [maxlength]="50"
          labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
        </moj-textbox>
        <moj-textbox [required]="true" ngModel name="lastNameChild" [labelTextKey]="'שם משפחה'" [maxlength]="50"
          labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
        </moj-textbox>
      </moj-line>
    </div>
    <div style=" padding: 10px 15px 10px 0;margin-top: 3px;">
      <moj-line>
        <moj-dropdownlist required [ngModel]="relatedSelected" labelWidthColumns="1" [controlWidthColumns]="4"
          name="relatedType" fieldID="id" labelTextKey="הקרבה למוריש/ה" isLabelAboveControl="false" [items]="related">
        </moj-dropdownlist>
      </moj-line>
    </div>
    <div style=" padding: 10px 30px 10px 0;margin-top: 3px;">
      <moj-line>
        <moj-datepicker isLabelAboveControl="true" [ngModel]="dateDeceased" name="dateDeceased"
          labelTextKey="תאריך פטירה לועזי" labelWidthColumns="1" [controlWidthColumns]="2">
        </moj-datepicker>

        <div class="col-xs-10">
          <span style="padding-right: 15px;">תאריך פטירה עברי</span>
          <span style="color:red;">*</span><br>
          <moj-line>
            <moj-dropdownlist placeholder="שנה" required [ngModel]="hebrewYearSelected" labelWidthColumns="0"
              [controlWidthColumns]="2" name="hebrewYear" fieldID="id" isLabelAboveControl="true" [items]="hebrewYears">
            </moj-dropdownlist>
            <moj-dropdownlist placeholder="חודש" required [ngModel]="hebrewMonthSelected" labelWidthColumns="0"
              [controlWidthColumns]="2" name="hebrewMonth" fieldID="id" isLabelAboveControl="true"
              [items]="hebrewMonths">
            </moj-dropdownlist>
            <moj-dropdownlist placeholder="יום" required [ngModel]="hebrewDaySelected" labelWidthColumns="0"
              [controlWidthColumns]="2" name="hebrewDay" fieldID="id" isLabelAboveControl="true" [items]="hebrewDays">
            </moj-dropdownlist>
          </moj-line>
        </div>
      </moj-line>
    </div>
    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div class="row" style=" padding: 10px 30px 10px 0;margin-top: 3px;">
      <div class="col-xs-6 paddingRight0">
        <span style="color:red;">*</span> אישורי פטירה של זוכים שנפטרו
      </div>
      <div class="col-xs-5 paddingRight0">
        <moj-file-upload labelWidthColumns="0" [controlWidthColumns]="12" isLabelAboveControl="false" [required]="true"
          [id]="'filesDiedHeirs'" [(ngModel)]="filesDiedHeirs" name="filesDiedHeirs" show [maxFileSize]="23068672"
          [enabledFileTypes]="'pdf'" designType="filesDesignType.Single">
        </moj-file-upload>
      </div>
    </div>
    <div class="row" style=" padding: 10px 30px 10px 0;margin-top: 3px;">
      <div class="col-xs-6 paddingRight0">
        <span style="color:red;">*</span>האם ניתן צו ירושה/קיום צוואה אחרי הזוכה שנפטר?
      </div>
      <div class="col-xs-5 paddingRight0">
        <div class="col-xs-4 paddingX0">
          <moj-radiobutton [(ngModel)]="isHasZavaa" [radiovalue]="true" name="hasZavaa" labelTextKey="כן">
          </moj-radiobutton>
        </div>
        <div class="col-xs-4 paddingX0">
          <moj-radiobutton [(ngModel)]="isHasZavaa" [radiovalue]="false" name="hasZavaa" labelTextKey="לא">
          </moj-radiobutton>
        </div>
      </div>
    </div>
    <div class="row" style=" padding: 10px 30px 10px 0;margin-top: 3px;">
      <div class="col-xs-6 paddingRight0">
        <span style="color:red;">*</span>צו ירושה/צו קיום צוואה של יורשים שנפטרו אחרי המנוח
      </div>
      <div class="col-xs-5 paddingRight0">
        <moj-file-upload labelWidthColumns="0" [controlWidthColumns]="12" isLabelAboveControl="false" [required]="true"
          [id]="'filesTZAV'" [(ngModel)]="filesZavaa" name="filesZavaa" show [maxFileSize]="23068672"
          [enabledFileTypes]="'pdf'" designType="filesDesignType.Single">
        </moj-file-upload>
      </div>
    </div>
    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div style="padding: 1px 30px;margin-top: 3px;"> והניח/ה אחריו/ה את הזוכים הבאים כמוכח
      בראיות הרשומות להלן (המצ"ב )</div>
    <div style=" padding: 10px 15px 10px 0;">
      <moj-line>
        <div>הערות</div>
        <textarea></textarea>
      </moj-line>
    </div>
    <hr class="col-md-12" style="border-top: dotted 1px;color: #488ABC;" />

    <div style="padding: 1px 30px;margin-top: 3px;">שמות הזוכים על פי צו</div>
    <div style=" padding:0 70px 0 0">
      <div style="padding: 10px 30px 10px 0;">
        <moj-line>
          <h3 class="font-14" style="float:right;">שורה 1</h3>
          <h3 class="font-14" style="float:left;padding-left:30px;text-decoration: underline;"><span
              style="color:#CC0000;" class="fa fa-times"></span>מחק שורה</h3>
        </moj-line>
      </div>
      <div style=" padding: 10px 15px 10px 0;">
        <moj-line>
          <moj-dropdownlist required [ngModel]="idTypeHeirsSelected" labelWidthColumns="1" [controlWidthColumns]="4"
            name="idTypeHeirs" fieldID="id" labelTextKey=" " isLabelAboveControl="false" [items]="idTypes">
          </moj-dropdownlist>
        </moj-line>
      </div>

      <div style=" padding: 10px 15px 10px 0;margin-top: 3px;">
        <moj-line>
          <moj-textbox [required]="true" [identification]='idTypeChildSelected==1' ngModel name="idNumberChild"
            labelTextKey="מספר זיהוי" labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true"
            [maxlength]="9">
          </moj-textbox>
          <moj-textbox [required]="true" ngModel name="firstNameChild" [labelTextKey]="'שם פרטי'" [maxlength]="50"
            labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
          </moj-textbox>
          <moj-textbox [required]="true" ngModel name="lastNameChild" [labelTextKey]="'שם משפחה'" [maxlength]="50"
            labelWidthColumns="2" [controlWidthColumns]="4" isLabelAboveControl="true">
          </moj-textbox>
        </moj-line>
      </div>

      <div style=" padding: 10px 30px 10px 0;margin-top: 3px;">
        <moj-line>
          <div style="padding-left:60px;">
            <button style="padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
          </div>
        </moj-line>
      </div>
    </div>
    <div style=" padding: 10px 30px 10px 0;margin-top: 3px;">
      <moj-line>
        <div style="padding-left:30px;">
          <button style="padding: 5px 20px;float:left;border: none;">+ הוסף שורה</button>
        </div>
      </moj-line>
    </div>
  </div>


</section>