import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GenericPaymentService } from '@moj/generic-payment';
import { ButtonStyle, FontSize, FontWeight, MojColor, MojNotificationType } from '@moj/moj-ng';

import { DialogResult, DialogResultEnum, MessageType, MojFileUploadDesignType, MojLoadingService, MojMessagesService, MojRecaptchaService } from '@moj/moj-ng';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { delay, map, retryWhen, take } from 'rxjs/operators';
import { ApostilleCountry } from '../../models/apostille.model';
import { BakashaButtonsLogicResponse } from '../../models/bakasha-buttons-logic.model';
import { Client } from '../../models/client-data.model';
import { DictItem } from '../../models/dict-item.model';
import { RecaptchaModel } from '../../models/recaptcha-model';
import { SaveAgraFormRequest } from '../../models/save-agra-form-request';
import { SaveAgraFormResponse } from '../../models/save-agra-form-response';
import { SugBakashaEnum } from '../../models/sug-bakasha-enum';
import { SugNewBakashaEnum } from '../../models/sug-new-bakasha-enum';
import { DataStoreService } from '../../services/data-store.service';
import { InheritanceDataService } from '../../services/inheritance-data.service';
import { SiteConfigService } from '../../services/SiteConfigService';


@Component({
  selector: 'request-copy',
  templateUrl: './request-copy.component.html',
  styleUrls: ['./request-copy.component.css'],

})
export class RequestCopyComponent implements OnInit, OnDestroy {
  @ViewChild('f', { static: false }) formModel!: NgForm;
  @ViewChild("pnl", { read: ViewContainerRef, static: false }) pnl!: ViewContainerRef;

  @Input() data: any; // Getting data from the opener (DialogComponent)
  @Output() closeDialog: EventEmitter<DialogResult> = new EventEmitter<DialogResult>();

  buttonStyle = ButtonStyle;
  fontSize = FontSize;
  fontWeight = FontWeight;
  mojColor = MojColor;
  SugBakashaEnum = SugBakashaEnum




  fuDesignType = MojFileUploadDesignType;
  today: number = Date.now();
  isTz = true;
  isLawyer = false;
  isCreditCard = true;
  isPtor = false;
  countries: any;
  files = [];
  guid!: string;
  request = <SaveAgraFormRequest>{};
  misparTik: any;
  kodMachoz: any;
  kodBakasha: any;
  sugBakasha: any;
  requestHeader!: string;
  isNewBakasha: boolean = false;
  isShowLawerPanel: boolean = true;
  isShowPtorPanel: boolean = false;
  documentLabelText: string = "";
  emailLabelText: string = 'דואר אלקטרוני';
  isShowVerifyEmailField: boolean = false;
  isShowHeaderRequestsCombo: boolean = false;
  sugNewBakasha!: DictItem;
  agraFee: any
  attention: any
  attentionEnd: any
  priceTooltip: any
  asmachtaMessage: any
  ptorMessage: any
  isLicenseNumberOk!: boolean;
  requestTexts;
  isEmailRequired: boolean = true;
  isPhoneRequired: boolean = true;
  isPersonalDataOk!: boolean;
  docType!: number;
  isSubmit: boolean = false;

  advocateFirstName: any;
  advocateLastName: any;
  advocatePhone: any;
  advocateEmail: any;
  licenseNumber: any;
  logicResponse = <BakashaButtonsLogicResponse>{};
  sugNewBakashaList!: DictItem[];
  FaultyDocumentsRemarks!: string[];
  model?: RecaptchaModel;
  mojNotificationType = MojNotificationType;
  sendRequest: boolean = true;
  isSignatureType: boolean = false;
  apostilleCountry!: ApostilleCountry;
  isApostilleSignature = false;

  constructor(private dataService: InheritanceDataService,
    public dataStoreService: DataStoreService,
    private translateService: TranslateService,
    private loadingService: MojLoadingService,
    private recaptchaService: MojRecaptchaService,
    private messagesService: MojMessagesService,
    private configService: SiteConfigService,
    private paymentService: GenericPaymentService
  ) {
    this.requestTexts = this.translateService.instant('Texts.requestTexts');
  }

  ngOnInit(): void {
    // console.log("this.data=",this.data);

    this.ptorMessage = 'במידה ואינך פטור מתשלום ולא בוצע תשלום על ידך – הבקשה לא תתקבל ותוחזר אלייך ע"י מזכירות הרשם להגשה חוזרת.'

    this.sugBakasha = this.data.sugBakasha;
    this.misparTik = this.data.misparTik;
    this.kodMachoz = this.data.kodMachoz;
    this.kodBakasha = this.data.kodBakasha;
    this.logicResponse = this.data.logicResponse;

    this.isLicenseNumberOk = false;

    if (this.sugBakasha == SugBakashaEnum.BakashaChadasha) {
      this.fillterSugBakashaList();
      this.isShowHeaderRequestsCombo = true;
      this.isNewBakasha = true;
      return;
    }

    this.getAgraFee(this.sugBakasha, this.kodBakasha)
      .subscribe({
        next: (res) => { this.agraFee = res; },
        error: () => { },
        complete: () => {
          this.initForm();
          if (this.sugBakasha != SugBakashaEnum.BakashaChadasha) {
            this.setAttention(this.requestHeader);
            this.setAsmachtaMessage(this.requestHeader);
          }
        }
      });
  }

  getAgraFee(sugBakasha: number, kodBakasha: number) {
    this.agraFee = 0;
    return this.dataService.getAgraFee(sugBakasha, kodBakasha)
      .pipe(
        retryWhen(errors => errors.pipe(
          delay(1000), // Delay before retrying
          take(2) // Retry only once
        )));
  }

  fillterSugBakashaList() {
    this.dataStoreService.sugNewBakasha$.pipe(take(1)).subscribe(res => {
      this.sugNewBakashaList = res.filter(item => {
        if(this.logicResponse.kod === 112 && item.id != 50)
        {
           return false;
        }
        if ((item.id === 14 || item.id === 11) && !this.logicResponse.isCancelInheritanceOrder) { //ביטול צו ירושה + תיקון צו ירושה
          return false;
        }

        if (item.id === 41 && !this.logicResponse.isExtendDateRequest) { //הארכת מועד
          return false;
        }
        
        if (item.id === 50 && !this.logicResponse.isViewFileRequest) { //עיון בתיק
          return false;
        }

        if (item.id === 29 && !this.logicResponse.isDelayExecution) { //עיכוב ביצוע
          return false;
        }

        if ((item.id === 15 || item.id === 12) && !this.logicResponse.isCancelWillOrder) { //ביטול צו צוואה + תיקון צו צוואה
          return false;
        }

        if ((item.id === 96 || item.id === 97 || item.id === 35 ) && this.data.taarichSgira != null) //בקשת צו ירושה /קיום צוואה מתוקנת /   החלפת ייצוג 
          return false;

        return true;
      })
      console.log("this.sugNewBakashaList=", this.sugNewBakashaList);

    })
  }

  onChangeSugNewBakasha(sugNewBakasha: { id: any, value: any }) {
    console.log("sugNewBakasha=", sugNewBakasha);

    if (!sugNewBakasha) {
      this.documentLabelText = "";
      this.setAttention("");
      this.setAsmachtaMessage("");
      this.agraFee = null;
      this.sugBakasha = SugBakashaEnum.BakashaChadasha;
      return;
    }

    this.docType = 3;
    this.sugBakasha = sugNewBakasha.id;
    this.loadingService.show(this.pnl);
    this.getAgraFee(this.sugBakasha, this.kodBakasha)
      .subscribe({
        next: (res) => { this.agraFee = res; },
        error: () => { },
        complete: () => {
          this.documentLabelText = "מסמך " + sugNewBakasha.value;
          this.setAttention(sugNewBakasha.value);
          this.setAsmachtaMessage(sugNewBakasha.value);
          this.setPtor(sugNewBakasha.id);
          this.loadingService.hide();
        }
      });
  }

  selectApostille(event: any) {
    this.apostilleCountry = event;
  }

  changeSugBakagaToApostille(isApostilleSignature: boolean, value: string) {
    this.sugBakasha = isApostilleSignature ? SugBakashaEnum.etekTzavApostille : SugBakashaEnum.etekTzav;
    this.dataService.getAgraFee(this.sugBakasha, this.kodBakasha).subscribe(res => {
      this.agraFee = res;
      this.setAttention(value);
      this.setAsmachtaMessage(value);
    });
  }

  setPtor(sugNewBakashaId: number) {
    this.isShowPtorPanel = (sugNewBakashaId == SugNewBakashaEnum.TikunTautSofer);
  }

  setAttention(sugBakashaText: string) {
    if (sugBakashaText == "") {
      this.attention = "";
      return;
    }

    this.attention = 'לתשומת ליבך, כדי לשלם את האגרה עבור הבקשה הנדרשת עליך לעבור '
    this.attentionEnd = 'לאחר התשלום יש לחזור לטופס זה ולהזין את מספר האסמכתא שיתקבל.'

    this.priceTooltip = "שים לב: "
    if (this.isNewBakasha)
      this.priceTooltip += "בקשה ל"

    this.priceTooltip += sugBakashaText + " כרוכה בתשלום בסך " + this.agraFee + ' ש"ח '

    if (this.sugBakasha == SugNewBakashaEnum.MinuyMenahelIzavon) {
      this.priceTooltip += "תשומת הלב כי בקשה למינוי מנהל עזבון זמני יש להגיש באמצעות משלוח הבקשה לדואר אלקטרוני Moked-Yerushot@justice.gov.il"
    }
  }

  setAsmachtaMessage(sugBakashaText: string) {
    if (this.agraFee == "") {
      this.asmachtaMessage = "";
      return;
    }

    this.asmachtaMessage = 'ולשלם את האגרה ל'
    if (this.isNewBakasha)
      this.asmachtaMessage += "בקשה ל"

    this.asmachtaMessage += `${sugBakashaText} בסך ${this.agraFee} ש"ח.`
  }

  onEmailorPhoneChanged(event: any) {
    let f = this.formModel.value;
    if ((f.email === '' && f.phone === '') || (f.email === '' && f.phone !== '')) {
      if (this.sugBakasha !== SugBakashaEnum.etekTzav && this.sugBakasha !== SugBakashaEnum.etekTzavApostille) {
        this.isPhoneRequired = true
        this.isEmailRequired = true
      }
      return;
    }

    this.isPhoneRequired = false
    this.isEmailRequired = true
    return
  }

  initForm() {
    switch (this.sugBakasha) {
      case SugBakashaEnum.Itnagdut: {
        this.documentLabelText = "נא צרף את מסמך ההתנגדות";
        this.requestHeader = "הגשת התנגדות";
        this.docType = 3;
        break;
      };
      case SugBakashaEnum.etekTzav:
      case SugBakashaEnum.etekTzavApostille: {
        this.isPhoneRequired = false;
        this.isShowLawerPanel = false;
        this.documentLabelText = '';
        this.isShowVerifyEmailField = true;
        this.emailLabelText = "דואר אלקטרוני - לכתובת זו ישלח הצו"
        this.requestHeader = "בקשה לקבלת העתק צו";
        this.isSignatureType = true;
        this.docType = 3;
        break;
      };
      case SugBakashaEnum.TguvaLehachlatatRasham: {
        this.documentLabelText = "נא צרף את מסמך התגובה";
        this.requestHeader = "תגובה להחלטת רשם";
        this.docType = 312;
        break;
      };
      case SugBakashaEnum.HashlamatMismachimMazkirut: {
        this.requestHeader = "השלמת מסמכים למזכירות";
        this.dataService.getFaultyDocumentsRemarks(this.kodBakasha).subscribe(res => {
          this.FaultyDocumentsRemarks = [];
          res.list.forEach(line => this.FaultyDocumentsRemarks.push(line.remark))
        })
        this.docType = 308;
        break;
      };
      case SugBakashaEnum.HashlamatMismachimYoetz: {
        this.documentLabelText = `השלמת מסמכים לב"כ היועץ המשפטי לממשלה`;
        this.requestHeader = `השלמת מסמכים לב"כ היועץ המשפטי לממשלה`;
        this.docType = 202;
        break;
      };
      case SugBakashaEnum.HashlamatMismachimOzerRasham: {
        this.documentLabelText = `השלמת מסמכים לעוזר רשם`;
        this.requestHeader = `השלמת מסמכים לעוזר רשם`;
        this.docType = 81;
        break;
      };
    }
  }

  fileUploadComplete(file: any) {
    this.guid = file.GUID;
    // console.log("in fileUploadComplete this.guid=" + this.guid);
  }

  ToggleIdLabel() {
    return this.isTz ? 'מספר זהות' : 'מספר דרכון';
  }

  clearLawyerFields() {
    this.isLicenseNumberOk = false
    this.advocateFirstName = "";
    this.advocateLastName = "";
    this.advocatePhone = "";
    this.advocateEmail = "";
  }

  onBlurLicenseNumber($event: any) {
    let licenseNumber = $event.target.value;
    var reg = /^\d+$/;
    if (!reg.test(licenseNumber)) {
      this.clearLawyerFields();
      return;
    };
    this.model = new RecaptchaModel();
    if ((<any>this.configService.configuration).isRecaptchaOn) {
      this.recaptchaService.executeRecaptcha().subscribe((res) => {
        this.model!.recaptchaStringLawyer = res;
        this.getLawyerDetails(licenseNumber);
      })
    }
    else {
      this.model!.recaptchaStringLawyer = '';
      this.getLawyerDetails(licenseNumber);
    }

  }

  getLawyerDetails(licenseNumber: number) {
    // console.log("in getLawyerDetails()");
    var reg = /[*+!@#%&?^${}()|[\]\\]/g;
    this.dataService.getLawyerDetails(licenseNumber, this.model!.recaptchaStringLawyer).subscribe(res => {
      if (res != null) {
        this.isLicenseNumberOk = true
        this.advocateFirstName = res.firstName.replace(reg, '');
        this.advocateLastName = res.lastName.replace(reg, '');
        this.advocatePhone = res.mobilePhone;
        this.advocateEmail = res.email;
      }
      else {
        this.clearLawyerFields();
        this.messagesService.showMessage(
          {
            messageTitle: this.requestTexts.Message,
            messageText: "מספר הרישיון אינו קיים",
            messageType: MessageType.Message
          });
      }
    })
  }

  isPersonalDataExists(): Observable<boolean> {
    // console.log("in isPersonalDataExists()");

    // check the personal details with aviv
    let clientData = new Client(<any>this.formModel);

    if (!this.isTz) return of(true); // במקרה של דרכון אין בדיקה מול אביב

    return this.dataService.isPersonalDataExists({ clientData, recaptchaString: this.model!.recaptchaStringTz }).pipe(
      map(
        (res: boolean) => {
          if (res) return true;


          this.messagesService.showMessage(
            {
              messageTitle: this.requestTexts.Message,
              messageText: "פרטי המגיש אינם נכונים. אנא וודא שהקלדת את הפרטים כפי שמופיעים בתעודת הזהות",
              messageType: MessageType.Message
            });
          this.isSubmit = false;
          return false;
        },
        (err: any) => {
          this.isSubmit = false;
          return false;
        }
      )
    )
  }

  onSubmit() {
    this.loadingService.show(this.pnl);
    this.isSubmit = true;
    if (this.checkFormBeforSubmit()) {
      this.model = new RecaptchaModel();
      if ((<any>this.configService.configuration).isRecaptchaOn) {
        this.recaptchaService.executeRecaptcha().subscribe((res) => {
          this.model!.recaptchaStringTz = res;
          this.checkPersonalDataExist()
        })
      }
      else {
        this.model!.recaptchaStringTz = '';
        this.checkPersonalDataExist()
      }
    }
  }

  checkPersonalDataExist() {
    this.isPersonalDataExists().pipe(take(1)).subscribe(res => {
      if (res)
        this.checkAndSubmitForm();
      else
        this.loadingService.hide();
    }
      , (err) => this.loadingService.hide())
  }

  initRequest() {
    let f = this.formModel.value;
    this.request = f;
    this.request.docType = this.docType
    this.request.isTz = f.optionId;

    this.request.misTik = this.misparTik;
    this.request.kodMachoz = this.kodMachoz;
    this.request.misparBakasha = this.kodBakasha;
    this.request.isCreditCard = f.paymentType;
    this.request.docGuid = this.guid;
    this.request.sugBakasha = this.sugBakasha;
    this.request.apostilleCountryId = f.signatureType ? this.apostilleCountry.id : undefined;
    if (this.request.asmachta || this.request.isCreditCard) this.request.WayOfPayment = 2; // שרת הטפסים
  }

  checkFormBeforSubmit() {
    let f = this.formModel.value;

    if (f.email == "" && f.phone == "") {

      this.messagesService.showMessage(
        {
          messageTitle: this.requestTexts.Message,
          messageText: "נא להקליד אימייל או טלפון של המבקש",
          messageType: MessageType.Message
        });
      this.isSubmit = false;
      this.loadingService.hide();
      return;
    }

    if (this.isShowVerifyEmailField && f.email != "" && f.email != f.emailVerify) {
      this.messagesService.showMessage(
        {
          messageTitle: this.requestTexts.Message,
          messageText: "שדה אימייל חייב להיות שווה לשדה אימייל לאימות",
          messageType: MessageType.Message
        });
      this.isSubmit = false;
      this.loadingService.hide();
      return;
    }

    // if (this.sugBakasha == SugNewBakashaEnum.TikunTautSofer && (f.ptor != true && f.paymentType == undefined)) {
    //   this.messagesService.showMessage("לא נבחר אמצעי תשלום או שלא סומן פטור", this.requestTexts.Message, null, MessageType.Message)
    //   this.isSubmit = false;
    //   this.loadingService.hide();
    //   return;
    // }

    if (this.docType == undefined) {
      this.initForm()
    }

    //Etek Tzav Apostille
    if (this.sugBakasha == SugBakashaEnum.etekTzavApostille) {
      if (f.signatureType && !this.apostilleCountry.convention) {
        this.messagesService.showMessage(
          {
            messageTitle: this.requestTexts.Message,
            messageText: this.requestTexts.apostilleConvention,
            messageType: MessageType.Message
          });
        this.isSubmit = false;
        this.loadingService.hide();
        return;
      }
    }
    if (!this.formModel.valid) return;
    return true;
  }

  checkAndSubmitForm() {
    this.isPersonalDataOk = true;
    let f = this.formModel.value;

    if (!this.formModel.valid) return;

    this.initRequest();

    // if payment type is credit card, first save the form data to the database in RequestDraft table,
    // then open shop to accept payment
    if (this.request.isCreditCard) {
      // this.paymentService.showMessageBeforeRedirect("הנך מועבר לשרת התשלומים לאחר ביצוע התשלום, הבקשה תשלח לרשם לעניני ירושה")
      //   .subscribe({
      //     next: (result) => {
      //       if (result.dialogResultType === DialogResultEnum.OK)
      this.paymentService.callShoham(this.request, "_self");
      this.loadingService.hide();
      // else this.loadingService.hide();
      // },
      // error: () => this.loadingService.hide()
      // });
    }
    else {
      this.saveAgraForm(this.request);
    }
  }

  saveAgraForm(request: SaveAgraFormRequest) {
    this.dataService.saveAgraForm(this.request)
      .pipe(take(1))
      .subscribe((res: SaveAgraFormResponse) => {
        this.showResultAfterSaveAgraForm(res);
      },
        (error) => this.loadingService.hide());
  }

  showResultAfterSaveAgraForm(res: any) {
    this.loadingService.hide();
    if (!res.ok) {
      this.messagesService.showMessage(
        {
          messageTitle: this.requestTexts.Message,
          messageText: res.message,
          messageType: MessageType.Message
        });

      this.isSubmit = false;
    }
    else {
       
      this.messagesService.showMessage(
        {
          messageTitle: this.requestTexts.Message,
          messageText: this.requestTexts.requestSent,
          messageType: MessageType.Message
        })
        .pipe(take(1))
        .subscribe(() => {
          this.closeDialog.emit({ dialogResultType: DialogResultEnum.OK, data: { ok: true } });
        },
          (error) => this.loadingService.hide());
    }
  }


  openWindows() {
    window.open((<any>this.configService.configuration).vaucherStoreLink, "_blank", "PopUp")
  }

  ngOnDestroy() {
    console.log("in ngOnDestroy");
  }

}
